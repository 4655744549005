import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import PricingAltHeader from './PricingAltHeader';
import { Link } from 'react-router-dom';
import useFakeFetch from 'hooks/useFakeFetch';
import PricingAltCard from './PricingAltCard';
import FaqAlt from 'components/pages/faq/faq-alt/FaqAlt';
import { pricingAltData as pricingData } from 'data/pricing';
import NavbarStandard from 'components/pages/landing/NavbarStandard';

const PricingAlt = () => {
  const { loading: pricingLoading, data: pricing } = useFakeFetch(
    pricingData,
    1000
  );

  console.log('PricingAlt.js: pricingData: ', pricingData);

  return (
    <>
      <NavbarStandard />
      <Card className="my-7 py-4 container">
        <Card.Body>
          <Row className="justify-content-center">
            <PricingAltHeader />

            <Col xs={12} lg={9} xl={10} xxl={8}>
              <Row>
                {pricingLoading ? (
                  <Col xs={12}>
                    <Spinner
                      className="position-absolute start-50"
                      animation="grow"
                    />
                  </Col>
                ) : (
                  pricing.map(item => (
                    <PricingAltCard key={item.id} pricing={item} />
                  ))
                )}
              </Row>
            </Col>
            <Col xs={12} className="text-center">
              {/* <FaqAlt />
              <p className="fs-1">
                Try the <Link to="#!">trial version</Link> of AuziPlan
              </p>

 */}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* <FaqAlt /> */}
    </>
  );
};

export default PricingAlt;
