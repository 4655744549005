import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import { currencies } from 'utils';

function Item({ details, motorVehicles, setMotorVehicles, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleDelete = async () => {
    try {
      await api.delete(`/motor-vehicles/delete/${details.id}`);
      toast.success('Motor Vehicle deleted');
      const updatedMotorVehicles = motorVehicles.filter(
        motorVehicle => motorVehicle.id !== details.id
      );
      setMotorVehicles(updatedMotorVehicles);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting Motor Vehicle');
    }
  };

  const amountIncludingGst = () => {
    if (details.quantity && details.carPrice && details.gstAmount) {
      console.log(details);
      return (
        details.quantity *
        (parseFloat(details.carPrice.replace(/[^0-9.]/g, '')) +
          parseFloat(details.gstAmount.replace(/[^0-9.]/g, '')))
      );
    }
    return 'N/A';
  };

  const handleEdit = () => {
    close();
    setEdit('motorVehicle');
    setEditDetails(details);
    setStep(2);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{details.quantity}</td>
        <td>{details.vehicleType}</td>
        <td>
          {currencies[companyDetails.currency]}
          {/* {details.totalValue.slice(1)} */} {amountIncludingGst()}
        </td>
        <td>{details.interestRate}</td>
        <td>
          {currencies[companyDetails.currency]}
          {details.monthlyPayment.slice(1)}
        </td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.description}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Motor Vehicle?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  motorVehicles: PropTypes.array,
  setMotorVehicles: PropTypes.func,
  close: PropTypes.func
};

export default Item;
