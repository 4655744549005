import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import AppContext, { RevenueWizardContext } from 'context/Context';
import { RevenueFormModalContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import WizardModal from './WizardModal';
import Flex from 'components/common/Flex';

import RevenueStreamsForm from '../forms/RevenueStreamsForm';
import ServicesForm from '../forms/ServicesForm';
import Success from '../forms/Success';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const { step, setStep } = useContext(RevenueWizardContext);
  const [modal, setModal] = useState(false);

  const { showFormModal, setShowFormModal } = useContext(
    RevenueFormModalContext
  );

  const handleFormModelClose = () => {
    setShowFormModal(false);
  };

  const toggle = () => setModal(!modal);

  const handleNavigations = targetStep => {
    if (step !== 5) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  const navItems = [
    {
      icon: 'dollar-sign',
      label: 'Products'
    },

    {
      icon: 'dollar-sign',
      label: 'Services'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />

      <Card className="theme-wizard mb-0 p-0">
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav
            className="justify-content-center gap-2 flex-wrap"
            variant={variant}
          >
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavigations}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavigations}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && <RevenueStreamsForm />}
          {step === 2 && <ServicesForm />}
          {step === 3 && <Success />}
        </Card.Body>

        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 3,
            ' d-flex': step < 3
          })}
        >
          <IconButton
            color="success"
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-arrow-left"
            iconAlign="right"
            className={classNames('px-auto fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          ></IconButton>
          <IconButton
            color="warning"
            variant="outline-success"
            size="lg"
            icon="fa fa-times"
            iconAlign="right"
            onClick={() => handleFormModelClose(false)}
            className="mb-0 mx-1 px-auto"
          ></IconButton>
          <IconButton
            color="success"
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-arrow-right"
            iconAlign="right"
            onClick={() => {
              setStep(step + 1);
            }}
          ></IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 3 ? step > index : step > 4,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
