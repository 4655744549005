import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BuzPitchFormModalContext } from 'context/Context';

const BuzPitchFormModalProvider = ({ children }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const value = { showFormModal, setShowFormModal };

  return (
    <BuzPitchFormModalContext.Provider value={value}>
      {children}
    </BuzPitchFormModalContext.Provider>
  );
};

BuzPitchFormModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BuzPitchFormModalProvider;
