import React from 'react';
import DistributionOfPerformance from './distribution-of-performance/DistributionOfPerformance';
import Greetings from './greetings/Greetings';
import IndepthHelpDesk from './IndepthHelpDesk';
import ReceivedTickets from './received-tickets/ReceivedTickets';
import SatisfactionSurvey from './satisfaction-survey/SatisfactionSurvey';
import SurveyResult from './SurveyResult';
import TicketVolume from './ticket-volume/TicketVolume';
import TopCustomers from './top-customers/TopCustomers';

import { Container, Row } from 'react-bootstrap';
import NavbarStandard from '../landing/NavbarStandard';
//import FooterStandard from './landing/FooterStandard';

const Reports = () => {
  return (
    <>
      <NavbarStandard />
      <Container className="mt-6">
        <Row>
          <Greetings />
          <IndepthHelpDesk />
          <DistributionOfPerformance />
          <SurveyResult />
          <SatisfactionSurvey />
          <TopCustomers />
          <ReceivedTickets />
          <TicketVolume />
        </Row>
      </Container>
    </>
  );
};

export default Reports;
