import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel,
  OverlayTrigger
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

const PrePaidCostsForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      costType: '',
      costDescription: '',
      costAmount: ''
    }
  });

  useEffect(() => {
    if (edit === 'prepaidCost' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('costType', editDetails.costType);
      setValue('costDescription', editDetails.costDescription);
      setValue('costAmount', editDetails.costAmount);
    }
  }, [edit]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : {
            companyId: companyDetails.id,
            ...data
          };

    api
      .post(
        isEditing ? '/prepaid-costs/update' : '/prepaid-costs/create',
        payload
      )
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Pre-Paid Costs"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="costType"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Startup Costs"
              description="Prepaid costs in business refer to expenses that have been paid in advance but have not yet been consumed or used up. These expenses are typically recorded as assets on the balance sheet until they are utilized, at which point they are recognized as expenses on the income statement."
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="div" style={{ backgroundColor: '' }}>
                    <Row
                      as={motion.div}
                      className="g-3"
                      initial={{ x: '-20%' }}
                      animate={{ x: '0%' }}
                      exit={{
                        x: window.innerWidth,
                        transition: { duration: 0.5 }
                      }}
                    >
                      <PageHeader
                        className="text-primary"
                        title="Pre-Paid Costs"
                        description=""
                      />
                      <div
                        className="border-none rounded mt-2"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        <Row>
                          <h6 className="fw-bold mt-0">What are they?</h6>

                          <p>
                            Prepaid costs in business refer to expenses that
                            have been paid in advance but have not yet been
                            consumed or used up. These expenses are typically
                            recorded as assets on the balance sheet until they
                            are utilized, at which point they are recognized as
                            expenses on the income statement.
                          </p>

                          <p>
                            Common examples of prepaid costs in business
                            include:
                          </p>

                          <ol>
                            <li>
                              <strong>Prepaid Rent:</strong> When a company pays
                              rent in advance for its office space or other
                              facilities.
                            </li>
                            <li>
                              <strong>Prepaid Insurance:</strong> Advance
                              payment made for insurance coverage, such as
                              property insurance or liability insurance.
                            </li>
                            <li>
                              <strong>Prepaid Subscriptions:</strong> Payment
                              for services or publications that cover a period
                              of time in advance, such as magazine subscriptions
                              or software licenses.
                            </li>
                            <li>
                              <strong>Prepaid Advertising:</strong> Advance
                              payment for advertising services, such as
                              billboard space or online ads.
                            </li>
                            <li>
                              <strong>Prepaid Maintenance Contracts:</strong>{' '}
                              Payments for maintenance services that cover a
                              certain period in advance, such as IT support
                              contracts or equipment servicing agreements.
                            </li>
                            <li>
                              <strong>Prepaid Taxes:</strong> Advance payment of
                              taxes, such as property taxes or income taxes.
                            </li>
                          </ol>

                          <p>
                            Prepaid costs are considered assets because they
                            represent future economic benefits to the company.
                            As they are used or consumed, they are gradually
                            expensed over time through the process of
                            amortization or recognition of the related expense.
                          </p>

                          <div className="border-dashed border-bottom my-3" />
                        </Row>
                      </div>
                    </Row>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>

            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="mt-0 mb-2 g-3">
                <h6>Make a list of all your pre-paid expenses</h6>
                <Form.Group as={Col} lg={4} controlId="costType">
                  <FloatingLabel label="Cost Type">
                    <Form.Select
                      aria-label="Select the type of cost"
                      name="costType"
                      {...register('costType', {
                        required: 'Cost Type is required.'
                      })}
                      isInvalid={errors['costType']}
                      isValid={
                        Object.keys(errors).length > 0 && !errors['costType']
                      }
                    >
                      <option value="security-deposits">
                        Security Deposits
                      </option>
                      <option value="pre-paid">Pre-Paid</option>
                      <option value="intellectual-property">
                        Intellectual Property
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors['costType']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="costDescription">
                  <FloatingLabel label="Cost Description">
                    <Form.Control
                      type="text"
                      placeholder="Cost Description"
                      name="costDescription"
                      {...register('costDescription', {
                        required: 'Cost description is required.'
                      })}
                      isInvalid={errors['costDescription']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['costDescription']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['costDescription']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="costAmount">
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="costAmount"
                    label="Cost Amount"
                    errors={errors}
                    formGroupProps={{ className: 'mb-0', as: Col }}
                    formControlProps={{
                      ...register('costAmount', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Form.Group>
              </Row>

              <div className="border-dashed border-bottom my-3" />
              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      className="float-end"
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>

      <div className="border-dashed border-bottom my-3" />
    </Row>
  );
};

export default PrePaidCostsForm;

{
  /*

Prepaid costs in business refer to expenses that have been paid in advance but have not yet been consumed or used up. These expenses are typically recorded as assets on the balance sheet until they are utilized and recognized as expenses. 



Company cars are vehicles allocated by organizations to employees for professional purposes, often serving as a perk or incentive, facilitating travel for work-related activities while also representing the company's brand.

*/
}
