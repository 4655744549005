import Flex from 'components/common/Flex';
import React, { useContext, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  ProgressBar,
  FormSelect,
  FormGroup,
  Collapse
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/6.svg';

import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { OperatingExpenseFormModalContext } from 'context/Context';
import OperatingExpenseWizard from 'components/custom/wizards/operatingExpenseWizard/OperatingExpenseWizard';
import PageHeader from 'components/common/PageHeader';

const OperatingExpensesSettings = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    OperatingExpenseFormModalContext
  );
  const [modalShow, setModalShow] = React.useState(false);
  const handleFormModelClose = () => {
    setShowFormModal(false);
  };
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-top justify-content-center">
        <Col lg={6} className="ps-lg-4 my-5 text-center">
          <img
            src={editing}
            className="img-fluid"
            alt=""
            style={{ maxWidth: '70%', height: 'auto' }}
          />
          <h2 className="test-primary"> Business Operating Expenses</h2>
          <p>
            Ensuring the smooth operation of your business involves proficiently
            managing recurring expenses such as rent, utilities, and employee
            salaries. These ongoing financial commitments play a critical role
            in sustaining the day-to-day activities and long-term viability of
            your operations.
          </p>

          <Row className="align-items-start justify-content-between">
            <Col lg={6}>
              <div className="text-start pt-3">
                <Link to="/RevenueSettings" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-left"
                    className="mb-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="text-end pt-3">
                <Link to="/help-center" className="text-decoration-none ">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-info"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
                <IconButton
                  onClick={() => setShowFormModal(true)}
                  variant="outline-primary"
                  size="lg"
                  icon="fa-solid fa-plus"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>{' '}
                <IconButton
                  onClick={() => setModalShow(true)}
                  variant="outline-warning"
                  size="lg"
                  icon="fa-solid fa-search"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>{' '}
                <Link to="/reports" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-right"
                    className="mb-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        //fullscreen={fullscreen}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <PageHeader
            title="Operating Expense Budget"
            description="Create your operating expenses budget"
            className="mb-0"
          />
          <div className="border-none rounded mt-4">
            <div className="p-x1">
              <Form.Group as={Col} lg={12} controlId="startupCostType">
                <FloatingLabel label="Startup Cost Type">
                  <Form.Select aria-label="Default select a cost type">
                    <option></option>
                    <option value="1">Operating Expenses</option>
                    <option value="2">Payroll Expenses</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            size="sm"
            onClick={() => setModalShow(false)}
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFormModal}
        // size="xl"
        fullscreen={fullscreen}
        aria-label="contained-modal-title-vcenter"
        centered
      >
        <Container>
          <OperatingExpenseWizard variant="pills" validation={false} />
        </Container>

        {/* <div className="border-dashed border-bottom my-3" /> 
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={handleFormModelClose}
            />
          </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
          <Modal.Footer>
            <Button
              variant="outline-success"
              onClick={() => handleFormModelClose(false)}
            >
              Close
            </Button>
          </Modal.Footer>
            
          */}
      </Modal>
    </Container>
  );
};

export default OperatingExpensesSettings;
