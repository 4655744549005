import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const GoalsAndObjectives = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="goalsAndObjectives" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Goals and Objectives</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Goals and Objectives</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        Goals and objectives are often used interchangeably, but
                        they have distinct meanings, especially in the context
                        of planning and management:
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                      <h6>Example</h6>

                      <div className="border-dashed border-bottom my-3" />
                      <h6>Goals and Objectives</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Goals</h6>
                      <ul>
                        <li>
                          <strong>Goal:</strong> Improve educational outcomes
                          for disadvantaged youth in the community.
                        </li>
                      </ul>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Objectives</h6>

                      <ul>
                        <div className="border-dashed border-bottom my-3" />
                        <li>
                          <strong>Objective:</strong> Increase the high school
                          graduation rate among disadvantaged youth by 15%
                          within the next three years through the implementation
                          of tutoring programs and mentorship initiatives.
                        </li>
                      </ul>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          Goals and objectives are often used interchangeably, but they have
          distinct meanings, especially in the context of planning and
          management:
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>

      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default GoalsAndObjectives;
