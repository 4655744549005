import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BuzPitchWizardContext } from 'context/Context';

const BuzPitchWizardProvider = ({ children }) => {
  const [samplePitch, setSamplePitch] = useState({});
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(false);

  const value = {
    samplePitch,
    setSamplePitch,
    step,
    setStep,
    edit,
    setEdit
  };
  return (
    <BuzPitchWizardContext.Provider value={value}>
      {children}
    </BuzPitchWizardContext.Provider>
  );
};

BuzPitchWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BuzPitchWizardProvider;
