import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

function PhoneWizardInput({
  name,
  label,
  placeholder,
  formGroupProps,
  formControlProps,
  errors,
  setValue,
  getValues
}) {
  const phoneFormValue = (getValues && getValues(name)) || '';
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (phoneFormValue !== phone) {
      setPhone(phoneFormValue);
    }
  }, [phoneFormValue]);

  return (
    <Form.Group {...formGroupProps}>
      <FloatingLabel label={label}>
        <Form.Control
          as={MaskedInput}
          mask={[
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]}
          onChange={e => {
            setValue(name, e.target.value, {
              shouldValidate: true,
              shouldDirty: true
            });
            setPhone(e.target.value);
          }}
          value={phone}
          placeholder={placeholder}
          showMask={false}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          aria-label={placeholder}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
}

PhoneWizardInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  formGroupProps: PropTypes.object,
  formControlProps: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func
};

export default PhoneWizardInput;
