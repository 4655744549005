import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const ManagementandOrganization = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="managementandOrganization" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Management and Organization</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6>Management and Organization</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Company ownership refers to the legal structure and
                        distribution of ownership rights within a business
                        entity. Ownership can take various forms, each with its
                        own implications for governance, liability, and
                        taxation. Here are some common types of company
                        ownership structures:
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6> Sole Proprietorship</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        {' '}
                        In a sole proprietorship, the business is owned and
                        operated by a single individual. The owner has full
                        control over the business's operations and retains all
                        profits but also bears full responsibility for any debts
                        or liabilities.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6> Partnership</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        A partnership is owned and operated by two or more
                        individuals who share the profits, losses, and
                        responsibilities of the business. Partnerships can be
                        either general partnerships, where all partners have
                        unlimited liability, or limited partnerships, where some
                        partners have limited liability based on their
                        investment.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6> Limited Liability Company (LLC)</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        An LLC is a hybrid legal structure that combines the
                        limited liability protection of a corporation with the
                        pass-through taxation of a partnership or sole
                        proprietorship. LLC owners, known as members, are not
                        personally liable for the company's debts and
                        obligations.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6> Corporation</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        A corporation is a separate legal entity owned by
                        shareholders. Shareholders elect a board of directors to
                        oversee the company's operations and make major
                        decisions. Corporations offer limited liability
                        protection to shareholders, meaning their personal
                        assets are generally not at risk for the company's
                        debts.
                      </p>

                      <ul>
                        <li>
                          C-Corporation: A traditional corporation taxed
                          separately from its owners. Shareholders are taxed on
                          dividends received from the corporation, resulting in
                          potential double taxation.
                        </li>
                        <div className="border-dashed border-bottom my-3" />
                        <li>
                          S-Corporation: A special type of corporation that
                          elects to pass corporate income, losses, deductions,
                          and credits through to its shareholders for federal
                          tax purposes. S-Corporations are taxed similarly to
                          partnerships or LLCs, with profits and losses flowing
                          through to the shareholders' personal tax returns.
                        </li>
                      </ul>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Cooperative:</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        A cooperative is owned and controlled by its members,
                        who typically share common interests or goals. Members
                        contribute to the capital and share in the profits based
                        on their level of participation, rather than the number
                        of shares owned.
                      </p>

                      <p>
                        he choice of company ownership structure depends on
                        various factors, including the number of owners, desired
                        level of liability protection, tax considerations, and
                        governance preferences. It's essential for business
                        owners to carefully consider the implications of each
                        ownership structure and consult with legal and financial
                        advisors before making a decision.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          Management and Organization refers to the legal structure and
          distribution of ownership rights within a business entity. Ownership
          can take various forms, each with its own implications for governance,
          liability, and taxation. Here are some common types of company
          ownership structures:
        </p>
        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default ManagementandOrganization;
