import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import {
  Button,
  Container,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import {
  FundingFormModalContext,
  FundingWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { useForm } from 'react-hook-form';
import api from 'utils/api';
import { toast } from 'react-toastify';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import TermWizardInput from 'components/custom/common/TermWizardInput';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import Background from 'components/common/Background';
import gallery2 from 'assets/img/illustrations/corner-4.png';

const LoanForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    reset,
    clearErrors
  } = useForm();

  const watchLoanAmount = watch('loanAmount', '');
  const watchInterestRate = watch('interestRate', '');
  const watchLoanTerm = watch('loanTerm', '');
  const watchFeeFrequency = watch('feeFrequency', '');
  const watchFeeAmount = watch('feeAmount', '');
  const watchRepaymentFrequency = watch('repaymentFrequency', '');

  useEffect(() => {
    if (edit === 'loan' && editDetails.id) {
      setValue('bankName', editDetails.bankName);
      setValue('loanAmount', editDetails.loanAmount);
      setValue('interestRate', editDetails.interestRate);
      setValue('repaymentFrequency', editDetails.repaymentFrequency);
      setValue('feeAmount', editDetails.feeAmount);
      setValue('feeFrequency', editDetails.feeFrequency);
      setValue('loanTerm', editDetails.loanTerm);
      setValue('repayment', editDetails.repayment);
      setValue('totalLoan', editDetails.totalLoan);
      setValue('details', editDetails.details);
      setIsEditing(true);
      setDocId(editDetails.id);
      setEdit('');
      setEditDetails({});
    }
  }, [edit]);

  useEffect(() => {
    if (
      watchLoanAmount &&
      watchInterestRate &&
      watchLoanTerm &&
      watchFeeFrequency &&
      watchFeeAmount &&
      watchRepaymentFrequency &&
      companyDetails.currency
    ) {
      const loanAmount = parseFloat(watchLoanAmount.replace(/[^0-9.-]+/g, ''));
      const interestRate = parseFloat(
        watchInterestRate.replace(/[^0-9.-]+/g, '')
      ); // like `10` in percentage
      const feeAmount = parseFloat(watchFeeAmount.replace(/[^0-9.-]+/g, ''));
      const loanTermInYears = watchLoanTerm.replace(/[^0-9.-]+/g, '');
      const feeFrequency = watchFeeFrequency;
      const repaymentFrequency = watchRepaymentFrequency;
      const currency = companyDetails.currency;

      // calculate the repayment, total interest and total loan
      let repayment = 0;
      let totalInterest = 0;
      let totalLoan = 0;

      if (repaymentFrequency === 'yearly') {
        const interestRatePerMonth = interestRate / 12 / 100;
        const loanTermInMonths = loanTermInYears * 12;
        const emi =
          (loanAmount *
            interestRatePerMonth *
            (1 + interestRatePerMonth) ** loanTermInMonths) /
          ((1 + interestRatePerMonth) ** loanTermInMonths - 1);
        repayment = emi * 12;
        totalInterest = repayment * loanTermInYears - loanAmount;
        totalLoan = repayment * loanTermInYears;

        if (feeFrequency === 'yearly') {
          totalLoan += feeAmount;
        }

        if (feeFrequency === 'quarterly') {
          totalLoan += feeAmount * 4;
        }

        if (feeFrequency === 'monthly') {
          totalLoan += feeAmount * 12;
        }

        if (feeFrequency === 'fortnightly') {
          totalLoan += feeAmount * 26;
        }

        if (feeFrequency === 'weekly') {
          totalLoan += feeAmount * 52;
        }

        setValue('repayment', repayment);
        setValue('totalInterest', totalInterest);
        setValue('totalLoan', totalLoan);
      }

      if (repaymentFrequency === 'monthly') {
        const interestRatePerMonth = interestRate / 12 / 100;
        const loanTermInMonths = loanTermInYears * 12;
        const emi =
          (loanAmount *
            interestRatePerMonth *
            (1 + interestRatePerMonth) ** loanTermInMonths) /
          ((1 + interestRatePerMonth) ** loanTermInMonths - 1);
        repayment = emi;
        totalInterest = repayment * loanTermInMonths - loanAmount;
        totalLoan = repayment * loanTermInMonths;

        if (feeFrequency === 'yearly') {
          totalLoan += feeAmount;
        }

        if (feeFrequency === 'quarterly') {
          totalLoan += feeAmount * 4;
        }

        if (feeFrequency === 'monthly') {
          totalLoan += feeAmount * 12;
        }

        if (feeFrequency === 'fortnightly') {
          totalLoan += feeAmount * 26;
        }

        if (feeFrequency === 'weekly') {
          totalLoan += feeAmount * 52;
        }

        setValue('repayment', repayment);
        setValue('totalInterest', totalInterest);
        setValue('totalLoan', totalLoan);
      }

      if (repaymentFrequency === 'fortnightly') {
        const interestRatePerMonth = interestRate / 12 / 100;
        const loanTermInMonths = loanTermInYears * 12;
        const emi =
          (loanAmount *
            interestRatePerMonth *
            (1 + interestRatePerMonth) ** loanTermInMonths) /
          ((1 + interestRatePerMonth) ** loanTermInMonths - 1);
        repayment = emi * 2;
        totalInterest = repayment * loanTermInMonths - loanAmount;
        totalLoan = repayment * loanTermInMonths;

        if (feeFrequency === 'yearly') {
          totalLoan += feeAmount;
        }

        if (feeFrequency === 'quarterly') {
          totalLoan += feeAmount * 4;
        }

        if (feeFrequency === 'monthly') {
          totalLoan += feeAmount * 12;
        }

        if (feeFrequency === 'fortnightly') {
          totalLoan += feeAmount * 26;
        }

        if (feeFrequency === 'weekly') {
          totalLoan += feeAmount * 52;
        }

        setValue('repayment', repayment);
        setValue('totalInterest', totalInterest);
        setValue('totalLoan', totalLoan);
      }

      if (repaymentFrequency === 'weekly') {
        const interestRatePerMonth = interestRate / 12 / 100;
        const loanTermInMonths = loanTermInYears * 12;
        const emi =
          (loanAmount *
            interestRatePerMonth *
            (1 + interestRatePerMonth) ** loanTermInMonths) /
          ((1 + interestRatePerMonth) ** loanTermInMonths - 1);
        repayment = emi / 4;
        totalInterest = repayment * loanTermInMonths - loanAmount;
        totalLoan = repayment * loanTermInMonths;

        if (feeFrequency === 'yearly') {
          totalLoan += feeAmount;
        }

        if (feeFrequency === 'quarterly') {
          totalLoan += feeAmount * 4;
        }

        if (feeFrequency === 'monthly') {
          totalLoan += feeAmount * 12;
        }

        if (feeFrequency === 'fortnightly') {
          totalLoan += feeAmount * 26;
        }

        if (feeFrequency === 'weekly') {
          totalLoan += feeAmount * 52;
        }

        setValue('repayment', repayment);
        setValue('totalInterest', totalInterest);
        setValue('totalLoan', totalLoan);
      }

      if (repaymentFrequency === 'quarterly') {
        const interestRatePerMonth = interestRate / 12 / 100;
        const loanTermInMonths = loanTermInYears * 12;
        const emi =
          (loanAmount *
            interestRatePerMonth *
            (1 + interestRatePerMonth) ** loanTermInMonths) /
          ((1 + interestRatePerMonth) ** loanTermInMonths - 1);
        repayment = emi * 3;
        totalInterest = repayment * loanTermInMonths - loanAmount;
        totalLoan = repayment * loanTermInMonths;

        if (feeFrequency === 'yearly') {
          totalLoan += feeAmount;
        }

        if (feeFrequency === 'quarterly') {
          totalLoan += feeAmount * 4;
        }

        if (feeFrequency === 'monthly') {
          totalLoan += feeAmount * 12;
        }

        if (feeFrequency === 'fortnightly') {
          totalLoan += feeAmount * 26;
        }

        if (feeFrequency === 'weekly') {
          totalLoan += feeAmount * 52;
        }

        setValue('repayment', repayment);
        setValue('totalInterest', totalInterest);
        setValue('totalLoan', totalLoan);
      }
    }
  }, [
    watchLoanAmount,
    watchInterestRate,
    watchLoanTerm,
    watchFeeFrequency,
    watchFeeAmount,
    watchRepaymentFrequency,
    companyDetails.currency
  ]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : {
            companyId: companyDetails.id,
            ...data
          };

    api
      .post(isEditing ? '/loans/update' : '/loans/create', payload)
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message="Do you want to add another loan?"
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="bankName"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="How much will my buisness loan repayments be?"
              description="Companies often require significant funds for capital
              investments such as purchasing machinery, expanding
              facilities, or developing new products."
              className="text-primary"
            />
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <Row className="mb-0 mt-0">
              <Col md="auto">
                <div ref={ref}>
                  <Overlay
                    show={show}
                    target={target}
                    placement="right"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover
                      id="popover-contained"
                      style={{ backgroundColor: '' }}
                    >
                      <Popover.Header as="h3">Help Center</Popover.Header>
                      <Popover.Body as="div" style={{ backgroundColor: '' }}>
                        <Row
                          as={motion.div}
                          className="g-3"
                          initial={{ x: '-20%' }}
                          animate={{ x: '0%' }}
                          exit={{
                            x: window.innerWidth,
                            transition: { duration: 0.5 }
                          }}
                        >
                          <PageHeader
                            className="text-primary"
                            title="Company Bank Finance"
                            description=""
                          />

                          <div
                            className="border-none rounded mt-2"
                            style={{ maxHeight: '280px', overflowY: 'auto' }}
                          >
                            <Row>
                              <p className="text">
                                Companies often require significant funds for
                                capital investments such as purchasing
                                machinery, expanding facilities, or developing
                                new products.
                              </p>
                              <p className="text">
                                Borrowing money allows them to make these
                                essential investments without depleting their
                                cash reserves.
                              </p>
                              <p className="text">
                                Comparing offers from multiple lenders can help
                                you make an informed decision aligned with your
                                financial goals.
                              </p>
                              <p className="text">
                                It's important to note that while borrowing
                                money can be a strategic move for companies, it
                                also comes with responsibilities and risks.
                              </p>
                              <p className="text">
                                Prudent financial management, careful assessment
                                of the terms and conditions of loans, and a
                                well-thought-out repayment plan are essential to
                                ensure that borrowing enhances a company's
                                financial health rather than jeopardizing it.
                              </p>
                              <p className="text">
                                In conclusion, borrowing money is a common and
                                necessary practice for companies across various
                                industries.
                              </p>
                              <p className="text">
                                When used wisely, it can fuel growth, enhance
                                competitiveness, and support a company's
                                long-term success. However, companies should
                                approach borrowing with a clear strategy and a
                                thorough understanding of their financial needs
                                and obligations.
                              </p>
                              <h6 className="text-primary">
                                Help Note: How to Fill Out the Loan Form
                              </h6>
                              <p>
                                Thank you for choosing to use our loan form to
                                submit your financing details. Below are
                                step-by-step instructions to help you fill out
                                the form accurately:
                              </p>

                              <h6 className="text-primary pt-3">
                                1. Bank Name
                              </h6>

                              <ul>
                                <li>
                                  <strong>Bank Name:</strong>
                                  Enter the name of the bank from which you are
                                  seeking a loan. This field is required.
                                </li>
                              </ul>
                              <h6 className="text-primary">2. Details</h6>
                              <ul>
                                <li>
                                  <strong>Details:</strong>
                                  Provide additional details regarding your loan
                                  request. Describe any specific terms or
                                  conditions you wish to highlight. This field
                                  is required.
                                </li>
                              </ul>

                              <h6 className="text-primary">3. Loan Amount</h6>
                              <ul>
                                <li>
                                  <strong>Loan Amount:</strong>
                                  Enter the amount of money you intend to
                                  borrow. Please specify the currency in which
                                  you are borrowing. This field is required.
                                </li>
                              </ul>

                              <h6 className="text-primary">4. Interest Rate</h6>
                              <ul>
                                <li>
                                  <strong>Interest Rate:</strong>
                                  Input the annual interest rate offered by the
                                  lender as a percentage. This field is
                                  required.
                                </li>
                              </ul>

                              <h6 className="text-primary">
                                5. Repayment Frequency
                              </h6>
                              <ul>
                                <li>
                                  <strong>Repayment Frequency:</strong>
                                  Select how often you plan to make repayments
                                  to the lender (e.g., yearly, quarterly,
                                  monthly). This field is required.
                                </li>
                              </ul>

                              <h6 className="text-primary">6. Loan Term</h6>
                              <ul>
                                <li>
                                  <strong>Loan Term:</strong>
                                  Specify the duration of the loan in years.
                                  This field is required.
                                </li>
                              </ul>

                              <h6 className="text-primary">7. Fee Amount</h6>
                              <ul>
                                <li>
                                  <strong>Fee Amount:</strong>
                                  Enter any fees associated with the loan.
                                  Specify the currency if different from the
                                  loan amount currency. This field is required.
                                </li>
                              </ul>

                              <h6 className="text-primary">
                                8. Fees Frequency
                              </h6>
                              <ul>
                                <li>
                                  <strong>Fee Frequency:</strong>
                                  Choose how often fees are charged (e.g.,
                                  yearly, quarterly, monthly). This field is
                                  required.
                                </li>
                              </ul>

                              <ul>
                                <h6 className="text-primary">9. Summary</h6>

                                <li>
                                  <strong>
                                    Total Interest, Total Loan/Interest, Loan
                                    Repayment:
                                  </strong>
                                  These fields will automatically calculate
                                  based on your inputs for Loan Amount, Interest
                                  Rate, Loan Term, Fee Amount, Fee Frequency,
                                  and Repayment Frequency.
                                </li>
                              </ul>
                              <h6 className="text-primary">
                                Additional Information
                              </h6>
                              <ul>
                                <li>
                                  If you encounter any errors while submitting,
                                  ensure all required fields are correctly
                                  filled out. Error messages will guide you on
                                  any missing or incorrect information.
                                </li>
                              </ul>
                              <h6 className="text-primary">Saving Your Data</h6>
                              <ul>
                                <li>
                                  Click the "Save" button at the bottom right
                                  after filling out all required fields to save
                                  your entries.
                                </li>
                                <li>
                                  You will receive notifications and prompts
                                  based on your actions during the form
                                  submission process.
                                </li>
                              </ul>

                              <div className="border-dashed border-bottom my-3" />
                            </Row>
                          </div>
                        </Row>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </Col>

              <Col md></Col>
            </Row>

            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="mt-0 mb-2 g-3">
                <Form.Group as={Col} lg={6} controlId="bankName">
                  <FloatingLabel label="Bank Name">
                    <Form.Control
                      type="text"
                      placeholder="Bank Name"
                      name="bankName"
                      {...register('bankName', {
                        required: 'Bank Name is required.'
                      })}
                      isInvalid={errors['bankName']}
                      isValid={
                        Object.keys(errors).length > 0 && !errors['bankName']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['bankName']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  as={Col}
                  lg={6}
                  className="mb-0"
                  controlId="details"
                >
                  <FloatingLabel label="Details">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Details"
                      name="details"
                      {...register('details', {
                        required: 'Details is required.'
                      })}
                      isInvalid={errors['details']}
                      isValid={
                        Object.keys(errors).length > 0 && !errors['details']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['details']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-2 g-3">
                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="loanAmount"
                  label="Loan Amount"
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', lg: 4, as: Col }}
                  formControlProps={{
                    ...register('loanAmount', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />

                <PercentageWizardInput
                  name="interestRate"
                  label="Interest Rate"
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', as: Col, lg: 4 }}
                  formControlProps={{
                    ...register('interestRate', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />

                <Form.Group as={Col} lg={4} controlId="repaymentFrequency">
                  <FloatingLabel label="Repayment frequency:">
                    <Form.Select
                      aria-label="Select the frequency"
                      name="repaymentFrequency"
                      {...register('repaymentFrequency', {
                        required: 'This field is required'
                      })}
                      isInvalid={errors['repaymentFrequency']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['repaymentFrequency']
                      }
                    >
                      <option value="yearly">Yearly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="monthly">Monthly</option>
                      <option value="fortnightly">Fortnightly</option>
                      <option value="weekly">Weekly</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors['repaymentFrequency']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-2 g-3">
                <TermWizardInput
                  name="loanTerm"
                  label="Loan Term"
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', as: Col, lg: 4 }}
                  formControlProps={{
                    ...register('loanTerm', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />

                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="feeAmount"
                  label="Fee Amount"
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', lg: 4, as: Col }}
                  formControlProps={{
                    ...register('feeAmount', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />

                <Form.Group as={Col} lg={4} controlId="feeFrequency">
                  <FloatingLabel label="Fees frequency:">
                    <Form.Select
                      aria-label="Select the frequency"
                      name="feeFrequency"
                      {...register('feeFrequency', {
                        required: 'This field is required'
                      })}
                      isInvalid={errors['feeFrequency']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['feeFrequency']
                      }
                    >
                      <option value="yearly">Yearly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="monthly">Monthly</option>
                      <option value="fortnightly">Fortnightly</option>
                      <option value="weekly">Weekly</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors['feeFrequency']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-2 g-3">
                {/* Auto calculate values for the following fields */}

                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="totalInterest"
                  label="Total Interest"
                  disabled={true}
                  errors={errors}
                  formGroupProps={{ className: 'mb-1', lg: 4, as: Col }}
                  formControlProps={{
                    ...register('totalInterest', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />

                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="totalLoan"
                  label="Total Loan/Interest"
                  disabled={true}
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', lg: 4, as: Col }}
                  formControlProps={{
                    ...register('totalLoan', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />

                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="repayment"
                  label="Loan Repayment"
                  disabled={true}
                  errors={errors}
                  formGroupProps={{ className: 'mb-0', lg: 4, as: Col }}
                  formControlProps={{
                    ...register('repayment', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
              </Row>

              <div className="border-dashed border-bottom my-3" />
              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default LoanForm;
