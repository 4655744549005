import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/illustrations/corner-4.png';
import dashboardDark from 'assets/img/icons/spot-illustrations/6.svg';
import dashboard from 'assets/img/generic/dashboard-alt.png';
import Section from 'components/common/Section';
import AppContext, { AuthUserContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import IconButton from 'components/common/IconButton';
import NewsLetter from './NewsLetter';

const Hero = () => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);
  const { user, isLoading } = useContext(AuthUserContext);
  const [next, setNext] = useState('/start');

  useEffect(() => {
    if (!isLoading && user && user.isAdmin) {
      setNext('/dashboard');
    }
  }, [isLoading, user]);

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      position="center top"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-9 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={6}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-success"> Welcome to AuziPlan</h1>
          <h1 className="text-white fw-light">
            Bring{' '}
            <span className="fw-bold">
              <Typewriter
                words={['simplicity', 'knowledge', 'perfection']}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            to your planning experience.
          </h1>
          <p className="lead text-white opacity-75">
            Empower Business Success with AuziPlan - Your Strategic Planning
            Partner. Analyze, Collaborate, Succeed - Maximize growth through
            data-driven decisions and customizable features.
          </p>
          <NewsLetter />
        </Col>
        <Col xl={{ span: 6 }} className="align-self-center mt-4 mt-xl-0">
          <Link to={next}>
            <img
              className="img-fluid"
              src={isDark ? dashboardDark : dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
{
  /*
    <Button
      as={Link}
      variant="outline-light"
      size="lg"
      className="border-2 rounded-pill mt-4 fs-0 py-2"
      to={next}
    >
      Start planning with the AuziPlan
      <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
    </Button>
    <Partners />
    <Testimonial />
    <Cta />
    <Services />
    <Button
      as={Link}
      variant="outline-danger"
      className="mb-4 fs--1 border-2 rounded-pill"
      to="#!"
    >
      <span className="me-2" role="img" aria-label="Gift">
        🎁
      </span>
      Become a pro
    </Button>
  */
}
