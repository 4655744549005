import React, { Fragment, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../components/WizardInput';
import {
  Col,
  Card,
  Button,
  Overlay,
  Popover,
  ListGroup,
  Form,
  ListGroupItem,
  Row
} from 'react-bootstrap';

import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import PageHeader from 'components/common/PageHeader';
import { CompanyWizardContext } from 'context/Context';
import { currencies } from 'utils';
import Flex from 'components/common/Flex';

const CompanyTaxForm = ({ register, errors, watch, setValue, getValues }) => {
  // Accessing company context to get currency symbol
  const { company } = useContext(CompanyWizardContext);
  const symbol = company.currency && currencies[company.currency];

  // State hooks for managing popover visibility and target
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  // Click handler for showing/hiding popover
  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  // JSX structure with motion animation
  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            {/* Page header for company business assumptions */}
            <PageHeader
              title="Company Business Assumptions"
              description=""
              className="text-primary"
            />

            {/* Info icon button triggering popover */}
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            {/* Popover container */}
            <div className="border-none rounded mt-0">
              <div ref={ref}>
                {/* Overlay popover for help center */}
                <Overlay
                  show={show}
                  target={target}
                  placement="right"
                  container={ref.current}
                  containerPadding={20}
                >
                  <Popover id="popover-contained">
                    {/* Popover header */}
                    <Popover.Header as="h3">Help Center</Popover.Header>
                    {/* Popover body with company tax and GST/Sales tax information */}
                    <Popover.Body as="h6">
                      {/* Subtitle for company business assumptions */}
                      <PageHeader
                        title="Company Business Assumptions"
                        description=""
                        className="text-primary"
                      />
                      <div className="border-dashed border-bottom my-3" />
                      <div
                        className="border-none rounded mt-1"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        <div className="pt-3">
                          {/* Company Tax Rate and Payment Schedule */}

                          <h6 className="text-primary">
                            Company Business Assumptions Form Help Guide
                          </h6>
                          <p>
                            Welcome to the Company Setup form! This guide will
                            assist you in filling out the necessary details
                            accurately.
                          </p>

                          <ul>
                            <li>
                              <strong>Company Tax Rate:</strong> Enter the
                              applicable tax rate for your country. For example,
                              in Australia, the corporate tax rate is currently
                              39%.
                            </li>
                            <li>
                              <strong>Tax Payment Period:</strong> Select how
                              often you plan to pay your tax liabilities:
                              30-days, 60-days, 90-days, or at Year-End.
                            </li>
                          </ul>

                          {/* Company GST/Sales Tax Rate and Payment Schedule */}
                          <div className="pt-3">
                            <h6 className="text-primary ">
                              Company GST/Sales Tax Rate and Payment Schedule:
                            </h6>
                            <ul>
                              <li>
                                <strong>GST/VAT Sales Tax Rate:</strong> Specify
                                the GST or Sales Tax rate applicable in your
                                jurisdiction.
                              </li>
                              <li>
                                <strong>
                                  BAS Activity Statement Payment Period:
                                </strong>{' '}
                                Choose your preferred payment frequency for
                                GST/Sales tax payments: 30-days, 60-days,
                                90-days, or at Year-End.
                              </li>
                            </ul>
                          </div>

                          {/* Payroll Assumptions */}

                          <h6 className="text-primary ">
                            Payroll Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Payroll Increase:</strong> Indicate the
                              annual percentage increase in payroll expenses.
                            </li>
                            <li>
                              <strong>Superannuation Liability:</strong> Enter
                              the percentage of payroll allocated to
                              superannuation.
                            </li>
                            <li>
                              <strong>WorkCover Liability:</strong> Enter the
                              percentage of payroll allocated to WorkCover
                              expenses.
                            </li>
                          </ul>

                          {/* Operating Assumptions */}

                          <h6 className="text-primary ">
                            Operating Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Sales Increase:</strong> Specify the
                              annual percentage increase in sales.
                            </li>
                            <li>
                              <strong>Product Cost Increase:</strong> Enter the
                              annual percentage increase in product costs.
                            </li>
                            <li>
                              <strong>Operating Expenses Increase:</strong>{' '}
                              Indicate the annual percentage increase in
                              operating expenses.
                            </li>
                          </ul>

                          {/* General Assumptions */}

                          <h6 className="text-primary ">
                            General Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>
                                Marketing and Advertising Increase:
                              </strong>{' '}
                              Specify the annual percentage increase in
                              marketing and advertising expenses.
                            </li>
                            <li>
                              <strong>PP&E Depreciation Calculation:</strong>{' '}
                              Enter the percentage for PP&E (Property, Plant,
                              and Equipment) depreciation.
                            </li>
                            <li>
                              <strong>Bad Debt Written Off:</strong> Indicate
                              the percentage of bad debts that you expect to
                              write off annually.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Company Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>Company Tax Rate:</strong> Enter the
                              applicable tax rate for your country. For example,
                              in Australia, the corporate tax rate is currently
                              39%.
                            </li>
                            <li>
                              <strong>Tax Payment Period:</strong> Select how
                              often you plan to pay your tax liabilities:
                              30-days, 60-days, 90-days, or at Year-End.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Company GST/Sales Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>GST/VAT Sales Tax Rate:</strong> Specify
                              the GST or Sales Tax rate applicable in your
                              jurisdiction.
                            </li>
                            <li>
                              <strong>
                                BAS Activity Statement Payment Period:
                              </strong>
                              Choose your preferred payment frequency for
                              GST/Sales tax payments: 30-days, 60-days, 90-days,
                              or at Year-End.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Payroll Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Payroll Increase:</strong> Indicate the
                              annual percentage increase in payroll expenses.
                            </li>
                            <li>
                              <strong>Superannuation Liability:</strong> Enter
                              the percentage of payroll allocated to
                              superannuation.
                            </li>
                            <li>
                              <strong>WorkCover Liability:</strong> Enter the
                              percentage of payroll allocated to WorkCover
                              expenses.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            Operating Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Sales Increase:</strong> Specify the
                              annual percentage increase in sales.
                            </li>
                            <li>
                              <strong>Product Cost Increase:</strong> Enter the
                              annual percentage increase in product costs.
                            </li>
                            <li>
                              <strong>Operating Expenses Increase:</strong>{' '}
                              Indicate the annual percentage increase in
                              operating expenses.
                            </li>
                          </ul>

                          <h6 className="text-primary ">
                            General Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>
                                Marketing and Advertising Increase:
                              </strong>{' '}
                              Specify the annual percentage increase in
                              marketing and advertising expenses.
                            </li>
                            <li>
                              <strong>PP&E Depreciation Calculation:</strong>{' '}
                              Enter the percentage for PP&E (Property, Plant,
                              and Equipment) depreciation.
                            </li>
                            <li>
                              <strong>Bad Debt Written Off:</strong> Indicate
                              the percentage of bad debts that you expect to
                              write off annually.
                            </li>
                          </ul>

                          {/* Company Tax Rate and Payment Schedule */}
                          <h6 className="text-primary ">
                            Company Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>Company Tax Rate:</strong> Enter the
                              applicable tax rate for your country. For example,
                              in Australia, the corporate tax rate is currently
                              39%.
                            </li>
                            <li>
                              <strong>Tax Payment Period:</strong> Select how
                              often you plan to pay your tax liabilities:
                              30-days, 60-days, 90-days, or at Year-End.
                            </li>
                          </ul>

                          {/* Company GST/Sales Tax Rate and Payment Schedule */}
                          <h6 className="text-primary ">
                            Company GST/Sales Tax Rate and Payment Schedule:
                          </h6>
                          <ul>
                            <li>
                              <strong>GST/VAT Sales Tax Rate:</strong> Specify
                              the GST or Sales Tax rate applicable in your
                              jurisdiction.
                            </li>
                            <li>
                              <strong>
                                BAS Activity Statement Payment Period:
                              </strong>{' '}
                              Choose your preferred payment frequency for
                              GST/Sales tax payments: 30-days, 60-days, 90-days,
                              or at Year-End.
                            </li>
                          </ul>

                          {/* Payroll Assumptions */}
                          <h6 className="text-primary ">
                            Payroll Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Payroll Increase:</strong> Indicate the
                              annual percentage increase in payroll expenses.
                            </li>
                            <li>
                              <strong>Superannuation Liability:</strong> Enter
                              the percentage of payroll allocated to
                              superannuation.
                            </li>
                            <li>
                              <strong>WorkCover Liability:</strong> Enter the
                              percentage of payroll allocated to WorkCover
                              expenses.
                            </li>
                          </ul>

                          {/* Operating Assumptions */}
                          <h6 className="text-primary ">
                            Operating Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>Sales Increase:</strong> Specify the
                              annual percentage increase in sales.
                            </li>
                            <li>
                              <strong>Product Cost Increase:</strong> Enter the
                              annual percentage increase in product costs.
                            </li>
                            <li>
                              <strong>Operating Expenses Increase:</strong>{' '}
                              Indicate the annual percentage increase in
                              operating expenses.
                            </li>
                          </ul>

                          {/* General Assumptions */}
                          <h6 className="text-primary ">
                            General Assumptions:
                          </h6>
                          <ul>
                            <li>
                              <strong>
                                Marketing and Advertising Increase:
                              </strong>{' '}
                              Specify the annual percentage increase in
                              marketing and advertising expenses.
                            </li>
                            <li>
                              <strong>PP&E Depreciation Calculation:</strong>{' '}
                              Enter the percentage for PP&E (Property, Plant,
                              and Equipment) depreciation.
                            </li>
                            <li>
                              <strong>Bad Debt Written Off:</strong> Indicate
                              the percentage of bad debts that you expect to
                              write off annually.
                            </li>
                          </ul>

                          <p>
                            Thank you for providing these details! If you
                            encounter any issues or have questions, feel free to
                            reach out for further assistance.
                          </p>
                        </div>

                        <div className="border-dashed border-bottom my-3" />
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>

              {/* Form inputs for tax rate and payment schedule */}
              <Row className="mt-0 mb-2 g-3">
                <Col lg={6}>
                  <PercentageWizardInput
                    name="taxRate"
                    label="Company Tax Rate"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('taxRate', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'39%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={6}>
                  <h6 className="text-primary ">
                    {' '}
                    In how many days will you pay your tax Liabilities?
                  </h6>
                  <Form.Group className="mt-3 d-flex gap-3 flex-wrap">
                    {/* Radio buttons for tax payment period */}
                    <WizardInput
                      type="radio"
                      name="taxPeriod"
                      label="30-days"
                      errors={errors}
                      formControlProps={{
                        ...register('taxPeriod', {
                          required: 'This field is required'
                        }),
                        value: '30'
                      }}
                    />
                    <WizardInput
                      type="radio"
                      name="taxPeriod"
                      label="60-days"
                      errors={errors}
                      formControlProps={{
                        ...register('taxPeriod', {
                          required: 'This field is required'
                        }),
                        value: '60'
                      }}
                    />
                    <WizardInput
                      type="radio"
                      name="taxPeriod"
                      label="90-days"
                      errors={errors}
                      formControlProps={{
                        ...register('taxPeriod', {
                          required: 'This field is required'
                        }),
                        value: '90'
                      }}
                    />
                    <WizardInput
                      type="radio"
                      name="taxPeriod"
                      label="Year-End"
                      errors={errors}
                      formControlProps={{
                        ...register('taxPeriod', {
                          required: 'This field is required'
                        }),
                        value: 'year-end'
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Form inputs for GST/Sales tax rate and payment schedule */}
              <Row className="mt-0 mb-2 g-3">
                <Col lg={6}>
                  <PercentageWizardInput
                    name="gstSalesTaxRate"
                    label="GST/VAT Sales Tax Rate"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('gstSalesTaxRate', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'10%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={6}>
                  <h6 className="text-primary ">
                    In how many days will you pay your BAS Activity Statement?
                  </h6>
                  <Form.Group className="mt-3 d-flex gap-3 flex-wrap">
                    {/* Radio buttons for BAS payment period */}
                    <WizardInput
                      type="radio"
                      name="gstSalesTaxPeriod"
                      label="30-days"
                      errors={errors}
                      formGroupProps={{ className: 'mb-0' }}
                      formControlProps={{
                        ...register('gstSalesTaxPeriod', {
                          required: 'This field is required'
                        }),
                        value: '30'
                      }}
                    />
                    <WizardInput
                      type="radio"
                      name="gstSalesTaxPeriod"
                      label="60-days"
                      errors={errors}
                      formGroupProps={{ className: 'mb-0' }}
                      formControlProps={{
                        ...register('gstSalesTaxPeriod', {
                          required: 'This field is required'
                        }),
                        value: '60'
                      }}
                    />
                    <WizardInput
                      type="radio"
                      name="gstSalesTaxPeriod"
                      label="90-days"
                      errors={errors}
                      formGroupProps={{ className: 'mb-0' }}
                      formControlProps={{
                        ...register('gstSalesTaxPeriod', {
                          required: 'This field is required'
                        }),
                        value: '90'
                      }}
                    />
                    <WizardInput
                      type="radio"
                      name="gstSalesTaxPeriod"
                      label="Year-End"
                      errors={errors}
                      formGroupProps={{ className: 'mb-0' }}
                      formControlProps={{
                        ...register('gstSalesTaxPeriod', {
                          required: 'This field is required'
                        }),
                        value: 'year-end'
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Form inputs for payroll assumptions */}
              <Row className="mt-0 mb-0 g-3">
                <h6 className="text-primary ">Payroll Assumptions</h6>
                <Col lg={4}>
                  <PercentageWizardInput
                    name="payrollIncrease"
                    label="Payroll increase each year by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('payrollIncrease', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'5%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <PercentageWizardInput
                    name="superannuation"
                    label="Suparannuation liability % of payroll:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('superannuation', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'9%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <PercentageWizardInput
                    name="workCover"
                    label="WorkCove liability % of payroll:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('workCover', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'10%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              {/* Form inputs for operating assumptions */}
              <Row className="mt-0 mb-0 g-3">
                <h6 className="text-primary ">Operating Assumptions </h6>
                <Col lg={4}>
                  <PercentageWizardInput
                    name="salesIncrease"
                    label="Sales increase each year by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('salesIncrease', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'10%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <PercentageWizardInput
                    name="productCostIncrease"
                    label="Product cost increase each year by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('productCostIncrease', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'20%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={4}>
                  <PercentageWizardInput
                    name="operatingCostIncrease"
                    label="Operating Expenses increase by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('operatingCostIncrease', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'10%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              {/* Form inputs for general assumptions */}
              <Row className="mt-0 mb-0 g-3">
                <h6 className="text-primary ">General Assumptions </h6>
                <Col lg={4}>
                  <PercentageWizardInput
                    name="marketingIncrease"
                    label="Marketing and Advertising increase by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('marketingIncrease', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'10%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <PercentageWizardInput
                    name="depreciation"
                    label="PP&E Depreciation calculation by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('depreciation', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'10%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>

                <Col lg={4}>
                  <PercentageWizardInput
                    name="badDebt"
                    label="Bad debt written off by:"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('badDebt', {
                        required: 'This field is required'
                      })
                    }}
                    getValues={getValues}
                    defaultValue={'5%'}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              {/* Divider line */}
              <div className="border-dashed border-bottom my-3" />
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

// PropTypes for type validation
CompanyTaxForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func
};

export default CompanyTaxForm;
