import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, FloatingLabel, Row, Form } from 'react-bootstrap';
import api from 'utils/api';
import Timer from 'components/utilities/Timer';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [showTimer, setShowTimer] = useState(false);
  const handleTimeout = () => {
    setShowTimer(false);
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      api
        .post('/users/forget', {
          email: email
        })
        .then(response => {
          toast.success(
            `An email is sent to ${email} with password reset link`,
            {
              theme: 'colored'
            }
          );
          navigate('/confirm-mail');
        })
        .catch(error => {
          setShowTimer(true);
          if (error.code !== 'ERR_NETWORK') {
            if (error.response.status === 500) {
              return toast.error(`Server error`, {
                theme: 'colored'
              });
            }

            if (
              error.response.status === 401 ||
              error.response.status === 400
            ) {
              return toast.error(error.response.data.message, {
                theme: 'colored'
              });
            }
          }
          toast.error(`Something went wrong!`, {
            theme: 'colored'
          });
        });
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="border-dashed border-bottom my-3" />

      <Form className="mt-4" onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <FloatingLabel label="Email address">
            <Form.Control
              placeholder={'Email address'}
              value={email}
              name="email"
              onChange={({ target }) => setEmail(target.value)}
              type="email"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-3">
          <IconButton
            className="w-100"
            type="submit"
            disabled={!email || showTimer}
          >
            Send reset link
          </IconButton>
        </Form.Group>

        {showTimer && <Timer timeout={30} onTimeout={handleTimeout} />}
        <Link className="fs--1 text-600" to="#!">
          I can't recover my account using this page
          <span className="d-inline-block ms-1"> &rarr;</span>
        </Link>

        <Form.Group className="mb-3">
          <Link className="fs--1 mb-0 mt-4" to={`/`}>
            Back to AuziPlan
          </Link>
        </Form.Group>
      </Form>
    </Row>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'split' };

export default ForgetPasswordForm;
