import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OperatingExpenseFormModalContext } from 'context/Context';

const OperatingExpenseFormModalProvider = ({ children }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const value = { showFormModal, setShowFormModal };

  return (
    <OperatingExpenseFormModalContext.Provider value={value}>
      {children}
    </OperatingExpenseFormModalContext.Provider>
  );
};

OperatingExpenseFormModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default OperatingExpenseFormModalProvider;
