import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
//import ownerData from 'data/owners';
//import experiences from 'data/experiences';
//import owners from 'data/owners';
import React, { useContext, useState } from 'react';
import {
  Card,
  ProgressBar,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  FormSelect,
  FormGroup,
  Collapse
} from 'react-bootstrap';

/* 
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import Section from 'components/common/Section';
import CompaniesProvider from 'components/custom/providers/CompaniesProvider';
import CompanyWizardProvider from 'components/custom/wizards/companyWizard/CompanyWizardProvider';
import OwnerItem from '../OwnerItem';
import OwnerForm from './OwnerForm';
import TooltipBadge from 'components/common/TooltipBadge';
*/

import editing from 'assets/img/icons/spot-illustrations/4.svg';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { RevenueFormModalContext } from 'context/Context';
import RevenueWizard from 'components/custom/wizards/revenueWizard/RevenueWizard';
import PageHeader from 'components/common/PageHeader';

const RevenueSettings = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    RevenueFormModalContext
  );
  const [modalShow, setModalShow] = React.useState(false);
  const handleFormModelClose = () => {
    setShowFormModal(false);
  };
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-top justify-content-center">
        <Col lg={6} className="ps-lg-4 my-5 text-center">
          <img
            src={editing}
            className="img-fluid"
            alt=""
            style={{ maxWidth: '70%', height: 'auto' }}
          />
          <h2 className="test-primary"> Business Revenue Streams</h2>
          <p>
            Diversifying revenue streams across services, products, and online
            offerings is crucial for sustained growth and resilience. By
            expanding income sources, you mitigate dependency risks and adapt
            better to economic changes. Let's explore strategies to optimize
            these varied revenue streams while maintaining operational
            efficiency.
          </p>

          <Row className="align-items-start justify-content-between">
            <Col lg={6}>
              <div className="text-start pt-3">
                <Link
                  to="/StartupCostsSettings"
                  className="text-decoration-none"
                >
                  <IconButton
                    variant="outline-success"
                    icon="fa-solid fa-arrow-left"
                    size="lg"
                    className="mb-1 mx-0"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-end pt-3">
                <Link to="/help-center" className="text-decoration-none ">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-info"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
                <IconButton
                  onClick={() => setShowFormModal(true)}
                  variant="outline-primary"
                  size="lg"
                  icon="fa-solid fa-plus"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>{' '}
                <IconButton
                  onClick={() => setModalShow(true)}
                  variant="outline-warning"
                  size="lg"
                  icon="fa-solid fa-search"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>{' '}
                <Link
                  to="/operatingExpensesSettings"
                  className="text-decoration-none"
                >
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-right"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        //fullscreen={fullscreen}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Revenue Streams
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PageHeader
              title="Revenue Streams"
              description="How is your company generating revenue ?"
              className="mb-0"
            />
            <div className="border-none rounded mt-4">
              <div className="p-x1">
                <Flex
                  alignItems="center"
                  gap={4}
                  justifyContent={'between'}
                  wrap={'wrap'}
                ></Flex>
                <Form.Group as={Col} lg={12} controlId="startupCostType">
                  <FloatingLabel label="Startup Cost Type">
                    <Form.Select aria-label="Default select a cost type">
                      <option></option>
                      <option value="1">Products Revenue</option>
                      <option value="2">Serivice Revenue</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            size="sm"
            onClick={() => setModalShow(false)}
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFormModal}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        fullscreen={fullscreen}
        //size="xl"
        centered
      >
        <Container>
          <RevenueWizard variant="pills" validation={false} />
        </Container>
      </Modal>
    </Container>
  );
};

export default RevenueSettings;

{
  /* 
              <Modal show={showFormModal} fullscreen={fullscreen} onHide={() => setShow(false)}>
            */
}
{
  /* 
          <Link to="" className="text-decoration-none">
            <IconButton
              variant="outline-danger"
              icon="fa-solid fa-video"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>{' '}
            <h4 className="h4 mt-6 text-success">Stage - 5</h4>
        */
}

{
  /* <div className="border-dashed border-bottom my-3" /> 
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={handleFormModelClose}
            />
          </Modal.Header>
            <Modal.Footer>
              <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
          <Modal.Footer>
            <Button
              variant="outline-success"
              onClick={() => handleFormModelClose(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        */
}
