import React, { useContext, useState } from 'react';
import {
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  Card,
  ProgressBar,
  Tab,
  Nav,
  Ratio
} from 'react-bootstrap';

import editing from 'assets/img/icons/spot-illustrations/4.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FundingFormModalContext } from 'context/Context';
import FundingWizard from 'components/custom/wizards/fundingWizard/FundingWizard';
import IconButton from 'components/common/IconButton';
import OwnersList from 'components/custom/lists/stage2/OwnerList';
import InvestorsList from 'components/custom/lists/stage2/InvestorList';
import LoansList from 'components/custom/lists/stage2/loanList';
import PageHeader from 'components/common/PageHeader';

const OwnerSettings = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [videoModalShow, setVideoModalShow] = useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    FundingFormModalContext
  );
  const [settingView, setSettingView] = useState('owners');
  const handleFormModelClose = () => {
    setShowFormModal(false);
  };
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-top justify-content-center">
        <Col lg={6} className="ps-lg-4 my-5 text-center">
          <img
            src={editing}
            className="img-fluid"
            alt=""
            style={{ maxWidth: '70%', height: 'auto' }}
          />
          <h2 className="test-primary"> Business Funding</h2>
          <p>
            Business funding involves acquiring capital to sustain or expand a
            business. Potential sources encompass owners, investors, loans,
            personal savings, or grants, contingent on the business's stage and
            objectives. The required capital fluctuates according to the
            business's startup needs. How much capital have you raise?
          </p>

          <Row className="align-items-start justify-content-between">
            <Col lg={6}>
              <div className="text-start pt-3">
                <Link to="/BuzPitchSettings" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-left"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="text-end pt-3">
                <Link to="/help-center" className="text-decoration-none ">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-info"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
                <IconButton
                  onClick={() => setShowFormModal(true)}
                  variant="outline-primary"
                  size="lg"
                  icon="fa-solid fa-plus"
                  className="mb-1 mx-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
                <IconButton
                  onClick={() => setModalShow(true)}
                  variant="outline-warning"
                  size="lg"
                  icon="fa-solid fa-search"
                  className="mb-1 mx-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
                <Link
                  to="/StartupCostsSettings"
                  className="text-decoration-none"
                >
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-right"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Summary modal with table results*/}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="xl"
          //fullscreen={fullscreen}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setModalShow(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <Row
              as={motion.div}
              className="g-3"
              initial={{ x: '-20%' }}
              animate={{ x: '0%' }}
              exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
            >
              <Container className="mt-3">
                <PageHeader
                  title="Business Funding Contributions"
                  description="How have you raised capital to start ?"
                  className="mb-0"
                />
                <Card className="mt-3">
                  <div className="p-x1">
                    <Form.Group
                      as={Col}
                      lg={12}
                      controlId="type"
                      onChange={e => setSettingView(e.target.value)}
                    >
                      <FloatingLabel label="Type">
                        <Form.Select aria-label="Default select company role">
                          <option value="owners">Owners</option>
                          <option value="contributor">Investors</option>
                          <option value="loans">Loans</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                    <div className="my-3">
                      {settingView === 'owners' && (
                        <OwnersList close={() => setModalShow(false)} />
                      )}
                      {settingView === 'contributor' && (
                        <InvestorsList close={() => setModalShow(false)} />
                      )}
                      {settingView === 'loans' && (
                        <LoansList close={() => setModalShow(false)} />
                      )}
                    </div>
                  </div>
                </Card>
              </Container>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              variant="outline-success"
              size="sm"
              onClick={() => setModalShow(false)}
            >
              Close
            </IconButton>
          </Modal.Footer>
        </Modal>
        {/* Wizard modal*/}
        <Modal
          show={showFormModal}
          //size="xl"
          fullscreen={fullscreen}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Container>
            <FundingWizard variant="pills" validation={false} />
          </Container>
        </Modal>

        {/*Video modal */}
        <Modal
          size="lg"
          show={videoModalShow}
          onHide={() => setVideoModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Company Funding Setup
            </Modal.Title>
            <CloseButton
              variant="white"
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setVideoModalShow(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <Ratio aspectRatio="16x9">
              <iframe
                src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                allowFullScreen={true}
                title="AuziPlan"
              />
            </Ratio>
          </Modal.Body>
        </Modal>
      </Row>
    </Container>
  );
};

export default OwnerSettings;
