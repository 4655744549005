import IconButton from 'components/common/IconButton';
import React from 'react';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from 'utils/api';
import { toast } from 'react-toastify';

const CustomersTableHeader = ({
  selectedRowIds,
  globalFilter,
  setGlobalFilter,
  setCustomers,
  page
}) => {
  const [action, setAction] = React.useState('delete');
  const handleApplyAction = () => {
    let newCustomers = [];
    page.forEach(row => {
      if (row && row.original && !selectedRowIds[row.id]) {
        newCustomers.push(row.original);
      }
    });
    console.log('new', newCustomers);
    setCustomers(newCustomers);
    if (action === 'delete') {
      const result = page.map(row => {
        if (selectedRowIds[row.id]) {
          console.log('delete', row);
          return api.delete(`/users/delete/${row.original.id}`);
        }
      });
      Promise.all(result)
        .then(() => {
          toast.success('Users deleted successfully');
        })
        .catch(err => {
          console.log(err);
          toast.error('Error deleting customers');
        });
    }
  };

  return (
    <Row className="flex-between-center">
      <Col
        md={4}
        xs="auto"
        sm="auto"
        className="d-flex align-items-center pe-0 justify-content-space-between"
      >
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 me-4">Users</h5>
        <AdvanceTableSearchBox
          className="input-search-width ms-2"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          placeholder="Search"
        />
      </Col>
      {/* <Row className="flex-end-center mb-3">
        <Col xs="auto" sm={6} lg={4}>
        </Col>
      </Row> */}
      <Col md={8} xs="auto" sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              value={action}
              onChange={e => setAction(e.target.value)}
              size="sm"
              aria-label="Bulk actions"
            >
              <option value="delete">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleApplyAction}
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <Link to="/users/add">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
            </Link>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

CustomersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  page: PropTypes.array,
  setCustomers: PropTypes.func
};

export default CustomersTableHeader;
