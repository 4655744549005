import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import AccountForm from 'components/wizard/AccountForm';
import AccountingForm from './AccountingForm';

const ServiceForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const [purchasePrice, setDirectCost] = useState('');
  const [inDirectCost, setInDirectCost] = useState('');
  const [other, setOther] = useState('');
  const [totalGrossProfit, setTotalGrossProfit] = useState('');
  const [startupBudget, setStartupBudget] = useState('');
  const [yearlyBudget, setYearlyBudget] = useState('');
  const [weeklyBudget, setWeeklyBudget] = useState('');
  const [weeklyGrossProfit, setWeeklyPayroll] = useState('');
  const [totalGrossServiceRevenue, setTotalGrossRevenue] = useState('');
  const [totalServiceCost, setTotalProductCost] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      expenseType: 'Accounting',
      serviceType: 'Accounting',
      ratePerHour: '120',
      numberOfMonths: '6',
      numberOfhoursPerWeek: '1976',
      weeklyBudget: '$120,000'
    }
  });

  const calculateOpeningBalance = (amount, months) => {
    const parsedAmount = amount
      ? parseFloat(amount.replace(/[^0-9.]/g, ''))
      : 0;
    const parsedMonths = parseInt(months);

    const budget = parsedAmount * parsedMonths;
    setStartupBudget(
      budget.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const yearly = parsedAmount * 12;
    setYearlyBudget(
      yearly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate TotalGross Service Revenue
    const totalGrossServiceRevenue =
      parsedAmount * watch().numberOfhoursPerWeek;
    setTotalGrossRevenue(
      totalGrossServiceRevenue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate weekly budget
    const weekly = totalGrossServiceRevenue / 52;
    setWeeklyBudget(
      weekly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate purchasePrice
    const purchasePriceAmount = totalGrossServiceRevenue * 0.1; // 10% of TotalGross Service Revenue
    setDirectCost(
      purchasePriceAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate In Direct Cost
    const inDirectCostAmount = totalGrossServiceRevenue * 0.1; // 10% of TotalGross Service Revenue
    setInDirectCost(
      inDirectCostAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate other
    const otherAmount = totalGrossServiceRevenue * 0.1; // 10% of TotalGross Service Revenue
    setOther(
      otherAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate Total Gross Profit
    const totalGrossProfit =
      totalGrossServiceRevenue -
      purchasePriceAmount -
      inDirectCostAmount -
      otherAmount;
    setTotalGrossProfit(
      totalGrossProfit.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate totalServiceCost
    const totalServiceCost =
      purchasePriceAmount + inDirectCostAmount + otherAmount;
    setTotalProductCost(
      totalServiceCost.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate startupBudget
    const startupBudget =
      (purchasePriceAmount + inDirectCostAmount + otherAmount) * parsedMonths;
    setStartupBudget(
      startupBudget.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    // Calculate weekly payroll
    const weeklyGrossProfit = totalGrossProfit;
    setWeeklyPayroll(
      weeklyGrossProfit.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
  };

  useEffect(() => {
    calculateOpeningBalance(watch().ratePerHour, watch().numberOfMonths);
  }, [
    watch().ratePerHour,
    watch().numberOfMonths,
    watch().numberOfhoursPerWeek
  ]);

  useEffect(() => {
    if (edit === 'payrollExpense' && editDetails.id) {
      setValue('serviceType', editDetails.serviceType);
      setValue('ratePerHour', editDetails.ratePerHour);
      setValue('numberOfMonths', editDetails.numberOfMonths);
      setValue('numberOfhoursPerWeek', editDetails.numberOfhoursPerWeek);
      setValue('weeklyBudget', editDetails.weeklyBudget);

      calculateOpeningBalance(
        editDetails.ratePerHour,
        editDetails.numberOfMonths
      );
    }
  }, [edit]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    const payload = { companyId: companyDetails.id, ...data };

    api
      .post('/service-expense/create', payload)
      .then(res => {
        reset();
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Service Expenses"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="serviceType"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleSelectChange = event => {
    setSelectedDiv(event.target.value);
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="div" style={{ backgroundColor: '' }}>
                    {/* Your popover content */}
                    <Row
                      as={motion.div}
                      className="g-3"
                      initial={{ x: '-20%' }}
                      animate={{ x: '0%' }}
                      exit={{
                        x: window.innerWidth,
                        transition: { duration: 0.5 }
                      }}
                    >
                      <PageHeader
                        className="text-primary"
                        title="Revenue by Services"
                        description=""
                      />
                      <div
                        className="bor"
                        style={{ maxHeight: '280px', overflowY: 'auto' }}
                      >
                        <Row>
                          <h6>Description of Service Revenue</h6>
                          <p>
                            Service revenue typically refers to the income
                            generated by a company through the provision of
                            services rather than the sale of physical products.
                            It encompasses the fees charged for services
                            rendered, such as consulting, maintenance, repairs,
                            training, or any other service offered by the
                            business.
                          </p>
                          <p>
                            For example, a consulting firm earns service revenue
                            by providing advice or expertise to clients for a
                            fee, while a software company may generate service
                            revenue by offering technical support or training
                            services to its customers.
                          </p>
                          <p>
                            Service revenue is usually recognized on the income
                            statement when the service is performed or
                            delivered, rather than when payment is received.
                            This is in accordance with the accrual accounting
                            principle, which aims to match revenues with the
                            expenses incurred to generate them, providing a more
                            accurate picture of a company's financial
                            performance over time.
                          </p>
                          <p>
                            In financial statements, service revenue is
                            typically reported separately from other sources of
                            revenue, such as product sales, to provide
                            transparency into the different revenue streams of
                            the business. It's an essential metric for
                            evaluating the performance and growth of
                            service-based businesses.
                          </p>

                          <div className="border-dashed border-bottom my-3" />
                        </Row>
                      </div>
                    </Row>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>

            <div className=" mt-3">
              <Form.Group controlId="industryType">
                <FloatingLabel label="Industry Type">
                  <Form.Select
                    value={selectedDiv}
                    onChange={handleSelectChange}
                    aria-label="Select the Industry type"
                    name="industryType"
                  >
                    <option value="">Select Industry</option>
                    <option value="div1">Accounting Firm</option>
                    <option value="div2">Solicitors</option>
                    <option value="div3">Carpentry</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </div>

            {/* Render the selected div based on selectedDiv state */}
            <div>
              {selectedDiv === 'div1' && (
                <div>
                  <AccountingForm></AccountingForm>
                </div>
              )}
              {selectedDiv === 'div2' && <div></div>}
              {selectedDiv === 'div3' && <div></div>}
            </div>

            <Form onSubmit={handleSubmit(onSubmitData, onError)}></Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default ServiceForm;
