import React, { useContext, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  Tab,
  Nav,
  Ratio,
  ProgressBar,
  FloatingLabel
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/1.svg';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import IconButton from 'components/common/IconButton';
import StartupWizard from 'components/custom/wizards/startupWizard/StartupWizard';
import { StartupFormModalContext } from 'context/Context';
import PrepaidCostsList from 'components/custom/lists/stage3/PrepaidCostsList';
import MotorVehicleList from 'components/custom/lists/stage3/MotorVehicleList';
import PlantAndEquipmentList from 'components/custom/lists/stage3/PlantAndEquipmentList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inDepthItems } from 'data/support-desk/reportsData';
import SimpleBarReact from 'simplebar-react';
import PropTypes from 'prop-types';

const InDepthItem = ({ item, status }) => {
  return (
    <Row className="mt-2">
      <Col xs={3} sm={2} md={3} lg={2}>
        <p className="mb-0 fs--1 fw-semi-bold text-600 text-nowrap">
          {item.name}
        </p>
      </Col>
      <Col xs={9} sm={10} md={9} lg={10} className="d-flex align-items-center">
        <ProgressBar
          now={
            status === 'created'
              ? item.progressbarWidth[0]
              : status === 'resolved'
              ? item.progressbarWidth[1]
              : status === 'reopened'
              ? item.progressbarWidth[2]
              : item.progressbarWidth[3]
          }
          className="w-100 animated-progress-bar"
          style={{
            height: '6px',
            '--falcon-progressbar-width': `${
              status === 'created'
                ? item.progressbarWidth[0]
                : status === 'resolved'
                ? item.progressbarWidth[1]
                : status === 'reopened'
                ? item.progressbarWidth[2]
                : item.progressbarWidth[3]
            }%`
          }}
        />
        <p className="mb-0 fs--1 ps-3 fw-semi-bold text-600">
          {status === 'created'
            ? item.progressbarWidth[0]
            : status === 'resolved'
            ? item.progressbarWidth[1]
            : status === 'reopened'
            ? item.progressbarWidth[2]
            : item.progressbarWidth[3]}
        </p>
      </Col>
    </Row>
  );
};

InDepthItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    progressbarWidth: PropTypes.array.isRequired
  }),
  status: PropTypes.string.isRequired
};

const TabItem = ({ status }) => {
  return (
    <>
      <Row className="mx-0 border-bottom border-dashed">
        <Col
          md={6}
          className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed"
        >
          <h6 className="fs--1 mb-3">Tickets {status} Split by Source</h6>
          {inDepthItems.slice(0, 4).map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
        <Col md={6} className="p-x1">
          <h6 className="fs--1 mb-3">Tickets {status} Split by Priority</h6>
          {inDepthItems.slice(4, 8).map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
      </Row>
      <Row className="mx-0">
        <Col
          md={6}
          className="p-x1 border-md-end border-bottom border-md-bottom-0 border-dashed"
        >
          <h6 className="fs--1 mb-3">Tickets {status} Split by Status</h6>
          {inDepthItems.slice(8, 9).map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
        <Col md={6} className="p-x1">
          <h6 className="fs--1 mb-3">Tickets {status} Split by Category</h6>
          {inDepthItems.slice(9, 10).map((item, index) => (
            <InDepthItem item={item} key={index} status={status} />
          ))}
        </Col>
      </Row>
    </>
  );
};

TabItem.propTypes = {
  status: PropTypes.string.isRequired
};

const StartupCostsSettings = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    StartupFormModalContext
  );
  const [settingView, setSettingView] = useState('prepaid-cost');
  const handleFormModelClose = () => {
    setShowFormModal(false);
  };
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-top justify-content-center">
        <Col lg={6} className="ps-lg-4 my-5 text-center">
          <img
            src={editing}
            className="img-fluid"
            alt=""
            style={{ maxWidth: '70%', height: 'auto' }}
          />
          <h2 className="test-primary"> Business Startup Costs</h2>
          <p>
            Business startup costs are the expenses incurred when starting a new
            business. These costs typically include everything necessary to get
            the business up and running before it begins generating
            revenue.Common startup costs may encompass expenditures such as
            equipment purchases, legal fees, initial inventory.
          </p>

          <Row className="align-items-start justify-content-between">
            <Col lg={6}>
              <div className="text-start pt-3">
                <Link to="/ownerSettings" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-left"
                    className="mb-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-end pt-3">
                <Link to="/help-center" className="text-decoration-none ">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-info"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
                <IconButton
                  onClick={() => setShowFormModal(true)}
                  variant="outline-primary"
                  size="lg"
                  icon="fa-solid fa-plus"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>{' '}
                <IconButton
                  onClick={() => setModalShow(true)}
                  variant="outline-warning"
                  size="lg"
                  icon="fa-solid fa-search"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>{' '}
                <Link to="/revenueSettings" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-right"
                    className="mb-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="xl"
          //fullscreen={fullscreen}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setModalShow(false)}
            />
          </Modal.Header>

          <Modal.Body>
            <Container>
              <PageHeader
                title="  Pre-Startup Cost / Assets"
                description="What are your startup costs ?"
                className="mb-0"
              />

              {/* 
       




              <Tab.Container defaultActiveKey="tickets-created">
                <Card.Header className="p-0 bg-light overflow-hidden">
                  <SimpleBarReact>
                    <Nav className="nav-tabs tab-tickets-status flex-nowrap border-0">
                      <Nav.Item className="text-nowrap">
                        <Nav.Link
                          eventKey="tickets-created"
                          className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                        >
                          <FontAwesomeIcon
                            icon="ticket-alt"
                            className="text-600"
                          />
                          <h6 className="mb-0 text-600">
                            Tickets Created
                            <span> (25)</span>
                          </h6>
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item className="text-nowrap">
                        <Nav.Link
                          eventKey="tickets-resolved"
                          className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                        >
                          <FontAwesomeIcon icon="check" className="text-600" />
                          <h6 className="mb-0 text-600">Tickets Resolved</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="text-nowrap">
                        <Nav.Link
                          eventKey="tickets-reopened"
                          className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                        >
                          <FontAwesomeIcon
                            icon="envelope-open-text"
                            className="text-600"
                          />
                          <h6 className="mb-0 text-600">Tickets Reopened</h6>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="text-nowrap">
                        <Nav.Link
                          eventKey="tickets-unsolved"
                          className="mb-0 d-flex align-items-center gap-2 py-3 px-x1"
                        >
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            className="text-600"
                          />
                          <h6 className="mb-0 text-600">Tickets Unsolved</h6>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SimpleBarReact>
                </Card.Header>
                <Card.Body className="p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="tickets-created">
                      <TabItem status="created" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tickets-resolved">
                      <TabItem status="resolved" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tickets-reopened">
                      <TabItem status="reopened" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tickets-unsolved">
                      <TabItem status="unsolved" />
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>


 */}

              <div className="border-none rounded mt-4">
                <div className="p-x1">
                  <Form.Group as={Col} lg={12} controlId="startupCostType">
                    <FloatingLabel label="Startup Cost Type">
                      <Form.Select
                        aria-label="Default select a cost type"
                        onChange={e => setSettingView(e.target.value)}
                        value={settingView}
                      >
                        <option value="prepaid-cost">Pre-Paid Cost</option>
                        <option value="motor-vehicles">Motor Vehicles</option>
                        <option value="plant-and-equipment">
                          Plant and Equipment
                        </option>
                        <option value="land-and-building">
                          Land and Building
                        </option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </div>
                <div className="my-3">
                  {settingView === 'prepaid-cost' && (
                    <PrepaidCostsList close={() => setModalShow(false)} />
                  )}
                  {settingView === 'motor-vehicles' && (
                    <MotorVehicleList close={() => setModalShow(false)} />
                  )}
                  {settingView === 'plant-and-equipment' && (
                    <PlantAndEquipmentList close={() => setModalShow(false)} />
                  )}
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              variant="outline-success"
              size="sm"
              onClick={() => setModalShow(false)}
            >
              Close
            </IconButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFormModal}
          //size="xl"
          fullscreen={fullscreen}
          aria-label="contained-modal-title-vcenter"
          centered
        >
          <Container>
            <StartupWizard variant="pills" validation={false} />
          </Container>

          {/* <div className="border-dashed border-bottom my-3" /> 
            <Modal.Footer>
              <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
            <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={handleFormModelClose}
            />
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="outline-success"
              onClick={() => handleFormModelClose(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        */}
        </Modal>
      </Row>
    </Container>
  );
};

export default StartupCostsSettings;

{
  /* <div className="border-dashed border-bottom my-3" /> 
    <Modal.Footer>
      <Button onClick={() => setModalShow(false)}>Close</Button>
    </Modal.Footer>
    <Container>
    <div className="border-none rounded mt-4">
        <StartupWizard variant="pills" validation={false} />
      </div>
    </Container>
  */
}
