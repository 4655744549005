import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  FundingFormModalContext,
  FundingWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { currencies } from 'utils';

function Item({ details, investors, setInvestors, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleInvestorDelete = async () => {
    try {
      await api.delete(`/investors/delete/${details.id}`);
      toast.success('Investor deleted');
      const updatedInvestors = investors.filter(
        investor => investor.id !== details.id
      );
      setInvestors(updatedInvestors);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting investor');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('investor');
    setEditDetails(details);
    setStep(2);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{details.investorFirstName}</td>
        <td>{details.investorLastName}</td>
        <td>{details.investorType}</td>
        <td>
          {currencies[companyDetails.currency]}
          {details.investorContribution.slice(1)}
        </td>
        <td>{details.investorPercentageEquity}</td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.investorFirstName} ${details.investorLastName}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this investor?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleInvestorDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  investors: PropTypes.array,
  setInvestors: PropTypes.func,
  close: PropTypes.func
};

export default Item;
