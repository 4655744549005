import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'utils/api';
import PageHeader from 'components/common/PageHeader';

function UpdateUser() {
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const data = location.state;
  const [formData, setFormData] = useState(data || {});

  const handleSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    const payload = { ...formData };
    delete payload.id;
    delete payload.createdOn;
    delete payload.isActive;

    api
      .put(`/users/update/${formData.id}`, payload)
      .then(response => {
        console.log(response);
        toast.success(`User updated`, {
          theme: 'colored'
        });
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            return toast.error(`Server error`, {
              theme: 'colored'
            });
          }

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 409
          ) {
            return toast.error(error.response.data.message, {
              theme: 'colored'
            });
          }
        }

        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    const value = e.target.value;
    const valueToBeSet =
      value === 'true' ? true : value === 'false' ? false : value;
    setFormData({
      ...formData,
      [e.target.name]: valueToBeSet
    });
  };

  return (
    <>
      <PageHeader
        title="Update User"
        description={`You can update a user here.`}
        className="mb-3"
      ></PageHeader>
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="exampleFirstName">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleFieldChange}
                  defaultValue={formData.email}
                  value={formData.email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exampleLastName">
                <Form.Label>Administrator</Form.Label>
                <Form.Select
                  value={formData.isAdmin}
                  name="isAdmin"
                  onChange={handleFieldChange}
                  aria-label="Make Administrator"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Form.Select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exampleUserName">
                <Form.Label>Username</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend"
                    defaultValue={formData.username}
                    name="username"
                    value={formData.username}
                    onChange={handleFieldChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label>Verified</Form.Label>
                <Form.Select
                  value={formData.isVerified}
                  name="isVerified"
                  onChange={handleFieldChange}
                  aria-label="Mark as verified"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Form.Select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Archived</Form.Label>
                <Form.Select
                  value={formData.isArchived}
                  name="isArchived"
                  onChange={handleFieldChange}
                  aria-label="Mark as archived"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Form.Select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button className="mt-2" type="submit">
              Save
            </Button>
            <Button className="ms-2 mt-2" variant="link">
              <Link to="/dashboard/users">Go Back</Link>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default UpdateUser;
