import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { createNumberMask } from 'text-mask-addons';

function TermWizardInput({
  name,
  label,
  placeholder,
  time = 'years',
  formControlProps,
  formGroupProps,
  defaultValue = '',
  errors,
  setValue,
  watch
}) {
  if (defaultValue && watch && !watch(name)) {
    setValue(name, defaultValue);
  }

  const currencyFormValue = (watch && watch(name)) || '';
  const [term, setTerm] = useState('');

  useEffect(() => {
    if (currencyFormValue) {
      setTerm(currencyFormValue);
    }
  }, [currencyFormValue]);

  const defaultMaskOptions = {
    prefix: '',
    suffix: ` ${time}`,
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 2, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false
  };
  const maskOptions = {
    ...defaultMaskOptions,
    ...formControlProps.maskOptions
  };
  const currencyMask = createNumberMask(maskOptions);
  return (
    <Form.Group {...formGroupProps}>
      <FloatingLabel label={label}>
        <Form.Control
          as={MaskedInput}
          mask={currencyMask}
          inputMode="numeric"
          placeholder={placeholder}
          onChange={e => {
            setValue(name, e.target.value, {
              shouldValidate: true,
              shouldDirty: true
            });
            setTerm(e.target.value);
          }}
          value={term}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          aria-label={placeholder}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
}

TermWizardInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  time: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  defaultValue: PropTypes.string,
  watch: PropTypes.func
};

export default TermWizardInput;
