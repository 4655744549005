import {
  AuthWizardContext,
  CompaniesContext,
  CompanyFormModalContenxt,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from '../lottie/celebration.json';
import { Link } from 'react-router-dom';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

//import { parseCompany } from 'utils';
//import Flex from 'components/common/Flex';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Success = ({ reset }) => {
  const { company, setStep, setCompany } = useContext(CompanyWizardContext);
  const { companies, setCompanies } = useContext(CompaniesContext);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );
  const { setShowFormModal } = useContext(CompanyFormModalContenxt);
  const emptyData = () => {
    setShowFormModal(false);
    setStep(1);
    setCompany({});
    reset();
  };
  useEffect(() => {
    let payload = { ...company };
    if (payload.avatar && payload.avatar.length > 0) {
      payload['avatar'] = payload['avatar'][0];
    } else {
      toast.error('Company logo is required.');
      setStep(1);
      return;
    }
    // if (payload['bankBalance']) {
    //   payload['bankBalance'] = parseFloat(
    //     `${payload['bankBalance']}`.replace(/,/gi, '')
    //   );
    // }
    // if (payload['creditorsBalance']) {
    //   payload['creditorsBalance'] = parseFloat(
    //     `${payload['creditorsBalance']}`.replace(/,/gi, '')
    //   );
    // }
    // if (payload['debtorsBalance']) {
    //   payload['debtorsBalance'] = parseFloat(
    //     `${payload['debtorsBalance']}`.replace(/,/gi, '')
    //   );
    // }
    // check if company is being edited
    if (company.id && company.userId) {
      delete payload['userId'];
      delete payload['id'];
      delete payload['createdOn'];
      delete payload['isArchived'];
      payload = { id: company.id, data: payload };
      api
        .post(`/companies/update`, payload)
        .then(res => {
          emptyData();
          const newCompany = { ...payload.data };
          newCompany.id = company.id;
          const updatedCompanies = companies.filter(doc => {
            if (doc.id === company.id) {
              return false;
            }
            return true;
          });
          setCompanies([...updatedCompanies, newCompany]);
          // if we have updated the selected company, update the selected company
          if (companyDetails.id === company.id) {
            console.log(res.data);
            setCompanyDetails(res.data);
          }
          toast.success('Company updated successfully', {
            theme: 'colored'
          });
        })
        .catch(error => {
          console.log(error);
          if (error.code !== 'ERR_NETWORK') {
            if (error.response.status === 500) {
              toast.error(`Server error`, {
                theme: 'colored'
              });
              return;
            }
            if (
              error.response.status === 401 ||
              error.response.status === 400
            ) {
              toast.error(error.response.data.message || error.response.data, {
                theme: 'colored'
              });
              return;
            }
          }
          toast.error(`Something went wrong!`, {
            theme: 'colored'
          });
        });
      return;
    }
    api
      .post('/companies/create', payload)
      .then(res => {
        setCompanies([...companies, res.data]);
        // if we don't have a company selected, select the newly created one
        if (!companyDetails.id) {
          setCompanyDetails(res.data);
        }
        emptyData();
        toast.success('Company created successfully', {
          theme: 'colored'
        });
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }
          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  }, []);
  return (
    <>
      <div className="border-none rounded mt-4">
        <Row className="mb-3">
          <Col className="text-center">
            <div className="wizard-lottie-wrapper">
              <div className="wizard-lottie mx-auto">
                <Lottie animationData={celebration} loop={true} />
              </div>
            </div>
            <h4 className="mb-1">Your company is all set!</h4>
            <p className="fs-0">Now head over to Stage 2 Startup Costs</p>

            <IconButton
              color="warning"
              variant="outline-warning"
              size="lg"
              icon="fa-solid fa-arrow-left"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              onClick={emptyData}
            ></IconButton>

            <IconButton
              type="submit"
              color="success"
              variant="outline-success"
              size="lg"
              icon="fa-solid fa-arrow-right"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              as={Link}
              to="/ownerSettings"
            ></IconButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
