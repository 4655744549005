import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';

function MotorVehicleList({ close }) {
  const [motorVehicles, setMotorVehicles] = useState([]);
  const [totalMonthlyRepayment, setTotalMonthlyRepayment] = useState(0);
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/motor-vehicles/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setMotorVehicles(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setTotalMonthlyRepayment(
      motorVehicles.reduce(
        (acc, motorVehicles) =>
          acc +
          parseFloat(motorVehicles.monthlyPayment.replace(/[^0-9.-]+/g, '')),
        0
      )
    );
  }, [motorVehicles]);

  return (
    <>
      <Table responsive className="">
        <thead>
          <tr>
            <th scope="col">Qty</th>
            <th scope="col">Vehicle Type</th>
            <th scope="col">Amount (Inc. GST)</th>
            <th scope="col">Interest Rate</th>
            <th scope="col">Monthly Repayment</th>
            <th className="text-end" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {motorVehicles.map(motorVehicle => (
            <Item
              key={motorVehicle.id}
              details={motorVehicle}
              motorVehicles={motorVehicles}
              setMotorVehicles={setMotorVehicles}
              close={close}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="text-center">
              <span className="fw-bold">
                Total Monthly Repayment:{' '}
                {`${
                  currencies[companyDetails.currency]
                } ${totalMonthlyRepayment.toLocaleString('en-US')}`}
              </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

MotorVehicleList.propTypes = {
  close: PropTypes.func
};

export default MotorVehicleList;
