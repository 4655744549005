import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  FundingFormModalContext,
  FundingWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { currencies } from 'utils';

function Item({ details, owners, setOwners, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleOwnerDelete = async () => {
    try {
      await api.delete(`/owners/delete/${details.id}`);
      toast.success('Owner deleted');
      const updatedOwners = owners.filter(owner => owner.id !== details.id);
      setOwners(updatedOwners);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting owner');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('owner');
    setEditDetails(details);
    setStep(1);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{details.ownerFirstName}</td>
        <td>{details.ownerLastName}</td>
        <td>{details.companyRole}</td>
        <td>
          {currencies[companyDetails.currency]}
          {details.ownerContribution.slice(1)}
        </td>
        <td>{details.ownerPercentageEquity}</td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.ownerFirstName} ${details.ownerLastName}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this owner?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleOwnerDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  owners: PropTypes.array,
  setOwners: PropTypes.func,
  close: PropTypes.func
};

export default Item;
