import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Form,
  Alert,
  Card,
  Button,
  Row,
  Col,
  Overlay,
  Popover,
  Table,
  FloatingLabel
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import WizardInput from '../components/WizardInput';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import { toast } from 'react-toastify';
import api from 'utils/api';

import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

function MotorVehicleForm() {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  // convert to use useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      carPrice: '0',
      tradeInAmount: '0',
      downPayment: '0',
      loanAmount: '0',
      gstAmount: '0',
      quantity: 1,
      vehicleType: 'Sedan',
      interestRate: '%',
      loanYears: 5,
      monthlyPayment: '0',
      totalInterest: '0',
      monthlyInterest: '0',
      totalAmountWithInterest: '0',
      // '08/31/2021'
      loanStartDate: new Date().toISOString().slice(0, 10),
      depreciationMethod: 'straight-line',
      monthlyDepreciation: '0',
      yearlyDepreciation: '0'
    }
  });

  useEffect(() => {
    if (edit === 'motorVehicle' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('carPrice', editDetails.carPrice);
      setValue('tradeInAmount', editDetails.tradeInAmount);
      setValue('downPayment', editDetails.downPayment);
      setValue('loanAmount', editDetails.loanAmount);
      setValue('gstAmount', editDetails.gstAmount);
      setValue('quantity', editDetails.quantity);
      setValue('vehicleType', editDetails.vehicleType);
      setValue('interestRate', editDetails.interestRate);
      setValue('loanYears', editDetails.loanYears);
      setValue('monthlyPayment', editDetails.monthlyPayment);
      setValue('totalInterest', editDetails.totalInterest);
      setValue('monthlyInterest', editDetails.monthlyInterest);
      setValue('totalAmountWithInterest', editDetails.totalAmountWithInterest);
      setValue(
        'loanStartDate',
        new Date(editDetails.loanStartDate.slice(0, 10))
      );
      setValue('depreciationMethod', editDetails.depreciationMethod);
      setValue('monthlyDepreciation', editDetails.monthlyDepreciation);
      setValue('yearlyDepreciation', editDetails.yearlyDepreciation);
    }
  }, [edit]);

  const watchCarPrice = watch('carPrice');
  const watchTradeInAmount = watch('tradeInAmount');
  const watchDownPayment = watch('downPayment');
  const watchQuantity = watch('quantity');
  const watchVehicleType = watch('vehicleType');
  const watchInterestRate = watch('interestRate');
  const watchLoanYears = watch('loanYears');
  const watchLoanStartDate = watch('loanStartDate');
  const watchDepreciationMethod = watch('depreciationMethod');
  const watchLoanAmount = watch('loanAmount');

  // Details to be displayed
  const watchGstAmount = watch('gstAmount');
  const watchMonthlyPayment = watch('monthlyPayment');
  const watchTotalInterest = watch('totalInterest');
  const watchMonthlyInterest = watch('monthlyInterest');
  const watchTotalAmountWithInterest = watch('totalAmountWithInterest');
  const watchMonthlyDepreciation = watch('monthlyDepreciation');
  const watchYearlyDepreciation = watch('yearlyDepreciation');

  // watchQuantity * (watchCarPrice + watchGstAmount)
  const amountIncludingGst = () => {
    if (watchQuantity && watchCarPrice && watchGstAmount) {
      return (
        watchQuantity *
        (parseFloat(watchCarPrice.replace(/[^0-9.]/g, '')) +
          parseFloat(watchGstAmount.replace(/[^0-9.]/g, '')))
      ).toLocaleString('en-US', {
        minimumFractionDigits: 2
      });
    }
    return 'N/A';
  };

  useEffect(() => {
    console.table({
      watchCarPrice,
      watchTradeInAmount,
      watchDownPayment,
      watchQuantity,
      watchInterestRate,
      watchLoanYears,
      watchLoanStartDate,
      watchDepreciationMethod,
      watchVehicleType
    });
    if (
      watchCarPrice &&
      watchTradeInAmount &&
      watchDownPayment &&
      watchQuantity &&
      watchInterestRate &&
      watchLoanYears &&
      watchLoanStartDate &&
      watchDepreciationMethod &&
      watchVehicleType
    ) {
      const carPrice = parseFloat(watchCarPrice.replace(/[^0-9.]/g, ''));
      const tradeInAmount = parseFloat(
        watchTradeInAmount.replace(/[^0-9.]/g, '')
      );
      const downPayment = parseFloat(watchDownPayment.replace(/[^0-9.]/g, ''));
      const quantity = parseFloat(watchQuantity);
      const interestRate = parseFloat(
        watchInterestRate.replace(/[^0-9.]/g, '')
      );
      const loanYears = watchLoanYears;
      const loanStartDate = watchLoanStartDate;
      const depreciationMethod = watchDepreciationMethod;
      const loanAmount = parseFloat(watchLoanAmount.replace(/[^0-9.]/g, ''));

      console.table({
        carPrice,
        tradeInAmount,
        downPayment,
        quantity,
        interestRate,
        loanYears,
        loanStartDate,
        depreciationMethod,
        loanAmount
      });

      const loanAmountAfterDownPayment = carPrice - tradeInAmount - downPayment;
      // setLoanAmount(loanAmountAfterDownPayment * quantity);
      setValue(
        'loanAmount',
        currencies[companyDetails.currency] +
          ' ' +
          (loanAmountAfterDownPayment * quantity).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('loanAmount', loanAmountAfterDownPayment * quantity);

      const gst = carPrice * 0.1;
      // setGstAmount(gst * quantity);
      // setValue('gstAmount', gst * quantity);
      setValue(
        'gstAmount',
        currencies[companyDetails.currency] +
          ' ' +
          (gst * quantity).toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('gstAmount', gst * quantity);

      const totalLoanAmount = (loanAmountAfterDownPayment + gst) * quantity;

      const monthlyInterestRate = interestRate / 100 / 12;
      const totalPayments =
        loanYears * 12 -
        getMonthsDifference(new Date(), new Date(loanStartDate));
      const numerator =
        monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments);
      const denominator = Math.pow(1 + monthlyInterestRate, totalPayments) - 1;
      const calculatedMonthlyPayment =
        totalLoanAmount * (numerator / denominator);
      // setMonthlyPayment(calculatedMonthlyPayment.toFixed(2));
      // setValue('monthlyPayment', calculatedMonthlyPayment.toFixed(2));
      setValue(
        'monthlyPayment',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedMonthlyPayment.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('monthlyPayment', calculatedMonthlyPayment.toFixed(2));

      // Calculate total interest based on carPrice, tradeInAmount, downPayment, and interestRate
      const calculatedTotalInterest =
        (carPrice - (tradeInAmount + downPayment)) * (interestRate / 100);
      setValue(
        'totalInterest',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedTotalInterest.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );

      setValue(
        'totalInterest',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedTotalInterest.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log(
        'totalInterest',
        calculatedTotalInterest.toLocaleString('en-US', {
          minimumFractionDigits: 2
        })
      );

      const totalInterest = parseFloat(
        watchTotalInterest.replace(/[^0-9.]/g, '')
      );
      const loanMonths = watchLoanYears * 12;
      const calculatedMonthlyInterest = (totalInterest / loanMonths).toFixed(2);
      setValue(
        'monthlyInterest',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedMonthlyInterest.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('monthlyInterest', calculatedMonthlyInterest);

      // setMonthlyInterest(calculatedMonthlyInterest);
      // setValue('monthlyInterest', calculatedMonthlyInterest);
      setValue(
        'monthlyInterest',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedMonthlyInterest.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('monthlyInterest', calculatedMonthlyInterest);

      const calculatedTotalAmountWithInterest = (
        totalLoanAmount + parseFloat(calculatedTotalInterest)
      ).toFixed(2);
      // setTotalAmountWithInterest(calculatedTotalAmountWithInterest);
      // setValue('totalAmountWithInterest', calculatedTotalAmountWithInterest);
      setValue(
        'totalAmountWithInterest',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedTotalAmountWithInterest.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('totalAmountWithInterest', calculatedTotalAmountWithInterest);

      // Calculate straight-line depreciation
      let calculatedMonthlyDepreciation = 0;
      let calculatedYearlyDepreciation = 0;
      if (depreciationMethod === 'straight-line') {
        calculatedMonthlyDepreciation = carPrice / loanYears / 12;
        calculatedYearlyDepreciation = carPrice / loanYears;
      }

      // setMonthlyDepreciation(calculatedMonthlyDepreciation);
      // setYearlyDepreciation(calculatedYearlyDepreciation);
      // setValue('monthlyDepreciation', calculatedMonthlyDepreciation);
      // setValue('yearlyDepreciation', calculatedYearlyDepreciation);
      setValue(
        'monthlyDepreciation',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedMonthlyDepreciation.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      setValue(
        'yearlyDepreciation',
        currencies[companyDetails.currency] +
          ' ' +
          calculatedYearlyDepreciation.toLocaleString('en-US', {
            minimumFractionDigits: 2
          })
      );
      console.log('monthlyDepreciation', calculatedMonthlyDepreciation);
      console.log('yearlyDepreciation', calculatedYearlyDepreciation);
    }
  }, [
    watchCarPrice,
    watchTradeInAmount,
    watchDownPayment,
    watchInterestRate,
    watchLoanYears,
    watchQuantity,
    watchVehicleType,
    watchLoanStartDate,
    watchDepreciationMethod
  ]);

  const getMonthsDifference = (date1, date2) => {
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();
    return yearDiff * 12 + monthDiff;
  };

  // const onSubmitData = async () => {
  //   try {
  //     // Code for saving form data to the database using Prisma
  //   } catch (error) {
  //     console.error('Error saving form data:', error);
  //   }
  // };

  const onSubmitData = data => {
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : {
            companyId: companyDetails.id,
            ...data
          };
    // 'mm/dd/yyyy'
    console.log('payload.loanStartDate', payload.loanStartDate);
    // payload.loanStartDate = new Date(payload.loanStartDate).toISOString();
    console.log('payload', payload);

    api
      .post(
        isEditing ? '/motor-vehicles/update' : '/motor-vehicles/create',
        payload
      )
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message="Do you want to add more motor vehicles?"
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="loanStartDate"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Add Company Cars"
              description=""
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained" style={{ backgroundColor: '' }}>
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body
                    as="div"
                    style={{ backgroundColor: '#', padding: '10px' }}
                  >
                    <Row
                      as={motion.div}
                      className="g-3"
                      initial={{ x: '-20%' }}
                      animate={{ x: '0%' }}
                      exit={{
                        x: window.innerWidth,
                        transition: { duration: 0.5 }
                      }}
                    >
                      <PageHeader
                        className="text-primary"
                        title="Company Cars"
                        description=""
                      />
                      <div
                        className="border-none rounded mt-2 p-3"
                        style={{ maxHeight: '400px', overflowY: 'auto' }}
                      >
                        <h3>What are Company Cars?</h3>
                        <p style={{ textAlign: 'left' }}>
                          Company cars are vehicles provided by companies to
                          their employees for business-related purposes. These
                          cars are typically owned or leased by the company and
                          are used by employees for tasks such as client
                          meetings, sales visits, or other work-related travel.
                        </p>

                        <p style={{ textAlign: 'left' }}>
                          They can range from sedans to SUVs, and their usage
                          policies vary depending on the company's guidelines.
                          Often seen as a perk or benefit, company cars can also
                          serve as a branding tool, showcasing the company's
                          image while on the road.
                        </p>

                        <h3>How to Complete the Form</h3>
                        <p style={{ textAlign: 'left' }}>
                          Thank you for using our vehicle finance calculator. To
                          accurately calculate your financial details, please
                          follow these steps to complete the form:
                        </p>

                        <ol>
                          <li>
                            <h4>Loan Details Section</h4>
                            <ul>
                              <li>
                                <strong>Loan Start Date:</strong> Select the
                                date when your loan agreement begins.
                              </li>
                              <li>
                                <strong>Depreciation Method:</strong> Choose
                                either "Straight Line" or "Other Method" for
                                vehicle depreciation.
                              </li>
                              <li>
                                <strong>Vehicle Type:</strong> Select the type
                                of vehicle you are financing (sedan, van, or
                                truck).
                              </li>
                              <li>
                                <strong>Quantity:</strong> Enter the number of
                                vehicles financed.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h4>Financial Details Section</h4>
                            <ul>
                              <li>
                                <strong>Car Price:</strong> Enter the total cost
                                of the vehicle(s).
                              </li>
                              <li>
                                <strong>Trade-in Amount:</strong> Input the
                                value of any vehicle(s) you are trading in.
                              </li>
                              <li>
                                <strong>Down Payment:</strong> Enter the initial
                                amount paid upfront.
                              </li>
                              <li>
                                <strong>Interest Rate:</strong> Input the annual
                                interest rate for your loan.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h4>Loan Terms Section</h4>
                            <ul>
                              <li>
                                <strong>Loan Years:</strong> Enter the duration
                                of the loan in years.
                              </li>
                              <li>
                                <strong>GST Amount:</strong> This field will
                                automatically calculate based on the entered
                                values.
                              </li>
                              <li>
                                <strong>Monthly Depreciation:</strong>{' '}
                                Automatically calculated based on depreciation
                                method and input values.
                              </li>
                              <li>
                                <strong>Monthly Payment:</strong> Calculated
                                monthly loan payment based on entered details.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h4>Additional Calculated Values</h4>
                            <ul>
                              <li>
                                <strong>Yearly Depreciation:</strong> Calculated
                                yearly depreciation amount based on selected
                                method.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <h4>Submission</h4>
                            <ul>
                              <li>
                                Ensure all required fields (marked with *) are
                                filled out correctly.
                              </li>
                              <li>
                                Review your entries for accuracy before
                                submitting the form.
                              </li>
                            </ul>
                          </li>
                        </ol>

                        <p style={{ textAlign: 'left' }}>
                          If you encounter any issues or have questions, please
                          refer to our help section or contact support for
                          assistance.
                        </p>

                        <p style={{ textAlign: 'left' }}>
                          Thank you for using our finance calculator!
                        </p>
                      </div>
                    </Row>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>

            <Form
              onSubmit={handleSubmit(onSubmitData, onError)}
              className="w-100"
            >
              <Row className="mt-0 mb-2 g-3">
                <h6>Make a list of all your pre-paid expenses</h6>
                <Col lg={3}>
                  <WizardInput
                    type="date"
                    label="Loan Start Date"
                    name="loanStartDate"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('loanStartDate', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    getValues={getValues}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Group controlId="depreciationMethod">
                    <FloatingLabel label="Depreciation Method">
                      <Form.Select>
                        <option value="straight-line">Straight Line</option>
                        <option value="other-method">Other Method</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <WizardInput
                    type="select"
                    label="Vehicle Type"
                    name="vehicleType"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('vehicleType', {
                        required: 'This field is required'
                      })
                    }}
                    options={['sedan', 'van', 'truck']}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Group controlId="quantity">
                    <FloatingLabel label="Quantity">
                      <Form.Control
                        type="number"
                        name="quantity"
                        {...register('quantity', {
                          required: 'This field is required',
                          valueAsNumber: true
                        })}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="g-3 pt-3">
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="carPrice"
                    label="Car Price"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('carPrice', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="tradeInAmount"
                    label="Trade-in Amount"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('tradeInAmount', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="downPayment"
                    label="Down Payment"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('downPayment', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
                <Col lg={3}>
                  <PercentageWizardInput
                    name="interestRate"
                    label="Interest Rate"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('interestRate', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              <Row className="g-3 pt-3">
                <Col lg={3}>
                  <Form.Group controlId="loanYears">
                    <FloatingLabel label="Loan Years">
                      <Form.Control
                        type="number"
                        name="loanYears"
                        {...register('loanYears', {
                          required: 'This field is required',
                          valueAsNumber: true
                        })}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="gstAmount"
                    label="GST Amount"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('gstAmount', {
                        required: 'This field is required'
                      })
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true}
                  />
                </Col>
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="monthlyDepreciation"
                    label="Monthly Depreciation"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('monthlyDepreciation')
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true} // Disable input for calculated values
                  />
                </Col>
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="monthlyPayment"
                    label="Monthly Payment"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('monthlyPayment')
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true} // Disable input for calculated values
                  />
                </Col>
              </Row>

              <Row className="g-3 pt-3">
                <Col lg={3}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="yearlyDepreciation"
                    label="Yearly Depreciation"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('yearlyDepreciation')
                    }}
                    setValue={setValue}
                    watch={watch}
                    disabled={true} // Disable input for calculated values
                  />
                </Col>
                <Col lg={9}></Col> {/* Empty column to balance layout */}
              </Row>

              <div className="border-dashed border-bottom my-3" />

              <Row className="g-3">
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      className="float-end"
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
}

export default MotorVehicleForm;
