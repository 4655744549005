import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const PotentialCustomers = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="potentialCustomers" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Potential Customers</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div className="border-dashed border-bottom my-3" />
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Potential Customers</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Market Segmentation</h6>
                      <p>
                        Break down the market into distinct segments based on
                        demographics, psychographics, behaviors, and needs. By
                        understanding the unique characteristics and preferences
                        of different customer segments, we can tailor our
                        marketing efforts and offerings to resonate with each
                        group effectively.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Customer Profiling</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Develop detailed profiles of our ideal customers,
                        including their age, gender, income level, occupation,
                        interests, and pain points. This information will help
                        us create targeted marketing campaigns and personalized
                        experiences that appeal to their specific needs and
                        preferences.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>competitive Analysis</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Study our competitors to identify their target customers
                        and market positioning. Analyze their strengths and
                        weaknesses to uncover opportunities for differentiation
                        and market penetration. By understanding the competitive
                        landscape, we can identify underserved or overlooked
                        customer segments that represent growth opportunities
                        for us.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Customer feedback</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Leverage customer feedback, surveys, and market research
                        to gain insights into customer preferences, satisfaction
                        levels, and unmet needs. By listening to our customers
                        and addressing their concerns or suggestions, we can
                        strengthen customer loyalty and attract new customers
                        through word-of-mouth referrals.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6> Industry Trends and Analysis</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Stay abreast of industry trends, market dynamics, and
                        emerging technologies that may influence customer
                        behavior and preferences. Anticipating and adapting to
                        changes in the market landscape will position us as
                        innovative leaders and enable us to meet evolving
                        customer demands effectively.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Networking and Partnerships</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Build relationships with industry influencers,
                        complementary businesses, and strategic partners who can
                        help us expand our reach and access new customer
                        segments. Collaborating with trusted partners can
                        provide valuable referrals and endorsements that enhance
                        our credibility and visibility in the market.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Test Marketing Campaigns</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        Experiment with targeted marketing campaigns across
                        different channels to gauge the response of potential
                        customers. Monitor key performance indicators (KPIs)
                        such as conversion rates, click-through rates, and
                        engagement metrics to assess the effectiveness of our
                        marketing efforts and refine our strategies accordingly.
                        d
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          As we strategize and plan for the growth of our business, it's
          essential to have a clear understanding of our potential customers and
          how we can effectively reach and engage with them. Identifying and
          targeting the right audience is crucial for driving sales and building
          long-term relationships. Here are some key points to consider when
          identifying potential customers:
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default PotentialCustomers;
