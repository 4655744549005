import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, FloatingLabel, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import api from 'utils/api';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

const RegistrationForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAccepted: false
  });

  const navigate = useNavigate();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      return toast.error(`Passwords don't match!`, {
        theme: 'colored'
      });
    }

    const data = { ...formData };
    delete data.confirmPassword;
    delete data.isAccepted;

    api
      .post('/users/register', data)
      .then(response => {
        console.log(response);
        navigate('/confirm-mail');
        toast.success(`Successfully registered as ${formData.username}`, {
          theme: 'colored'
        });
      })
      .catch(error => {
        console.log(error);
        setFormData({
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          isAccepted: false
        });

        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            return toast.error(`Server error`, {
              theme: 'colored'
            });
          }

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 409
          ) {
            return toast.error(error.response.data.message, {
              theme: 'colored'
            });
          }
        }

        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="border-dashed border-bottom my-3" />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <FloatingLabel label="User Name">
            <Form.Control
              placeholder={!hasLabel ? 'Username' : ''}
              value={formData.username}
              name="username"
              onChange={handleFieldChange}
              type="text"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-3">
          <FloatingLabel label="Email Address">
            <Form.Control
              placeholder={!hasLabel ? 'Email address' : ''}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
            />
          </FloatingLabel>
        </Form.Group>

        <Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            <FloatingLabel label="Password">
              <Form.Control
                placeholder={!hasLabel ? 'Password' : ''}
                value={formData.password}
                name="password"
                onChange={handleFieldChange}
                type="password"
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <FloatingLabel label="Confirm Password">
              <Form.Control
                placeholder={!hasLabel ? 'Confirm Password' : ''}
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleFieldChange}
                type="password"
              />
            </FloatingLabel>
          </Form.Group>
        </Row>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="acceptCheckbox"
            className="form-check"
          >
            <Form.Check.Input
              type="checkbox"
              name="isAccepted"
              checked={formData.isAccepted}
              onChange={e =>
                setFormData({
                  ...formData,
                  isAccepted: e.target.checked
                })
              }
            />
            <Form.Check.Label className="form-label">
              I accept the <Link to="/privacy-policy">terms</Link> and{' '}
              <Link to="/privacy-policy">privacy policy</Link>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <Form.Group className="mb-4">
          <IconButton
            className="w-100"
            type="submit"
            disabled={
              !formData.username ||
              !formData.email ||
              !formData.password ||
              !formData.confirmPassword ||
              !formData.isAccepted
            }
          >
            Register
          </IconButton>
        </Form.Group>
        <Divider>or register with</Divider>

        <SocialAuthButtons />

        <Row className="justify-content-between align-items-center">
          <Link className="fs--1 mb-0 mt-4" to={`/`}>
            Back to AuziPlan
          </Link>
        </Row>
      </Form>
    </Row>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
