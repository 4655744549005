import React from 'react';
import { Col, Form, FormCheck } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const PricingAltHeader = () => {
  return (
    <Col xs={12} className="text-center mb-4">
      <div className="fs-4">AuziPlan Pricing</div>
      <h5 className="mt-1">
        Looking for an automated business planning soloution?
      </h5>
      <h5 className="text-primary">Free plan for evaluation only. </h5>
      <h5 className="text-danger">Pro plan with all features.</h5>

      {/*
      <Flex justifyContent="center">
        <FormCheck.Label htmlFor="custom-switch" className="me-2">
          Monthly
        </FormCheck.Label>
        <Form.Check type="switch">
          <Form.Check.Input
            type="checkbox"
            className="falcon-form-check-input"
            defaultChecked
          />
        </Form.Check>
        <Form.Check.Label className="ms-2 align-top">Yearly</Form.Check.Label>
      </Flex>

 */}
    </Col>
  );
};

export default PricingAltHeader;
