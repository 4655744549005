import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const ExecutiveSummary = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="executiveSummary" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Executive Summary</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div style={{ maxHeight: '280px', overflowY: 'auto' }}>
                      <h6 className="fw-bold mt-1">Executive Summary</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        An executive summary is a concise overview of a longer
                        document or report. It provides a summary of the key
                        points, findings, recommendations, and conclusions
                        contained within the document, allowing busy executives
                        or stakeholders to quickly grasp the main ideas without
                        having to read the entire document.
                      </p>

                      <p>
                        The purpose of an executive summary is to provide
                        decision-makers with the essential information they need
                        to understand the contents of the full report and make
                        informed decisions based on its findings.
                      </p>
                      <p>
                        It should be well-written, clear, and focused,
                        highlighting the most important aspects of the document
                        while omitting unnecessary details.
                      </p>

                      <div className="border-dashed border-bottom my-3" />

                      <h6>Typically, an executive summary includes:</h6>

                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        A brief overview of the purpose and scope of the
                        document.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>Summary of Findings:</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        A concise summary of the main findings, results, or key
                        points discussed in the document.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>Recommendations:</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        Any actionable recommendations or suggestions derived
                        from the findings.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>Conclusions:</h6>

                      <p>
                        {' '}
                        <div className="border-dashed border-bottom my-3" />A
                        summary of the conclusions drawn from the analysis or
                        discussion presented in the document.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>Implications:</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        Any potential implications or impacts of the findings
                        and recommendations.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>Call to Action:</h6>

                      <p>
                        {' '}
                        <div className="border-dashed border-bottom my-3" />A
                        clear statement of what action, if any, is required from
                        the reader.
                      </p>

                      <p>
                        The length of an executive summary can vary depending on
                        the length and complexity of the full document, but it
                        is typically no more than one to two pages long.
                      </p>

                      <p>
                        The length of an executive summary can vary depending on
                        the length and complexity of the full document, but it
                        is typically no more than one to two pages long. Its
                        purpose is to provide a quick overview and entice the
                        reader to delve deeper into the details of the full
                        report if necessary.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          An executive summary is a concise overview of a longer document or
          report. It provides a summary of the key points, findings,
          recommendations, and conclusions contained within the document,
          allowing busy executives or stakeholders to quickly grasp the main
          ideas without having to read the entire document.
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default ExecutiveSummary;
