import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel,
  Table
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//import logo from 'assets/img/favicons/apple-icon-512x512.png';

import logo from 'assets/img/illustrations/logo.png';
import Modal from 'react-modal';

import avatarImg from 'assets/img/team/avatar.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { CompanyWizardContext } from 'context/Context';
import FalconDropzone from 'components/common/FalconDropzone';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';

const GarmentForm = () => {
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);
  const [retailPrice, setRetailPrice] = useState('');
  const [productCostPrice, setProductCostPrice] = useState('');
  const [totalRevenue, setTotalRevenue] = useState('');
  const [yearlyProjection, setYearlyProjection] = useState('');
  const [weeklyProjection, setWeeklyProjection] = useState('');
  const [totalCostOfGoods, setTotalCostOfGoods] = useState('');
  const [grossProfit, setGrossProfit] = useState('');
  const [startupBudget, setStartupBudget] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      productName: '',
      productType: 'Top',
      productCostPrice: '',
      retailPrice: '',
      quantity: '',
      numberOfMonths: ''
    }
  });

  useEffect(() => {
    if (edit === 'opratingExpense' && editDetails.id) {
      setIsEditing(true);
      setDocId(editDetails.id);
      setValue('productName', editDetails.productName);
      setValue('productType', editDetails.productType);
      setValue('productCostPrice', editDetails.productCostPrice);
      setValue('retailPrice', editDetails.retailPrice);
      setValue('quantity', editDetails.quantity);
      setValue('numberOfMonths', editDetails.numberOfMonths);

      calculateProjections(
        editDetails.productCostPrice,
        editDetails.retailPrice,
        editDetails.quantity,
        editDetails.numberOfMonths
      );
    }
  }, [edit]);

  useEffect(() => {
    calculateProjections(
      watch().productCostPrice,
      watch().retailPrice,
      watch().quantity,
      watch().numberOfMonths
    );
  }, [
    watch().productCostPrice,
    watch().retailPrice,
    watch().quantity,
    watch().numberOfMonths
  ]);

  const { company } = useContext(CompanyWizardContext);
  const [avatar, setAvatar] = useState([
    ...(company.avatar ? company.avatar : []),
    { src: avatarImg }
  ]);

  const calculateProjections = (
    productCostPrice,
    retailPrice,
    quantity,
    months
  ) => {
    const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol

    const parsedProductCostPrice = parseFloat(
      productCostPrice?.replace(/[^\d.-]/g, '') || '0'
    );
    const parsedRetailPrice = parseFloat(
      retailPrice?.replace(/[^\d.-]/g, '') || '0'
    );
    const parsedQuantity = parseInt(quantity) || 0;
    const parsedMonths = parseInt(months) || 1;

    const totalRevenueValue = parsedRetailPrice * parsedQuantity;
    setTotalRevenue(
      totalRevenueValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const yearly = totalRevenueValue * 12;
    setYearlyProjection(
      yearly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const weekly = yearly / 52;
    setWeeklyProjection(
      weekly.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const costOfGoods = parsedProductCostPrice * parsedQuantity;
    setTotalCostOfGoods(
      costOfGoods.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const grossProfitValue = totalRevenueValue - costOfGoods;
    setGrossProfit(
      grossProfitValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );

    const startupBudgetValue = (yearly / 12) * parsedMonths;
    setStartupBudget(
      startupBudgetValue.toLocaleString('en-US', {
        style: 'currency',
        currency: companyDetails.currency
      })
    );
  };

  const onSubmitData = data => {
    const payload = isEditing && docId ? { id: docId, data } : { ...data };

    api
      .post(
        isEditing ? '/operating-expense/update' : '/operating-expense/create',
        payload
      )
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message='Do you want to add more "Operating Expenses"?'
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="productName"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const currencySymbol = currencies[companyDetails.currency]; // Retrieve currency symbol
  const generatePDF = () => {
    const pageTitle = 'Revenue Projection'; // Adjusted page title
    const currentDate = new Date().toLocaleDateString(); // Get current date

    const input = document.getElementById('pdf-table');
    html2canvas(input, {
      backgroundColor: 'rgba(205, 209, 228, 1)' // Light blue color (RGB: 205, 209, 228)
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');

      // Set PDF to landscape
      const pdf = new jsPDF('l', 'px', 'a4'); // 'l' for landscape orientation

      // Add light blue background to the entire page
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.setFillColor(205, 209, 228); // Light blue color (RGB: 205, 209, 228)
      pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

      // Add header
      const headerImgWidth = 20;
      const headerImgHeight = 20;
      pdf.addImage(logo, 'PNG', 10, 10, headerImgWidth, headerImgHeight);

      // Calculate title position to center it in the header
      const titleWidth =
        pdf.getStringUnitWidth(pageTitle) * pdf.internal.getFontSize();
      const titleX = (pdfWidth - titleWidth) / 2;
      const titleY = 40; // Adjust this value to position the title as desired

      // Add centered page title in the header with blue color
      pdf.setFontSize(10); // Set font size to 10
      pdf.setTextColor(0, 0, 0); // Blue color
      pdf.text(pageTitle, titleX, titleY);

      // Reset text color to black for other content
      pdf.setTextColor(0, 0, 0);

      // Add blue line under header
      const headerBottomY = titleY + 10;
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0); // Blue color
      pdf.line(10, headerBottomY, pdfWidth - 10, headerBottomY);

      // Calculate content position and size
      const contentStartY = headerBottomY + 10;
      const contentHeight = pdfHeight - contentStartY - 50; // Adjust as necessary

      // Add table content or overlay
      const imageAspectRatio = canvas.width / canvas.height;
      const imgWidth = pdfWidth - 20;
      const imgHeight = imgWidth / imageAspectRatio;

      pdf.addImage(imgData, 'PNG', 10, contentStartY, imgWidth, imgHeight);

      // Add blue line above footer
      const footerTopY = pdf.internal.pageSize.height - 30; // Adjust position based on footer height
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0); // Blue color
      pdf.line(10, footerTopY, pdfWidth - 10, footerTopY);

      // Add footer with page number and current date
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10); // Set font size to 10
        pdf.text(`Page ${i} of ${pageCount}`, pdfWidth - 50, pdfHeight - 10, {
          align: 'right'
        });
        pdf.text(currentDate, 10, pdfHeight - 10, {
          align: 'left'
        });
      }

      // Save PDF
      pdf.save('table.pdf');
    });
  };
  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0
          },
          visible: {
            scale: 1,
            opacity: 1
          }
        }}
      >
        <PageHeader
          title={isEditing ? 'Edit Garment' : 'Add New Garment'}
          subtitle={
            isEditing
              ? 'Edit details of an existing garment'
              : 'Fill in the details of the new garment'
          }
          className="mb-3"
        />

        <Form onSubmit={handleSubmit(onSubmitData, onError)}>
          <Row className="align-items-start justify-content-between">
            <Col md={4}>
              <FalconDropzone
                files={avatar}
                onChange={files => {
                  setAvatar(files);
                  setValue('avatar', files);
                }}
                multiple={false}
                accept="image/*"
                placeholder={
                  <Row>
                    <Flex justifyContent="center">
                      <img
                        src={cloudUpload}
                        alt="logo"
                        width={25}
                        className="me-2"
                      />
                    </Flex>
                    <h className=" mb-0 w-75 mx-auto text-400 text-muted pt-3">
                      Optional
                    </h>
                    <p className="mb-0 w-75 mx-auto text-400">
                      Upload a 300x300 jpg image with a maximum size of 400KB,
                      or use default no image
                    </p>
                  </Row>
                }
              />
            </Col>

            <Col lg={8}>
              <Row className="align-items-start justify-content-between">
                <Col lg={6}>
                  <Form.Group controlId="productType">
                    <FloatingLabel label="Product Type">
                      <Form.Select
                        aria-label="Select the product type"
                        name="productType"
                      >
                        <option value="">Select Product</option>
                        <option value="Top">Top</option>
                        <option value="Bottom">Bottom</option>
                        <option value="Footwear">Footwear</option>
                        <option value="Accessories">Accessories</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group controlId="quantity">
                    <FloatingLabel label="How many products will sell each month?">
                      <Form.Control
                        type="number"
                        placeholder="How many products will sell each period?"
                        name="quantity"
                        {...register('quantity', {
                          required: 'Number Of Products is required.'
                        })}
                        isInvalid={errors['quantity']}
                        isValid={
                          Object.keys(errors).length > 0 && !errors['quantity']
                        }
                      />
                      <Form.Control.Feedback type="quantity">
                        {errors['quantity']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}>
                  <Form.Group controlId="productName">
                    <FloatingLabel label="Describe your product?">
                      <Form.Control
                        type="text"
                        placeholder="Describe your product?"
                        name="productName"
                        {...register('productName')}
                      />
                      {errors.productName && (
                        <Form.Text className="text-danger">
                          Product name is required
                        </Form.Text>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="retailPrice"
                    label="What is the average retail price for each product?"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('retailPrice', {
                        required: 'This field is required'
                      })
                    }}
                    {...register('retailPrice', { required: true })}
                    onChange={e => {
                      const value = e.target.value.replace(currencySymbol, '');
                      setValue('retailPrice', currencySymbol + value);
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>

              <Row className="align-items-start justify-content-between pt-3">
                <Col lg={6}>
                  <Form.Group controlId="numberOfMonths">
                    <FloatingLabel label="How many months of inventory will you start with?">
                      <Form.Control
                        type="number"
                        placeholder="Number Of Months?"
                        name="numberOfMonths"
                        {...register('numberOfMonths', {
                          required: 'Number of months is required.'
                        })}
                        isInvalid={errors['numberOfMonths']}
                        isValid={
                          Object.keys(errors).length > 0 &&
                          !errors['numberOfMonths']
                        }
                      />
                      <Form.Control.Feedback type="numberOfMonths">
                        {errors['numberOfMonths']?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <CurrencyWizardInput
                    symbol={currencies[companyDetails.currency]}
                    name="productCostPrice"
                    label="What is the average cost price for each product?"
                    errors={errors}
                    formGroupProps={{}}
                    formControlProps={{
                      ...register('productCostPrice', {
                        required: 'This field is required'
                      })
                    }}
                    {...register('productCostPrice', { required: true })}
                    onChange={e => {
                      const value = e.target.value.replace(currencySymbol, '');
                      setValue('productCostPrice', currencySymbol + value);
                    }}
                    setValue={setValue}
                    watch={watch}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={4}></Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="text-start mb-3 p-3"></div>
            </Col>
            <Col lg={6}>
              <div className="text-end p-3">
                <IconButton
                  onClick={generatePDF}
                  className="float-end mb-1 mx-1 "
                  variant="outline-success"
                  size="sm"
                >
                  PDF
                </IconButton>

                <IconButton
                  className="float-end mb-1 mx-1"
                  variant="outline-success"
                  size="sm"
                  type="submit"
                >
                  {isEditing ? 'Update' : 'Submit'}
                </IconButton>
              </div>
            </Col>
          </Row>
        </Form>

        <h5 className="mb-4">Monthly Financial Projections</h5>
        <Table striped bordered hover responsive id="pdf-table">
          <thead>
            <tr>
              <th>Total Revenue</th>
              <th>Total Cost of Goods</th>
              <th>Gross Profit</th>
              <th>Startup Budget</th>
              <th>Yearly Projection</th>
              <th>Weekly Projection</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{totalRevenue}</td>
              <td>{totalCostOfGoods}</td>
              <td>{grossProfit}</td>
              <td>{startupBudget}</td>
              <td>{yearlyProjection}</td>
              <td>{weeklyProjection}</td>
            </tr>
          </tbody>
        </Table>
      </motion.div>
    </div>
  );
};

export default GarmentForm;
