import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthUserContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
// const PrivateRoute = ({ ...rest }) => {
//   const { isAuthenticated, user, isLoading } = useContext(AuthUserContext);
//   console.log("isAuthenticated: ", isAuthenticated);
//   const isPlanPage = window.location.pathname.startsWith('/plans');
//   const isDashboardPage =
//     window.location.pathname.startsWith('/dashboard') ||
//     window.location.pathname.startsWith('/documentation') ||
//     window.location.pathname.startsWith('/changelog');
//     const navigate=useNavigate();
//   return (
//     <>
//       {
//       (!isLoading && (isAuthenticated && user.id && !isPlanPage && user.isActive && !user.isAdmin))?
//       (navigate('/start')):
//       (!isLoading &&
//         (isAuthenticated && user.id ? (
//           !user.isActive && !isPlanPage ? (
//             <Navigate to="/plans" />
//           ) : isPlanPage && user.isActive && user.isAdmin ? (
//             <Navigate to="/dashboard" />
//           ) : isDashboardPage && user.isActive && !user.isAdmin ? (
//             <Navigate to="/errors/404" />
//           ) : isPlanPage && user.isActive ? (
//             <Navigate to="/errors/404" />
//           ) : (
//             <Outlet {...rest} />
//           )
//         ) : (
//           <Navigate to="/login" />
//         )))
//         }
//     </>
//   );
// };
const PrivateRoute = ({ ...rest }) => {
  const { isAuthenticated, user, isLoading } = useContext(AuthUserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check conditions and navigate only once when the component mounts
    if (!isLoading && isAuthenticated && user && user.id && !user.isAdmin) {
      if (!user.isActive) {
        navigate('/plans');
      } else if (window.location.pathname.startsWith('/start')) {
        // Navigate to /start if the user is active and on the /start page
        navigate('/start');
      } else if (user.isAdmin) {
        navigate('/dashboard');
      }
    }
  }, [isLoading, isAuthenticated, user, navigate]);

  return (
    <>
      {!isLoading && isAuthenticated && user && user.id ? (
        <Outlet {...rest} />
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default PrivateRoute;
