import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const ProductsandServices = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="productsandServices" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Products and Services</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div style={{ maxHeight: '280px', overflowY: 'auto' }}>
                      <h6 className="fw-bold mt-1">Products and Services</h6>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>
                        Products - Key points to highlight about our products
                        include:
                      </h6>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Innovation</h6>

                      <p>
                        We are committed to innovation and continuous
                        improvement, regularly introducing new features and
                        enhancements to our product lineup.
                      </p>
                      <h6>Quality Assurance </h6>

                      <p>
                        Our products undergo rigorous quality assurance
                        processes to ensure they meet the highest standards of
                        performance, reliability, and safety.
                      </p>
                      <h6>Customization</h6>

                      <p>
                        We offer customization options to accommodate unique
                        customer preferences and requirements, providing
                        tailored solutions that address specific needs.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>
                        Service - Key points to highlight about our services
                        include:
                      </h6>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Customer Support</h6>
                      <p>
                        We pride ourselves on providing exceptional customer
                        support, offering timely assistance and resolution to
                        customer inquiries, concerns, and issues.
                      </p>
                      <h6>Installation and Setup</h6>
                      <p>
                        Our team of experts is available to provide installation
                        and setup services, ensuring a seamless experience for
                        our customers from purchase to use.
                      </p>
                      <h6>Training and Education</h6>
                      <p>
                        We offer training programs and educational resources to
                        help customers maximize the value of our products,
                        empowering them to achieve their goals and objectives.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          Provide detailed descriptions of your products/services, their
          features, benefits, and pricing strategy. Explain how they fulfill
          customer needs and solve pain points.
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>

      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default ProductsandServices;
