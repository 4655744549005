import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, FloatingLabel, Row } from 'react-bootstrap';
import classNames from 'classnames';
import api from 'utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const navigate = useNavigate();
  const { token } = useParams();

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      return toast.error('Passwords do not match');
    }

    api
      .post(`/users/reset/${token}`, {
        password: formData.password
      })
      .then(response => {
        toast.success('Your password has been saved.', {
          theme: 'colored'
        });
        navigate('/login');
      })
      .catch(error => {
        console.error(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            return toast.error(`Server error`, {
              theme: 'colored'
            });
          }

          if (error.response.status === 401 || error.response.status === 400) {
            return toast.error(error.response.data.message, {
              theme: 'colored'
            });
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="border-dashed border-bottom my-3" />

      <Form
        className={classNames('mt-3', { 'text-left': hasLabel })}
        onSubmit={handleSubmit}
      >
        <Form.Group className="mb-3">
          <FloatingLabel label="New Password">
            <Form.Control
              placeholder={!hasLabel ? 'New Password' : ''}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-3">
          <FloatingLabel label="Confirm New Password">
            <Form.Control
              placeholder={!hasLabel ? 'Confirm Password' : ''}
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type="password"
            />
          </FloatingLabel>
        </Form.Group>

        <IconButton
          type="submit"
          className="w-100"
          disabled={!formData.password || !formData.confirmPassword}
        >
          Set password
        </IconButton>

        <Row className="justify-content-between align-items-center">
          <Link className="fs--1 mb-0 mt-4" to={`/`}>
            Back to AuziPlan
          </Link>
        </Row>
      </Form>
    </Row>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
