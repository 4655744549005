import React, { forwardRef } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
const OperatingCosts = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="operatingCosts" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Operating Costs</h5>
      </Card.Header>

      <Card.Body>
        <h5 className="text-primary mt-1 ps-3 mb-3">
          What are "Operating Costs of a Business"
        </h5>

        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Operating costs, also known as operating expenses or OPEX, are the
          ongoing expenses that a business incurs as part of its regular
          operations to generate revenue. These costs are necessary for
          maintaining day-to-day business activities and sustaining the business
          over time. Operating costs are distinct from one-time or infrequent
          expenses, such as capital expenditures for purchasing assets or
          investments.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Operating costs typically include various categories of expenses, such
          as:
        </p>

        <ol>
          <h5 className="text-primary">Employee Expenses</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Salaries, wages, benefits, bonuses, and payroll taxes for employees
            who are directly involved in the operation of the business.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Utilities</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Costs associated with essential services required to operate the
            business, including electricity, water, gas, heating, and air
            conditioning.
          </li>
          <div className="border-dashed border-bottom my-3" />

          <h5 className="text-primary">Rent and Lease Payments</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Payments for leasing or renting office space, retail locations,
            warehouses, or other facilities necessary for business operations.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Materials and Supplies</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Costs for purchasing raw materials, inventory, office supplies, and
            other consumable items required for production or service delivery.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Marketing and Advertising</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Expenses related to promoting the business, including advertising
            campaigns, marketing materials, website maintenance, and social
            media marketing.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Maintenance and Repairs</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Costs for maintaining and repairing equipment, machinery, vehicles,
            and facilities used in the business operations.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Insurance</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Premiums for various insurance policies to protect the business
            against risks such as property damage, liability claims, and
            business interruption.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary"> Administrative Expenses</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Costs associated with general administrative functions, including
            office rent, office supplies, communication expenses, postage, and
            professional services (e.g., legal, accounting).
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Depreciation</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Allocation of the cost of assets over their useful lives to reflect
            their gradual loss of value due to wear and tear or obsolescence.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Taxes</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            Business taxes, such as property taxes, sales taxes, and corporate
            income taxes, as well as license fees and other regulatory charges.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Interest Expenses</h5>
          <div className="border-dashed border-bottom my-3" />

          <li>
            <strong>Interest Expenses:</strong> Interest payments on business
            loans, lines of credit, or other forms of debt financing.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Operating costs are crucial for determining the financial health and
          profitability of a business. Managing operating costs efficiently is
          essential for maximizing profitability and maintaining competitiveness
          in the market. Businesses often analyze and monitor their operating
          expenses closely to identify opportunities for cost reduction or
          optimization while ensuring that essential operations continue to run
          smoothly.
        </p>

        <div className="border-dashed border-bottom my-3" />
        <h5 className="mt-1 ps-3 mb-3">How do I add operating Costs?</h5>

        <div className="border-dashed border-bottom my-3" />
        <ol className="pt-1">
          <li>
            Click the plus button
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              en="true"
            ></IconButton>
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            Click{' '}
            <IconButton className="ml-3" variant="outline-primary" size="sm">
              <i className="fa fa-save fa-1x" aria-hidden="true"></i>
              Next
            </IconButton>{' '}
            to go to then next stage.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
      </Card.Body>
    </Card>
  );
});

export default OperatingCosts;
