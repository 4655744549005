import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  Container,
  FloatingLabel
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import { useForm } from 'react-hook-form';
import {
  SelectedCompanyContext,
  RevenueFormModalContext,
  RevenueWizardContext
} from 'context/Context';
import { toast } from 'react-toastify';
import api from 'utils/api';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import { addMoreNotification, currencies } from 'utils';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

import RetailStoreForm from './RetailStoreForm';
import GarmentForm from './GarmentForm';
import ProductsForm from './ProductsForm';

const RevenueStreamsForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(RevenueWizardContext);
  const { setShowFormModal } = useContext(RevenueFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const [selectedDiv, setSelectedDiv] = useState(null);
  const handleSelectChange = event => {
    setSelectedDiv(event.target.value);
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Row className="align-items-center justify-content-between">
        <div className="mt-3">
          <IconButton
            variant="outline-primary"
            icon="fa-solid fa-info"
            onClick={handleClick}
            size="sm"
          ></IconButton>
        </div>

        <div ref={ref}>
          <Overlay
            show={show}
            target={target}
            placement="right"
            container={ref.current}
            containerPadding={20}
          >
            <Popover id="popover-contained" style={{ backgroundColor: '' }}>
              <Popover.Header as="h3">Help Center</Popover.Header>
              <Popover.Body as="div" style={{ backgroundColor: '' }}>
                {/* Your popover content */}
                <Row
                  as={motion.div}
                  className="g-3"
                  initial={{ x: '-20%' }}
                  animate={{ x: '0%' }}
                  exit={{
                    x: window.innerWidth,
                    transition: { duration: 0.5 }
                  }}
                >
                  <PageHeader
                    className="text-primary"
                    title="Revenue by Products"
                    description=""
                  />
                  <div
                    className="bor"
                    style={{ maxHeight: '280px', overflowY: 'auto' }}
                  >
                    <Row>
                      <h6 className="fw-bold pt-2">
                        What is Revenue by Products?
                      </h6>

                      <p>
                        Revenue by products refers to the breakdown of a
                        company's total revenue based on the individual products
                        or services it sells. It provides insights into which
                        products or services are driving the company's sales and
                        revenue growth.
                      </p>

                      <h6>1. Product Segmentation</h6>
                      <p>
                        Companies categorize their offerings into distinct
                        products or services. Each product or service may have
                        its own pricing, target market, and sales strategy.
                      </p>

                      <h6 className="fw-bold pt-2">2. Revenue Analysis</h6>
                      <p>
                        Revenue by products involves analyzing sales data to
                        determine how much revenue each product or service has
                        generated over a specific period, such as a month,
                        quarter, or year.
                      </p>

                      <h6 className="fw-bold pt-2">
                        3. Performance Evaluation
                      </h6>
                      <p>
                        By analyzing revenue by products, companies can evaluate
                        the performance of each product or service. They can
                        identify which products are the most profitable, which
                        ones are experiencing growth, and which ones may be
                        underperforming.
                      </p>

                      <h6 className="fw-bold pt-2">
                        4. Strategic Decision-Making
                      </h6>
                      <p>
                        Understanding revenue by products helps companies make
                        strategic decisions about resource allocation, product
                        development, marketing efforts, and pricing strategies.
                      </p>

                      <p>
                        For example, if a certain product is driving significant
                        revenue growth, the company may choose to invest more
                        resources in its marketing and development.
                      </p>

                      <h6 className="fw-bold pt-2">
                        5. Forecasting and Planning
                      </h6>
                      <p>
                        Revenue by products also aids in forecasting future
                        revenue and planning sales targets. By analyzing
                        historical sales data and trends, companies can make
                        informed predictions about future product performance
                        and adjust their strategies accordingly.
                      </p>

                      <p>
                        Overall, revenue by products provides valuable insights
                        into the financial health of a company and helps guide
                        decision-making to optimize sales and profitability.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </Row>
                  </div>
                </Row>
              </Popover.Body>
            </Popover>
          </Overlay>
        </div>

        <Container>
          <div className=" mt-3">
            <Form.Group controlId="revenueType">
              <FloatingLabel label="Revenue Type">
                <Form.Select
                  value={selectedDiv}
                  onChange={handleSelectChange}
                  aria-label="Select the role type"
                  name="revenueType"
                >
                  <option value="">Select Industry</option>
                  <option value="div1">Garment</option>
                  <option value="div2">Retail Store</option>
                  <option value="div3">Products</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </div>

          {/* Render the selected div based on selectedDiv state */}
          <div>
            {selectedDiv === 'div1' && (
              <div>
                <GarmentForm />
              </div>
            )}

            {selectedDiv === 'div2' && (
              <div>
                <RetailStoreForm />
              </div>
            )}

            {selectedDiv === 'div3' && (
              <div>
                <ProductsForm />
              </div>
            )}
          </div>
        </Container>
      </Row>
    </Row>
  );
};

export default RevenueStreamsForm;
