import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const FundingRequest = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="fundingRequest" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Funding Request</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Funding Request</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        If you're seeking financing, specify the amount of
                        funding required and how you intend to use it. Provide a
                        detailed breakdown of your startup costs, operating
                        expenses, and revenue projections.
                      </p>

                      <h6 className="fw-bold mt-1">For Example</h6>
                      <h6>
                        Proposal for Funding: Community Youth Center Expansion
                      </h6>

                      <p>Dear [Potential Funder's Name],</p>
                      <p>
                        I hope this message finds you well. I am writing to
                        present an exciting opportunity for partnership and
                        investment in our community's youth. Our organization,
                        Youth Empowerment Initiative (YEI), is dedicated to
                        providing essential resources and support to the young
                        people in our area, helping them reach their full
                        potential and become active, engaged members of society.
                      </p>
                      <p>
                        Over the past five years, YEI has successfully operated
                        a community youth center, offering a safe and nurturing
                        environment for adolescents aged 12-18. Our programs
                        focus on academic support, life skills development,
                        career exploration, and recreational activities.
                        However, with the increasing demand for our services and
                        the growing population of youth in our community, we are
                        facing capacity constraints.
                      </p>
                      <p>
                        To address this challenge and meet the needs of our
                        expanding youth population, we are seeking funding
                        support for the expansion of our youth center. The
                        proposed expansion project includes:
                      </p>
                      <ol>
                        <li>
                          Facility Expansion: We plan to renovate and expand our
                          current facility to accommodate more youth
                          participants. This will involve adding additional
                          classrooms, recreational areas, and meeting spaces.
                        </li>
                        <li>
                          Program Enhancement: With increased space, we aim to
                          introduce new programs and activities tailored to the
                          diverse interests and needs of our youth population.
                          This may include workshops on entrepreneurship, STEM
                          education, arts and culture, and mental health support
                          services.
                        </li>
                        <li>
                          Technology Upgrades: In today's digital age, access to
                          technology is crucial for youth development. We intend
                          to invest in updated computer labs, multimedia
                          resources, and internet connectivity to ensure our
                          participants have the tools they need to succeed
                          academically and professionally.
                        </li>
                        <li>
                          Staffing and Training: As we expand our operations, we
                          will need to hire additional qualified staff members
                          and provide ongoing training and professional
                          development opportunities to ensure the highest
                          quality of services for our youth.
                        </li>
                      </ol>

                      <p>
                        We believe that by investing in the expansion of our
                        youth center, we will not only meet the immediate needs
                        of our community but also create a lasting impact on the
                        lives of countless young people who will benefit from
                        our programs and services.
                      </p>

                      <p>
                        Attached to this email, you will find a detailed
                        proposal outlining our expansion plans, budget
                        breakdown, and anticipated outcomes. We are open to
                        discussing this proposal further at your convenience and
                        welcome the opportunity to answer any questions you may
                        have.
                      </p>

                      <p>
                        Thank you for considering our funding request. Together,
                        we can make a difference in the lives of our community's
                        youth.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          If you're seeking financing, specify the amount of funding required
          and how you intend to use it. Provide a detailed breakdown of your
          startup costs, operating expenses, and revenue projections.
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default FundingRequest;
