//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Ratio,
  Card,
  ProgressBar,
  Col
} from 'react-bootstrap';
import CompanyItem from '../CompanyItem';
import CompanyWizard from 'components/custom/wizards/companyWizard/CompanyWizard';
import editing from 'assets/img/icons/spot-illustrations/5.png';
import { Link, useNavigate } from 'react-router-dom';
//import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  AuthUserContext,
  CompaniesContext,
  CompanyFormModalContenxt,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import Section from 'components/common/Section';

import bg1 from 'assets/img/illustrations/corner-4.png';

//import gallery2 from 'assets/img/team/1-thumb.png';
//import Background from 'components/common/Background';
//import Section from 'components/common/Section';
//import Background from 'components/common/Background';
//import gallery2 from 'assets/img/avatars/no_image.png';

const CompanySettings = () => {
  const { companies } = useContext(CompaniesContext);
  const { setStep, setCompany } = useContext(CompanyWizardContext);
  const { user } = useContext(AuthUserContext);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );
  const { showFormModal, setShowFormModal } = useContext(
    CompanyFormModalContenxt
  );
  const handleFormModelClose = () => {
    setShowFormModal(false);
    setCompany({});
    setStep(1);
  };
  const [modalShow, setModalShow] = React.useState(false);
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleAddNewCompany = () => {
    if (user.status && user.status === 'trialing' && companies.length >= 1) {
      toast.error(
        'Your are only allowed to work on one company with this trial version, upgrade to AuziPro for multi-company support and more features.'
      );
      return;
    }
    setShowFormModal(true);
  };
  const [lgShow, setLgShow] = useState(false);
  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-top justify-content-center">
        <Col lg={6} className="ps-lg-4 my-5 text-center">
          <img
            src={editing}
            className="img-fluid"
            alt=""
            style={{ maxWidth: '70%', height: 'auto' }}
          />
          <h2 className="test-primary"> Company Setup</h2>
          <p>
            "Launching a new company is an exhilarating journey filled with
            boundless opportunities and exciting challenges. Before diving into
            the intricacies of crafting a business plan, it's crucial to
            establish a solid foundation of fundamental business assumptions.
            These assumptions serve as guiding principles that shape your
            direction
          </p>

          <Row className="align-items-start justify-content-between">
            <Col lg={6}>
              <div className="text-start pt-3">
                <Link to="/start" className="text-decoration-none">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-arrow-left"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
            <Col>
              <div className="text-end pt-3">
                <Link to="/help-center" className="text-decoration-none ">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-info"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
                <IconButton
                  onClick={handleAddNewCompany}
                  variant="outline-primary"
                  size="lg"
                  icon="fa-solid fa-plus"
                  className="mb-1 mx-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
                <IconButton
                  onClick={() => setModalShow(true)}
                  variant="outline-warning"
                  size="lg"
                  icon="fa-solid fa-search"
                  className="mb-1 mx-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
                {companies.length > 0 && companyDetails && (
                  <Link
                    to="/buzPitchSettings"
                    className="text-decoration-none mx-0"
                  >
                    <IconButton
                      variant="outline-success"
                      size="lg"
                      icon="fa-solid fa-arrow-right"
                      className="mb-1 mx-1"
                      iconAlign="right"
                      transform="shrink-3"
                    ></IconButton>
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Company list  modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        //fullscreen={fullscreen}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <PageHeader
              title="Company List"
              description="Which company are you preparing a business plan for ?"
              className="mb-0"
            />
            {companies.length === 0 && (
              <div className="text-center">
                <h5 className="text-muted text-primary">
                  No companies found. Please add a new company.
                </h5>
              </div>
            )}
            <div
              className="border-none rounded mt-4"
              // style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              <div className="p-x1">
                <Flex
                  alignItems="center"
                  gap={4}
                  justifyContent={'between'}
                  wrap={'wrap'}
                >
                  {companies.map((item, index) => (
                    <CompanyItem
                      key={item.id + Math.random()}
                      details={item}
                      isLast={index === companies.length - 1}
                      setShowFormModal={setShowFormModal}
                      isSelected={item.id === companyDetails.id}
                      setSelected={setCompanyDetails}
                    />
                  ))}
                </Flex>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            variant="outline-success"
            size="lg"
            onClick={() => setModalShow(false)}
          >
            Close
          </IconButton>
        </Modal.Footer>
      </Modal>
      {/* Add company wizard modal */}
      <Modal
        show={showFormModal}
        size="lg"
        fullscreen={fullscreen}
        aria-label="contained-modal-title-vcenter"
        centered
      >
        <Container>
          <CompanyWizard variant="pills" validation={false} />
        </Container>

        {/* 
            <Button onClick={() => setModalShow(false)}>Close</Button>
        <Modal.Footer>
          <Button
            variant="outline-success"
            onClick={() => handleFormModelClose(false)}
          >
            Close
          </Button>
        </Modal.Footer>    
        */}
      </Modal>
      {/*Video modal */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        //centered
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Company Setup
          </Modal.Title>
          <CloseButton
            variant="white"
            size="lg"
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setLgShow(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="16x9">
            <iframe
              src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
              allowFullScreen={true}
              title="AuziPlan"
            />
          </Ratio>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CompanySettings;

{
  /* 
  



 <h6 className=" h6 mt-3">You are preparing a plan for:</h6>
          {companies.length === 0 && (
            <div className="text-center my-4">
              <h6 className="text-muted">
                No companies found. Please add a new company by clicking on the
                plus button to add your company.
              </h6>
            </div>
          )}
          {Object.keys(companyDetails).length > 0 && (
            <div className="border-none rounded mt-4">
              <CompanyItem
                details={companyDetails}
                isLast={companies.length === 0}
                setShowFormModal={setShowFormModal}
                isSelected={true}
                setSelected={setCompanyDetails}
              />
            </div>
          )}



















<Link to="#!">{company}</Link>
  <div className="border-dashed border-bottom my-3" />        
  <p className="lead pt-1">
  "In Stage 2, you'll include the owner and investor roles, while also
  considering the option of securing a business loan if required."
  </p>

<div className="border-dashed border-bottom my-3" />
 <Link
    to="/help-center"
      className="border-2 rounded-pill mt-0 fs-0 py-2"
    >
      <h6 className="fw-bold mt-4">
        Help Center
        <TooltipBadge
          tooltip="Typically GST and Sales Tax in paid quaterly in line with BAS (Business Activivty Statement)."
          icon="question-circle"
        />
      </h6>
    </Link>
      <ReactVimeoPlayer
        url="https://vimeo.com/90509568"
        controls={true}
        className="react-player"
      />
  */

  {
    /* 
          <Link to="/help-center" className="text-decoration-none">
            <IconButton
              variant="outline-primary"
              icon="fa-solid fa-info"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
          <Link to="" className="text-decoration-none mx-2">
            <IconButton
              onClick={() => setLgShow(true)}
              variant="outline-danger"
              icon="fa-solid fa-video"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
          
          <h6 className=" h6 mt-3">You are preparing a plan for:</h6>
          {companies.length === 0 && (
            <div className="text-center my-4">
              <h6 className="text-muted">
                No companies found. Please add a new company by clicking on the
                plus button to add your company.
              </h6>
            </div>
          )}
          {Object.keys(companyDetails).length > 0 && (
            <div className="border-none rounded mt-4">
              <CompanyItem
                details={companyDetails}
                isLast={companies.length === 0}
                setShowFormModal={setShowFormModal}
                isSelected={true}
                setSelected={setCompanyDetails}
              />
            </div>
          )}
        
          */
    {
      /* Company list  modal 
          <Link to="/start" className="text-decoration-none">          
            <IconButton
              variant="outline-success"
              icon="fa-solid fa-arrow-left"
              className="mb-1"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
          <Link to="/help-center" className="text-decoration-none">
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-info"
              className="mb-1 mx-o"
              iconAlign="right"
              transform="shrink-3"
            ></IconButton>
          </Link>
               <h4 className=" h4 mt-6 text-success">Stage - 1</h4>
          */
    }
  }
}
