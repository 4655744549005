import React, { useState, forwardRef, useRef, useContext } from 'react';

import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Card, Col, Row } from 'react-bootstrap';

///import { useFormContext } from 'react-hook-form';
//import DatePicker from 'react-datepicker';
//import TinymceEditor from 'components/common/TinymceEditor';
//import CustomDateInput from 'components/common/CustomDateInput';

//import { motion } from 'framer-motion';
//import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';

const Introduction = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="introduction" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Introduction</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Introduction</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        A business plan is a comprehensive document that
                        outlines the strategic direction, objectives, and
                        operational details of a business venture.
                      </p>
                      <p>
                        It serves as a roadmap for entrepreneurs, investors, and
                        stakeholders, providing a clear understanding of the
                        business concept, market analysis, financial
                        projections, and implementation strategy.
                      </p>
                      <p>
                        A well-crafted business plan not only articulates the
                        business idea but also demonstrates it's feasibility,
                        potential for growth, and profitability.
                      </p>
                      <p>
                        It serves as a tool for decision-making, securing
                        funding, and guiding the day-to-day operations of the
                        business.
                      </p>

                      <p>
                        From startup seeking investment to established companies
                        planning expansion, a business plan is an essential tool
                        for navigating the complexities of the business
                        landscape and achieving long-term success.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                      <h5>Key Business Plan Details</h5>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>1. Executive Summary:</h6>
                      <p>
                        A brief overview of the business plan, highlighting the
                        key points and objectives.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>2. Mission Statement:</h6>
                      <p>
                        A mission statement is a few lines of text that
                        introduces your company to the world and briefly states
                        your intentions. Example:"SquarePlans" purpose is to
                        provide an easy to use online business planning
                        platform, that will lay the foundation to grow a
                        successful business.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>3. Vission Statement:</h6>
                      <p>
                        A vision statement serves as a guiding beacon for a
                        company, articulating its long-term aspirations, goals,
                        and desired future state. It provides a clear direction
                        for strategic decision-making, shaping the
                        organization's path to growth and success.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>4. Business Description:</h6>
                      <p>
                        A detailed description of the business concept,
                        including its products or services, target market,
                        unique selling proposition, and competitive advantage.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>5. Management and Organization:</h6>
                      <p>
                        Information about the management team and organizational
                        structure of the business, including key personnel,
                        roles and responsibilities, and any advisory board or
                        external partners.
                      </p>
                      <div className="border-dashed border-bottom my-3" />

                      <h6>3. Market Analysis:</h6>
                      <p>
                        An analysis of the industry and market in which the
                        business operates, including market size, growth trends,
                        customer needs and preferences, and competitor analysis.
                      </p>

                      <h6>4. Marketing and Sales Strategy:</h6>
                      <p>
                        A description of how the business plans to attract and
                        retain customers, including marketing strategies, sales
                        channels, pricing strategy, and promotional activities.
                      </p>

                      <h6>5. Product and Services Sales Strategy:</h6>
                      <p>
                        A description of how the business plans to attract and
                        retain customers, including marketing strategies, sales
                        channels, pricing strategy, and promotional activities.
                      </p>

                      <h6>6. Operational Plan:</h6>
                      <p>
                        Details about the day-to-day operations of the business,
                        including the location, facilities, equipment,
                        suppliers, and production process.
                      </p>

                      <h6>6. Management and Organization:</h6>
                      <p>
                        Information about the management team and organizational
                        structure of the business, including key personnel,
                        roles and responsibilities, and any advisory board or
                        external partners.
                      </p>

                      <h6>7. Financial Plan:</h6>
                      <p>
                        Financial projections for the business, including sales
                        forecasts, expense budgets, cash flow projections, and a
                        break-even analysis. This section may also include
                        information about funding requirements and sources of
                        funding.
                      </p>

                      <h6>8. Risk Management Plan:</h6>
                      <p>
                        Identification and assessment of potential risks and
                        challenges that could impact the success of the
                        business, along with strategies for mitigating those
                        risks.
                      </p>

                      <h6>9. Appendices:</h6>
                      <p>
                        Additional information that supports the main sections
                        of the business plan, such as resumes of key personnel,
                        market research data, legal documents, and other
                        relevant materials.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <PageHeader
          title=""
          description="

          A business pitch is like a power-packed elevator speech designed to hook investors or stakeholders in a short span. It's a concise yet comprehensive presentation that lays out the essence of a business idea, aiming to persuade the audience of its worthiness. This pitch typically covers crucial elements such as the problem at hand, the innovative solution offered, the identified target market, the revenue model envisaged, and the expertise of the team behind the venture. The ultimate objective? To ignite enthusiasm and secure backing for the endeavor."
          className="text-default mb-0"
        />
      </Card.Body>
    </Card>
  );
});

export default Introduction;
