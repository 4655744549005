import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const SwotAnalysis = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="swotAnalysis" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">S.W.0.T. Analysis</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6>SWOT Analysis</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        S.W.O.T. Analysis is a strategic planning tool used to
                        identify and analyze the Strengths, Weaknesses,
                        Opportunities, and Threats related to a business,
                        project, or situation.
                      </p>

                      <p>
                        It provides a structured framework for evaluating
                        internal and external factors that can impact the
                        success or failure of a particular endeavor. Here's what
                        each component of SWOT represents:
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                      <h6>Strengths:</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        These are internal factors that contribute positively to
                        the organization's objectives and give it a competitive
                        advantage. Strengths may include resources,
                        capabilities, expertise, market position, brand
                        reputation, and any other factors that differentiate the
                        organization from its competitors.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Weaknesses:</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        These are internal factors that hinder the
                        organization's ability to achieve its objectives or
                        compete effectively in the marketplace. Weaknesses may
                        include limitations in resources, outdated technology,
                        poor management practices, lack of skills or expertise,
                        and any other factors that put the organization at a
                        disadvantage.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Opportunities:</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        These are external factors or trends in the market or
                        industry that the organization could exploit to its
                        advantage. Opportunities may arise from changes in
                        technology, shifts in consumer preferences, emerging
                        markets, strategic partnerships, or any other favorable
                        conditions that the organization can capitalize on to
                        grow and succeed.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Threats:</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        These are external factors or challenges that could
                        potentially harm the organization's performance or
                        disrupt its operations. Threats may come from
                        competitors, changes in government regulations, economic
                        downturns, technological disruptions, shifting consumer
                        preferences, or any other factors that pose risks to the
                        organization's success.
                      </p>

                      <p>
                        SWOT analysis helps organizations gain a better
                        understanding of their current situation, identify areas
                        for improvement, and develop strategies to leverage
                        strengths, mitigate weaknesses, capitalize on
                        opportunities, and address threats. It is commonly used
                        in strategic planning, marketing, product development,
                        and project management to inform decision-making and
                        guide future actions.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          S.W.O.T. Analysis is a strategic planning tool used to identify and
          analyze the Strengths, Weaknesses, Opportunities, and Threats related
          to a business, project, or situation. It provides a structured
          framework for evaluating internal and external factors that can impact
          the success or failure of a particular endeavor. Here's what each
          component of SWOT represents:
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default SwotAnalysis;
