import Flex from 'components/common/Flex';
import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from 'components/common/PageHeader';
import { Card, Button, Col, Nav, Row } from 'react-bootstrap';

import Introduction from './Introduction';
import MissionStatement from './MissionStatement';
import VisionStatement from './VisionStatement';
import FundingRequest from './FundingRequest';
import ManagementandOrganization from './ManagementandOrganization';
import PotentialCustomers from './PotentialCustomers';
import ProductsandServices from './ProductsandServices';
import MarketTarget from './MarketTarget';
import BusinessDescription from './BusinessDescription';
import SwotAnalysis from './SwotAnalysis';
import GoalsAndObjectives from './GoalsAndObjectives';
import ExecutiveSummary from './ExecutiveSummary';
import useScrollSpy from 'react-use-scrollspy';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
//import FooterStandard from 'components/pages/landing/FooterStandard';

import { motion } from 'framer-motion';
import Section from 'components/common/Section';
import IconButton from 'components/common/IconButton';

import { imageOverlay } from 'leaflet';

//import { use } from 'video.js/dist/types/tech/middleware';

const ThePitch = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <NavbarStandard />

      <Section>
        <Row
          as={motion.div}
          className="g-3"
          initial={{ x: '-20%' }}
          animate={{ x: '0%' }}
          exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
          <Row className="g-0 container mx-auto mb-5">
            <Col lg={8} className="pe-lg-2 order-1 order-lg-0">
              <Introduction ref={sectionRefs[0]} />
              <MissionStatement ref={sectionRefs[1]} />
              <VisionStatement ref={sectionRefs[2]} />
              <BusinessDescription ref={sectionRefs[3]} />
              <ManagementandOrganization ref={sectionRefs[4]} />
              <ProductsandServices ref={sectionRefs[5]} />
              <GoalsAndObjectives ref={sectionRefs[6]} />
              <MarketTarget ref={sectionRefs[7]} />
              <PotentialCustomers ref={sectionRefs[8]} />
              <SwotAnalysis ref={sectionRefs[9]} />
              <FundingRequest ref={sectionRefs[10]} />
              <ExecutiveSummary ref={sectionRefs[11]} />
            </Col>
            <Col lg={4} className="ps-lg-2 mb-3">
              <div className="sticky-sidebar">
                <Card className="sticky-top">
                  <Card.Header className="border-bottom">
                    <h6 className="mb-0 fs-0">Topics</h6>
                  </Card.Header>
                  <Card.Body>
                    <div
                      id="terms-sidebar"
                      className="terms-sidebar nav flex-column fs--1"
                    >
                      <Nav.Item>
                        <Nav.Link
                          href="#introduction"
                          className="nav-link px-0 py-1"
                          active={activeSection === 0}
                        >
                          Introduction
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#missionStatement"
                          className="nav-link px-0 py-1"
                          active={activeSection === 1}
                        >
                          Mission Statement
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#visionStatement"
                          className="nav-link px-0 py-1"
                          active={activeSection === 2}
                        >
                          Vision Statement
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#businessDescription"
                          className="nav-link px-0 py-1"
                          active={activeSection === 3}
                        >
                          Business Description
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#managementandOrganization"
                          className="nav-link px-0 py-1"
                          active={activeSection === 4}
                        >
                          Management and Organization
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#productsandServices"
                          className="nav-link px-0 py-1"
                          active={activeSection === 5}
                        >
                          Products and Services
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#goalsAndObjectives"
                          className="nav-link px-0 py-1"
                          active={activeSection === 6}
                        >
                          Goals and Objectives
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#marketTarget"
                          className="nav-link px-0 py-1"
                          active={activeSection === 7}
                        >
                          Market Target
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#potentialCustomers"
                          className="nav-link px-0 py-1"
                          active={activeSection === 8}
                        >
                          Potential Customers
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#swotAnalysis"
                          className="nav-link px-0 py-1"
                          active={activeSection === 9}
                        >
                          S.W.O.T. Analysis
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#fundingRequest"
                          className="nav-link px-0 py-1"
                          active={activeSection === 10}
                        >
                          Funding Request
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#executiveSummary"
                          className="nav-link px-0 py-1"
                          active={activeSection === 11}
                        >
                          Executive Summary
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                    <Row className="g-0 container mx-auto p-3 mb-0 float-end">
                      <div>
                        <div className="border-dashed border-bottom my-3 mb-3" />
                        <Link to="/process" className="text-decoration-none">
                          <div className="flex-1 pb-3">
                            <h5 className="fs-0">Stage 1</h5>
                            <p className="fs--1 mb-0">Company Setup</p>
                          </div>
                          <IconButton
                            variant="outline-success"
                            size="sm"
                            icon="fa-solid fa-arrow-right"
                            className="mb-1"
                            iconAlign="right"
                            transform="shrink-3"
                          ></IconButton>
                        </Link>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Row>
      </Section>
    </>
  );
};

export default ThePitch;

{
  /*
      <PageHeader
          title="The Pitch"
          description="So you stuck on your business plan and need help, select from the topics on the right sidebar to learn more."
          className="mb-3 container mt-5"
        />
      <FooterStandard />
      <Link
          to="/help-center"
          className="text-decoration-none"
        >
        <IconButton
          variant="outline-info"
          icon="fa-solid fa-info"
          className="mb-1"
          iconAlign="right"
          transform="shrink-3"
          ></IconButton>
      </Link>{' '}
    */
}
