import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './components/WizardLayout';

const OperatingExpenseWizard = ({ variant, validation, progressBar }) => {
  return (
    <WizardLayout
      variant={variant}
      validation={validation}
      progressBar={progressBar}
    />
  );
};

OperatingExpenseWizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default OperatingExpenseWizard;
