import React, { useState, forwardRef, useRef, useContext } from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Row } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';

const VisionStatement = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="visionStatement" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Vision Statement</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Vision Statement</h6>

                      <div className="border-dashed border-bottom my-3" />

                      <p>
                        A vision statement is a foundational element of a
                        company's identity, encapsulating its ultimate
                        aspirations, values, and overarching purpose. It serves
                        as a guiding light, illuminating the organization's
                        long-term goals and desired future state.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6 className="fw-bold mt-1">For Example</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Tesla's vision statement, "to create the most compelling
                        car company of the 21st century by driving the world’s
                        transition to electric vehicles," not only outlines its
                        ambition to revolutionize the automotive industry but
                        also underscores its commitment to sustainability and
                        innovation. Such vision statements inspire employees,
                        stakeholders, and customers alike, rallying them behind
                        a common vision and guiding strategic decisions towards
                        achieving that vision.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          A vision statement serves as a guiding beacon for a company,
          articulating its long-term aspirations, goals, and desired future
          state. It provides a clear direction for strategic decision-making,
          shaping the organization's path to growth and success.
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>

      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default VisionStatement;
