import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customersData';
import CardDropdown from 'components/common/CardDropdown';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import SubscriptionsTableHeader from './SubscriptionsTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AppContext from 'context/Context';
import api from 'utils/api';
import { toast } from 'react-toastify';

function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
}

const Subscriptions = () => {
  const [subScriptions, setSubscriptions] = useState([]);
  const columns = useMemo(() => {
    return [
      // {
      //   accessor: 'name',
      //   Header: 'Name',
      //   headerProps: { className: 'pe-1' },
      //   cellProps: {
      //     className: 'py-2'
      //   },
      //   Cell: rowData => {
      //     const { username, avatar } = rowData.row.original;
      //     return (
      //       <Link to="/e-commerce/customer-details">
      //         <Flex alignItems="center">
      //           {avatar ? (
      //             <Avatar src={avatar} size="xl" className="me-2" />
      //           ) : (
      //             <Avatar size="xl" name={username} className="me-2" />
      //           )}
      //           <div className="flex-1">
      //             <h5 className="mb-0 fs--1">{name}</h5>
      //           </div>
      //         </Flex>
      //       </Link>
      //     );
      //   }
      // },
      {
        accessor: 'metadata.email',
        Header: 'Email',
        Cell: rowData => {
          const {
            metadata: { email }
          } = rowData.row.original;
          return <a href={`mailto:${email}`}>{email}</a>;
        }
      },
      {
        accessor: 'customer',
        Header: 'Customer ID'
      },
      {
        accessor: 'status',
        Header: 'Status'
      },
      {
        accessor: 'isAdmin',
        Header: 'Administrator',
        Cell: rowData => {
          const {
            metadata: { isAdmin }
          } = rowData.row.original;
          return <p style={{ margin: 'auto 0' }}>{isAdmin ? 'yes' : 'no'}</p>;
        },
        sortType: 'basic'
      },
      {
        accessor: 'current_period_start',
        Header: 'Period Start',
        Cell: rowData => {
          const { current_period_start } = rowData.row.original;
          const formattedDate = formatDate(current_period_start);
          return <p style={{ margin: 'auto 0' }}>{formattedDate}</p>;
        }
      },
      {
        accessor: 'current_period_end',
        Header: 'Period End',
        Cell: rowData => {
          const { current_period_end } = rowData.row.original;
          const formattedDate = formatDate(current_period_end);
          return <p style={{ margin: 'auto 0' }}>{formattedDate}</p>;
        }
      }
      // {
      //   accessor: 'none',
      //   Header: '',
      //   disableSortBy: true,
      //   cellProps: {
      //     className: 'text-end'
      //   },
      //   Cell: rowData => {
      //     return (
      //       <CardDropdown>
      //         <div className="py-2">
      //           <Dropdown.Item href="#!" onClick={() => handleEdit(rowData)}>
      //             Edit
      //           </Dropdown.Item>
      //           <Dropdown.Item href="#!" onClick={() => handleDelete(rowData)}>
      //             Delete
      //           </Dropdown.Item>
      //         </div>
      //       </CardDropdown>
      //     );
      //   }
      // }
    ];
  }, []);

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const navigate = useNavigate();

  const handleEdit = rowData => {
    navigate('/dashboard/users/edit', { state: rowData.row.original });
  };

  const handleDelete = rowData => {
    api
      .delete(`/users/delete/${rowData.row.original.id}`)
      .then(res => {
        setSubscriptions(prev => {
          return prev.filter(
            customer => customer.id !== rowData.row.original.id
          );
        });
        toast.success('User deleted successfully');
      })
      .catch(err => {
        console.log(err);
        toast.error('Error deleting user');
      });
  };

  useEffect(() => {
    if (!isNavbarVerticalCollapsed) {
      setConfig('isNavbarVerticalCollapsed', true);
    }
  }, []);

  useEffect(() => {
    api.get('/pid/allSubscriptions').then(res => {
      console.log(res.data);
      setSubscriptions(res.data.data);
    });
  }, []);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={subScriptions}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <SubscriptionsTableHeader table setCustomers={setSubscriptions} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Subscriptions;
