import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FundingWizardContext } from 'context/Context';

const FundingWizardProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(false);
  const [editDetails, setEditDetails] = useState({});

  const value = { step, setStep, edit, setEdit, editDetails, setEditDetails };
  return (
    <FundingWizardContext.Provider value={value}>
      {children}
    </FundingWizardContext.Provider>
  );
};

FundingWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FundingWizardProvider;
