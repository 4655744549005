import React, { forwardRef } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
const BusinessAssumptions = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="businessAssumptions" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Business Assumptions</h5>
      </Card.Header>
      <Card.Body>
        {/*        

        <h6>Free service</h6>
        <p className="mb-0 ps-3">
          We may make certain Services available to you free of charge, up to
          certain limits as described on the Website (“Free Services”). Usage of
          the Services in excess of those limits requires a payment. We may
          terminate your access to the Free Services at any time without prior
          notice and will have no liability in respect of such termination.
          Without limiting the scope of clause 15, Free Services are provided
          without any warranty.
        </p>
        <hr className="my-4" />
        <h6>Paid service</h6>
        <ol type="1">
          <li>
            If you use our Services and cross the limits of the Free Services we
            provide, the ‘Paid service’ section applies for you
          </li>
          <li>
            The “Invoice Payment” are the fees payable for your invoice, as
            specified to you when you use our Service beyond the free usage
            limit. You shall pay for the Invoice Payment to us for the term of
            your paid usage.
          </li>
          <li>
            You won’t be charged for using our services until your usage crosses
            the free usage limit. At the end of each month (considering you have
            used beyond our free usage limit), we will generate an invoice
            containing your usage information, and the due amount for that
            month.
          </li>
          <li>
            Invoice Fees are payable in the currency specified when you sign up
            for the Service and are exclusive of value added tax (or other
            applicable sales tax), which shall be added at the appropriate rate.
          </li>
        </ol>
        <hr className="my-4" />
        <h6>Credit cards</h6>
        <p className="mb-0 ps-3">
          As long as you’re a paid user or have an outstanding balance with us,
          you will provide us with valid credit card information and authorize
          us to deduct the monthly invoice charges against that credit card
          (considering you have used beyond our free usage limit). We do not
          save card information that you provided, rather we use Stripe. You
          must replace the information for any credit card that expires with
          information for a different valid credit card. You can add multiple
          credit cards in our service, but only the card that you mark as
          Primary will be used to charge you for using paid services.
        </p>
        <hr className="my-4" />
        <h6>Refunds</h6>
        <p className="mb-0 ps-3">
          You won’t be entitled to a refund from us. As a postpaid service, it’s
          invalid. But if your invoice has inappropriate information due to any
          software bug or any other reason, we will refund the extra amount we
          charged within 14 working days after the discovery. You need to let us
          know about the extra amount charged from you.
        </p>








        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>

      


        <p className="mt-1 ps-3 mb-3">
          Launching a new company is an exhilarating journey filled with
          boundless opportunities and exciting challenges. Before diving into
          the intricacies of crafting a business plan, it's crucial to establish
          a solid foundation of fundamental business assumptions. These
          assumptions serve as guiding principles that shape the direction and
          strategy of your venture.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <p className="mt-1 ps-3 mb-3">
          One of the first steps in setting up your business is identifying your
          target market. Who are the potential customers for your products or
          services? What are their needs, preferences, and pain points?
          Conducting thorough market research will provide valuable insights
          into consumer behavior and market trends, helping you tailor your
          offerings to meet customer demands effectively.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <p className="mt-1 ps-3 mb-3">
          Next, consider your value proposition. What unique value do you offer
          to your target market? How does your product or service solve their
          problems or fulfill their desires better than existing alternatives?
          Clearly articulating your value proposition will differentiate your
          business from competitors and attract customers to your brand.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <p className="mt-1 ps-3 mb-3">
          Furthermore, it's essential to define your revenue model. How will you
          generate income? Will you sell products, offer services, or adopt a
          subscription-based model? Understanding your revenue streams and
          pricing strategy is vital for sustainable growth and profitability.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <p className="mt-1 ps-3 mb-3">
          Additionally, outline your distribution channels. How will you reach
          your target customers and deliver your offerings to them? Whether
          through online platforms, retail stores, or direct sales teams,
          choosing the right distribution channels will maximize your reach and
          accessibility to customers.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <p className="mt-1 ps-3 mb-3">
          Moreover, consider the resources and capabilities required to operate
          your business. What human, financial, and physical resources do you
          need? Do you have the necessary skills, expertise, and partnerships to
          execute your business plan effectively? Identifying and leveraging
          your strengths while addressing potential gaps will enhance your
          operational efficiency and competitiveness.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <p className="mt-1 ps-3 mb-3">
          Lastly, assess the competitive landscape. Who are your main
          competitors, and what are their strengths and weaknesses? Conducting a
          competitive analysis will help you identify opportunities for
          differentiation and areas where you can gain a competitive edge in the
          market.
        </p>
        <h5 className="mt-1 ps-3 mb-3">Your Tagline Name</h5>
*/}

        <h5 className="text-primary mt-1 ps-3 mb-3">
          What are "Business Assumption"
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Business assumptions are the foundational beliefs or premises upon
          which a business model, strategy, or decision-making process is built.
          These assumptions often involve predictions or estimations about
          various factors that can impact the success or failure of a business
          endeavor. They can include elements such as market demand, customer
          behavior, competition, technological trends, regulatory environment,
          and economic conditions.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Business assumptions are essential because they guide planning and
          resource allocation within organizations. However, they are inherently
          uncertain and carry risks. Therefore, it's crucial for businesses to
          continuously monitor, test, and adjust their assumptions based on
          real-world feedback and data to improve decision-making and increase
          the likelihood of achieving their goals.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">
          Detail on Business Assumptions
        </h5>

        <div className="border-dashed border-bottom my-3" />

        <ol className=" m-5 ps-3 mb-3">
          <h5 className="text-primary">Market Demand</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            One of the fundamental assumptions for any business is the existence
            of demand for its products or services...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Customer Behavior</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Businesses often make assumptions about how customers will interact
            with their products or services...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Competition</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Assumptions about the competitive landscape are critical for
            businesses to develop effective strategies...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Technology and Trends</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            In today's fast-paced world, technological advancements can
            significantly impact businesses...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Regulatory Environment</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Businesses operate within a framework of laws and regulations that
            can impact their operations and profitability...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Economic Conditions</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Assumptions about macroeconomic factors, such as GDP growth,
            inflation rates, interest rates, and unemployment levels...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">
            Cost Structure and Financial Projections
          </h5>{' '}
          <div className="border-dashed border-bottom my-3" />
          <li>
            Assumptions about costs, revenues, and financial metrics are crucial
            for developing business plans...
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Operational Factors</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Assumptions about internal operations, such as supply chain
            efficiency, workforce productivity, and scalability...
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />

        <p className="mt-1 ps-3 mb-3">
          Business assumptions serve as the foundation for strategic planning,
          resource allocation, and decision-making processes. However, they are
          inherently uncertain and subject to change. Therefore, businesses must
          regularly review and validate their assumptions based on real-world
          data and feedback to adapt to evolving market conditions and improve
          their chances of success.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          By laying out these fundamental business assumptions, you'll establish
          a strong groundwork for crafting a comprehensive business plan that
          outlines your goals, strategies, and tactics for success. With a clear
          understanding of your target market, value proposition, revenue model,
          distribution channels, resources, and competition, you'll be
          well-equipped to navigate the complexities of entrepreneurship and
          steer your company towards growth and prosperity.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="mt-1 ps-3 mb-3">How do I add a Business Assumptions?</h5>
        <div className="border-dashed border-bottom my-3" />
        <ol className="pt-1">
          <li>
            Click the plus button
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              en="true"
            ></IconButton>
          </li>

          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            Click{' '}
            <IconButton className="ml-3" variant="outline-primary" size="sm">
              <i className="fa fa-save fa-1x" aria-hidden="true"></i>
              Next
            </IconButton>{' '}
            to go to then next stage.
          </li>
        </ol>
      </Card.Body>
    </Card>
  );
});

export default BusinessAssumptions;
