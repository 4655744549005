import React, { useRef } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import CompanySetup from './CompanySetup';
import BusinessAssumptions from './BusinessAssumptions';
import OpeningCosts from './OpeningCosts';
import OperatingCosts from './OperatingCosts';
import PayrollCosts from './PayrollCosts';
import BusinessAnalysis from './BusinessAnalysis';
import ThePitch from './ThePitch';
import useScrollSpy from 'react-use-scrollspy';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import { motion } from 'framer-motion';
import Section from 'components/common/Section';
import IconButton from 'components/common/IconButton';
import { Link, useNavigate } from 'react-router-dom';

//import PageHeader from 'components/common/PageHeader';
//import FooterStandard from 'components/pages/landing/FooterStandard';
//import Flex from 'components/common/Flex';
//import IconButton from 'components/common/IconButton';

const HelpCenter = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <NavbarStandard />
      <Section>
        <Row
          as={motion.div}
          className="g-3"
          initial={{ x: '-20%' }}
          animate={{ x: '0%' }}
          exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
          <Row className="g-0 container mx-auto mb-5">
            <Col lg={8} className="pe-lg-2 order-1 order-lg-0">
              <ThePitch ref={sectionRefs[0]} />
              <CompanySetup ref={sectionRefs[1]} />
              <BusinessAssumptions ref={sectionRefs[2]} />
              <OpeningCosts ref={sectionRefs[3]} />
              <OperatingCosts ref={sectionRefs[4]} />
              <PayrollCosts ref={sectionRefs[5]} />
              <BusinessAnalysis ref={sectionRefs[6]} />
            </Col>
            <Col lg={4} className="ps-lg-2 mb-3">
              <div className="sticky-sidebar">
                <Card className="sticky-top">
                  <Card.Header className="border-bottom">
                    <h6 className="mb-0 fs-0">Help Topics</h6>
                  </Card.Header>
                  <Card.Body>
                    <div
                      id="terms-sidebar"
                      className="terms-sidebar nav flex-column fs--1"
                    >
                      <Nav.Item>
                        <Nav.Link
                          href="#thePitch"
                          className="nav-link px-0 py-1"
                          active={activeSection === 0}
                        >
                          The Pitch
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          href="#companySetup"
                          className="nav-link px-0 py-1"
                          active={activeSection === 1}
                        >
                          Company Setup
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          href="#businessAssumptions"
                          className="nav-link px-0 py-1"
                          active={activeSection === 2}
                        >
                          Business Assumptions
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          href="#openingCosts"
                          className="nav-link px-0 py-1"
                          active={activeSection === 3}
                        >
                          Opening Costs
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          href="#operatingCosts"
                          className="nav-link px-0 py-1"
                          active={activeSection === 4}
                        >
                          Operating Costs
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#payrollCosts"
                          className="nav-link px-0 py-1"
                          active={activeSection === 5}
                        >
                          Payroll Costs
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="#businessAnalysis"
                          className="nav-link px-0 py-1"
                          active={activeSection === 6}
                        >
                          Business Analysis
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Card.Body>

                  <div className="border-dashed border-bottom my-3" />
                  <Row className="g-0 container mx-auto p-3 mb-0">
                    <div className="float-end">
                      <div className="text-end">
                        <Link to="/start" className="text-decoration-none">
                          <IconButton
                            variant="outline-success"
                            size="sm"
                            icon="fa-solid fa-arrow-left"
                            className="mb-1 mx-1"
                          ></IconButton>
                        </Link>
                      </div>
                    </div>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>
        </Row>
      </Section>
    </>
  );
};

export default HelpCenter;

{
  /* 

      <PageHeader
      title="Help Center"
      description="So you stuck on your business plan and need help, select from the topics on the right sidebar to learn more."
      className="mb-3 container mt-0"

      <p> Did you find this helpful?</p>
      <IconButton
        variant="outline-success"
        size="sm"
        icon="fa-solid fa-arrow-left"
        className="mb-1 mx-1"
      >
        Yes
      </IconButton>
      <IconButton
        variant="outline-primary"
        size="sm"
        icon="fa-solid fa-arrow-left"
        className="mb-1 mx-1"
      >
        Need more help?
      </IconButton>

    <FooterStandard />


*/
}
