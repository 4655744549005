import ActionButton from 'components/common/ActionButton';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/custom/common/ConfirmationModel';
import api from 'utils/api';
import { toast } from 'react-toastify';
import {
  SelectedCompanyContext,
  StartupFormModalContext,
  StartupWizardContext
} from 'context/Context';
import { currencies } from 'utils';

function Item({ details, plantAndEquipments, setPlantAndEquipments, close }) {
  const [showDelConfirmModal, setShowDelConfirmModal] = useState(false);
  const { setEdit, setEditDetails, setStep } = useContext(StartupWizardContext);
  const { setShowFormModal } = useContext(StartupFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);

  const handleDelete = async () => {
    try {
      await api.delete(`/plant-and-equipments/delete/${details.id}`);
      toast.success('Plant And Equipment deleted');
      const updatedMotorVehicles = plantAndEquipments.filter(
        motorVehicle => motorVehicle.id !== details.id
      );
      setPlantAndEquipments(updatedMotorVehicles);
      setShowDelConfirmModal(false);
    } catch (error) {
      console.log(error);
      toast.error('Error deleting Plant And Equipment');
    }
  };

  const handleEdit = () => {
    close();
    setEdit('plantAndEquipment');
    setEditDetails(details);
    setStep(3);
    setShowFormModal(true);
  };

  return (
    <>
      <tr key={details.id}>
        <td>{details.quantity}</td>
        <td>{details.purchaseDate}</td>
        <td>
          {currencies[companyDetails.currency]}
          {details.totalCost}
        </td>
        <td>
          {currencies[companyDetails.currency]}
          {details.salvageValue}
        </td>
        <td>{details.depreciationTerm}</td>
        <td className="text-end">
          <ActionButton
            icon="edit"
            title="Edit"
            variant="action"
            className="p-0 me-2"
            onClick={handleEdit}
          />
          <ActionButton
            icon="trash-alt"
            title="Delete"
            variant="action"
            className="p-0"
            onClick={() => setShowDelConfirmModal(true)}
          />
        </td>
      </tr>
      <ConfirmationModal
        title={`Delete ${details.description}`}
        show={showDelConfirmModal}
        message="Are you sure you want to delete this Motor Vehicle?"
        handleClose={() => setShowDelConfirmModal(false)}
        handleConfirm={handleDelete}
      />
    </>
  );
}

Item.propTypes = {
  details: PropTypes.object,
  plantAndEquipments: PropTypes.array,
  setPlantAndEquipments: PropTypes.func,
  close: PropTypes.func
};

export default Item;
