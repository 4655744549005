import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OperatingExpenseWizardContext } from 'context/Context';

const OperatingExpenseWizardProvider = ({ children }) => {
  const [operatingExpense, setOperatingExpense] = useState({});
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(false);

  const value = {
    operatingExpense,
    setOperatingExpense,
    step,
    setStep,
    edit,
    setEdit
  };
  return (
    <OperatingExpenseWizardContext.Provider value={value}>
      {children}
    </OperatingExpenseWizardContext.Provider>
  );
};

OperatingExpenseWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default OperatingExpenseWizardProvider;
