import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, FloatingLabel, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import api from 'utils/api';
import { AuthUserContext } from 'context/Context';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { faEye } from '@fortawesome/free-solid-svg-icons';
const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    remember: true
  });

  const [visible, setVisible] = useState(false);
  const [isToggled, setToggled] = useState(false);
  const handleToggle = () => {
    setToggled(!isToggled);
  };

  const navigate = useNavigate();
  const { login } = useContext(AuthUserContext);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    api
      .post('/users/login', {
        username: formData.username,
        password: formData.password,
        rememberMe: formData.remember
      })
      .then(response => {
        toast.success(`Logged in as ${formData.username}`, {
          theme: 'colored'
        });
        login(response.data);
        if (response.data.isAdmin) {
          navigate('/dashboard');
        } else {
          navigate('/start');
        }
      })
      .catch(error => {
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            return toast.error(`Server error`, {
              theme: 'colored'
            });
          }

          if (error.response.status === 401 || error.response.status === 400) {
            return toast.error(error.response.data.message, {
              theme: 'colored'
            });
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <div className="border-dashed border-bottom my-3" />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <FloatingLabel label="User Name">
            <Form.Control
              placeholder={!hasLabel ? 'username' : ''}
              value={formData.username}
              name="username"
              onChange={handleFieldChange}
              type="text"
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3">
          <FloatingLabel label="Password">
            <Form.Control
              placeholder={!hasLabel ? 'Password' : ''}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type={visible ? 'text' : 'password'}
              className="pt-4"
              onClick={() => setVisible(!visible)}
            />
          </FloatingLabel>
          <div>{visible ? <faEye /> : <faEyeInvisible />}</div>
        </Form.Group>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Form.Check type="checkbox" id="hide" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="hide"
                checked={formData.show}
                onChange={() => setVisible(!visible)}
                onClick={handleToggle}
              />
              <Form.Check.Label className="mb-0 text-700">
                {isToggled ? 'Hide Password' : 'Show Password'}
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto"></Col>
        </Row>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Form.Check type="checkbox" id="rememberMe" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember"
                checked={formData.remember}
                onChange={e =>
                  setFormData({
                    ...formData,
                    remember: e.target.checked
                  })
                }
              />
              <Form.Check.Label className="mb-0 text-700">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <Link className="fs--1 mb-0" to={`/forgot-password`}>
              Forgot Password?
            </Link>
          </Col>
        </Row>
        <Form.Group>
          <IconButton
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.username || !formData.password}
          >
            Log in
          </IconButton>
        </Form.Group>
        <Divider className="mt-4">or log in with</Divider>
        <SocialAuthButtons />
        <Row className="justify-content-between align-items-center">
          <Link className="fs--1 mb-0 mt-4" to={`/`}>
            Back to AuziPlan
          </Link>
        </Row>
      </Form>
    </Row>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'split',
  hasLabel: false
};

export default LoginForm;
