import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customersData';
import CardDropdown from 'components/common/CardDropdown';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AppContext from 'context/Context';
import api from 'utils/api';
import { toast } from 'react-toastify';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const columns = useMemo(() => {
    return [
      // {
      //   accessor: 'name',
      //   Header: 'Name',
      //   headerProps: { className: 'pe-1' },
      //   cellProps: {
      //     className: 'py-2'
      //   },
      //   Cell: rowData => {
      //     const { username, avatar } = rowData.row.original;
      //     return (
      //       <Link to="/e-commerce/customer-details">
      //         <Flex alignItems="center">
      //           {avatar ? (
      //             <Avatar src={avatar} size="xl" className="me-2" />
      //           ) : (
      //             <Avatar size="xl" name={username} className="me-2" />
      //           )}
      //           <div className="flex-1">
      //             <h5 className="mb-0 fs--1">{name}</h5>
      //           </div>
      //         </Flex>
      //       </Link>
      //     );
      //   }
      // },
      {
        accessor: 'email',
        Header: 'Email',
        Cell: rowData => {
          const { email } = rowData.row.original;
          return <a href={`mailto:${email}`}>{email}</a>;
        }
      },
      {
        accessor: 'username',
        Header: 'Username'
      },
      {
        accessor: 'isVerified',
        Header: 'Verified',
        Cell: rowData => {
          const { isVerified } = rowData.row.original;
          return <p>{isVerified ? 'yes' : 'no'}</p>;
        },
        sortType: 'basic'
      },
      {
        accessor: 'isAdmin',
        Header: 'Administrator',
        Cell: rowData => {
          const { isAdmin } = rowData.row.original;
          return <p>{isAdmin ? 'yes' : 'no'}</p>;
        },
        sortType: 'basic'
      },
      {
        accessor: 'isActive',
        Header: 'Active',
        Cell: rowData => {
          const { isActive } = rowData.row.original;
          return <p>{isActive ? 'yes' : 'no'}</p>;
        },
        sortType: 'basic'
      },
      {
        accessor: 'isArchived',
        Header: 'Archived',
        Cell: rowData => {
          const { isArchived } = rowData.row.original;
          return <p>{isArchived ? 'yes' : 'no'}</p>;
        },
        sortType: 'basic'
      },
      // {
      //   accessor: 'address',
      //   Header: 'Billing Address',
      //   headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      //   cellProps: { className: 'ps-5' }
      // },
      {
        accessor: 'createdOn',
        Header: 'Joined',
        Cell: rowData => {
          const { createdOn } = rowData.row.original;
          const date = new Date(createdOn);
          const formattedDate = date.toLocaleDateString('en-GB');
          return <p>{formattedDate}</p>;
        }
      },
      {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        cellProps: {
          className: 'text-end'
        },
        Cell: rowData => {
          return (
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item href="#!" onClick={() => handleEdit(rowData)}>
                  Edit
                </Dropdown.Item>
                {/* (!rowData.row.original.isArchived && (
                <Dropdown.Item href="#!" onClick={() => handleDelete(rowData)}>
                  Archive
                </Dropdown.Item>
                )) */}
              </div>
            </CardDropdown>
          );
        }
      }
    ];
  }, []);

  const {
    config: { isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const navigate = useNavigate();

  const handleEdit = rowData => {
    navigate('/dashboard/users/edit', { state: rowData.row.original });
  };

  // const handleDelete = rowData => {
  //   api
  //     .delete(`/users/delete/${rowData.row.original.id}`)
  //     .then(res => {
  //       const customersCopy = [...customers];
  //       // setCustomers(prev => {
  //       //   return prev.filter(
  //       //     customer => customer.id !== rowData.row.original.id
  //       //   );
  //       // });
  //       const index = customersCopy.findIndex(
  //         customer => customer.id === rowData.row.original.id
  //       );
  //       customersCopy[index].isArchived = !rowData.row.original.isArchived;
  //       toast.success('User deleted successfully');
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       toast.error('Error deleting user');
  //     });
  // };

  useEffect(() => {
    if (!isNavbarVerticalCollapsed) {
      setConfig('isNavbarVerticalCollapsed', true);
    }
  }, []);

  useEffect(() => {
    api.get('/users/getAll').then(res => setCustomers(res.data));
  }, []);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={customers}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <CustomersTableHeader table setCustomers={setCustomers} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Customers;
