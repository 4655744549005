import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import Section from 'components/common/Section';
import RevenueSettings from 'components/pages/user/settings/RevenueSettings';
import RevenueFormModalProvider from 'components/custom/providers/RevenueFormModalProvider';
import RevenueWizardProvider from 'components/custom/wizards/revenueWizard/RevenueWizardProvider';

const RevenueSettingsPage = () => {
  return (
    <>
      <NavbarStandard />

      <Section>
        <Row
          as={motion.div}
          className="g-3"
          initial={{ x: '-20%' }}
          animate={{ x: '0%' }}
          exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
          <Col lg={12}>
            <RevenueWizardProvider>
              <RevenueFormModalProvider>
                <RevenueSettings />
              </RevenueFormModalProvider>
            </RevenueWizardProvider>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default RevenueSettingsPage;
