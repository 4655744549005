import React, { useState, forwardRef, useRef, useContext } from 'react';

import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Card, Col, Row } from 'react-bootstrap';

import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import TinymceEditor from 'components/common/TinymceEditor';
import CustomDateInput from 'components/common/CustomDateInput';

const MissionStatement = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="missionStatement" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Mission Statement</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Mission Statement</h6>

                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        A mission statement defines the fundamental purpose and
                        raison d'être of a company, articulating its core
                        values, objectives, and the primary means through which
                        it seeks to create value. It serves as a compass,
                        guiding the organization's daily operations and
                        strategic initiatives.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6 className="fw-bold mt-1">For Example</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Google's mission statement, "to organize the world's
                        information and make it universally accessible and
                        useful," succinctly encapsulates its commitment to
                        information accessibility and innovation. Through this
                        mission, Google not only outlines its strategic focus
                        but also communicates its broader societal impact and
                        commitment to empowering users worldwide. Mission
                        statements like these provide clarity of purpose, align
                        stakeholders, and inform decision-making, driving the
                        organization towards its overarching goals.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          A mission statement is a few lines of text that introduces your
          company to the world and briefly states your intentions.
          Example:"SquarePlans" purpose is to provide an easy to use online
          business planning platform, that will lay the foundation to grow a
          successful business.
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>

      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default MissionStatement;
