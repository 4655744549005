import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FloatingLabel, Form, FormControl, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Autocomplete } from '@react-google-maps/api';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const CustomDateInput = forwardRef(
  (
    { value, onClick, isInvalid, isValid, formControlProps, errorMessage },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          {...formControlProps}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

const WizardInput = ({
  label,
  name,
  errors,
  type = 'text',
  symbol = '$',
  options = [],
  symbols = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  getValues,
  datepickerProps
}) => {
  const [date, setDate] = useState(null);

  if (type === 'address') {
    const addressValue = (getValues && getValues('address')) || '';
    const [address, setAddress] = useState('');

    useEffect(() => {
      if (addressValue !== address) {
        setAddress(addressValue);
      }
    }, [addressValue]);
    const { ref: googleAutcompleteRef } = usePlacesWidget({
      apiKey: 'AIzaSyDMAA3a4GhTGeN37IXzwT7vzTOHEeB3swA',
      onPlaceSelected: (place, inputRef, autocomplete) => {
        setValue('address', place.formatted_address);
        setAddress(place.formatted_address);
      }
    });

    return (
      <>
        <Form.Group {...formGroupProps}>
          {/* <Form.Label>{label}</Form.Label> */}
          <FloatingLabel label={label}>
            <Form.Control
              type={'text'}
              placeholder={placeholder}
              isInvalid={errors[name]}
              onChange={e => {
                setValue('address', e.target.value);
                setAddress(e.target.value);
              }}
              value={address}
              ref={googleAutcompleteRef}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[name]?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </>
    );
  }

  if (type === 'formGroup') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}
        <InputGroup className="mb-3">
          <InputGroup.Text>{symbol}</InputGroup.Text>
          {/* <FloatingLabel label={label}> */}
          <Form.Control
            placeholder={placeholder}
            {...formControlProps}
            isInvalid={errors[name]}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
            aria-label={placeholder}
          />
          {/* </FloatingLabel> */}
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    );
  }

  if (type === 'date') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}

        <DatePicker
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
        <Form.Check.Input
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
  if (type === 'select') {
    return (
      <Form.Group {...formGroupProps}>
        <FloatingLabel label={label}>
          <Form.Select
            type={type}
            {...formControlProps}
            isInvalid={errors[name]}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
          >
            {/* <option value="">{placeholder}</option> */}
            {options.map((option, index) => (
              <option value={option} key={option}>
                {symbols.length > 0 ? `${symbols[index]} ${option}` : option}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    );
  }
  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  return (
    <Form.Group {...formGroupProps}>
      <FloatingLabel label={label} className="mb-3">
        <Form.Control
          type={type}
          placeholder={placeholder}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string
};

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  symbols: PropTypes.array,
  placeholder: PropTypes.string,
  symbol: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  datepickerProps: PropTypes.object
};

WizardInput.defaultProps = { required: false };

export default WizardInput;
