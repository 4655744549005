import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';

function PlantAndEquipmentList({ close }) {
  const [plantAndEquipments, setPlantAndEquipments] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/plant-and-equipments/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setPlantAndEquipments(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setTotalCost(
      plantAndEquipments.reduce(
        (acc, plantAndEquipments) =>
          acc +
          parseFloat(plantAndEquipments.totalCost.replace(/[^0-9.-]+/g, '')),
        0
      )
    );
  }, [plantAndEquipments]);

  return (
    <>
      <Table responsive className="">
        <thead>
          <tr>
            <th scope="col">Qty</th>
            <th scope="col">purchaseDate</th>
            <th scope="col">Cost</th>
            <th scope="col">Salvage Value</th>
            <th scope="col">Depreciation Term</th>
            <th className="text-end" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {plantAndEquipments.map(motorVehicle => (
            <Item
              key={motorVehicle.id}
              details={motorVehicle}
              plantAndEquipments={plantAndEquipments}
              setPlantAndEquipments={setPlantAndEquipments}
              close={close}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="text-center">
              <span className="fw-bold">
                Total Cost:{' '}
                {`${
                  currencies[companyDetails.currency]
                } ${totalCost.toLocaleString('en-US')}`}
              </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

PlantAndEquipmentList.propTypes = {
  close: PropTypes.func
};

export default PlantAndEquipmentList;
