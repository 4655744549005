import { AuthUserContext } from 'context/Context';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from 'utils/api';

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .get('/users/me')
      .then(res => {
        if (res.data) {
          login(res.data);
        }
        setIsLoading(false);
      })
      .catch(err => {
        if (err?.response?.status === 500) {
          console.log(err);
        }
        setIsLoading(false);
      });
  }, []);

  const login = user => {
    setUser(user);
    setIsAuthenticated(true);
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthUserContext.Provider
      value={{ isAuthenticated, login, logout, user, isLoading }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};
