import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FundingWizardContext, StartupWizardContext } from 'context/Context';

const StartupWizardProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [edit, setEdit] = useState(false);
  const [editDetails, setEditDetails] = useState({});

  const value = { step, setStep, edit, setEdit, editDetails, setEditDetails };
  return (
    <StartupWizardContext.Provider value={value}>
      {children}
    </StartupWizardContext.Provider>
  );
};

StartupWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default StartupWizardProvider;
