import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StartupFormModalContext } from 'context/Context';

const StartupFormModalProvider = ({ children }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const value = { showFormModal, setShowFormModal };

  return (
    <StartupFormModalContext.Provider value={value}>
      {children}
    </StartupFormModalContext.Provider>
  );
};

StartupFormModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default StartupFormModalProvider;
