import React, { forwardRef } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
const OpeningCosts = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="openingCosts" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Opening Costs</h5>
      </Card.Header>
      <Card.Body>
        <h5 className="text-primary mt-1 ps-3 mb-3">
          What are "Opening Costs of a Business"
        </h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Opening costs of a business refer to the expenses incurred when
          initially setting up or launching a business operation. These costs
          can vary significantly depending on the nature of the business, its
          industry, location, and scale. Here are some common types of opening
          costs a business might encounter:
        </p>
        <div className="border-dashed border-bottom my-3" />
        <ol>
          <h5 className="text-primary"> Legal and Regulatory Fees:</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            This includes expenses related to business registration, permits,
            licenses, and legal consultations to ensure compliance with local
            regulations.
          </li>
          <div className="border-dashed border-bottom my-3" />

          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Leases and Deposits</h5>
          <li>
            If the business requires physical space, such as an office, retail
            store, or warehouse, initial deposits or advance payments for leases
            or rentals are necessary.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Equipment and Fixtures</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Purchasing or leasing necessary equipment, machinery, furniture, and
            fixtures for the business operation, including computers, production
            machinery, shelving, and display cases.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Inventory</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Initial purchases of inventory or raw materials required to start
            operations, especially for retail or manufacturing businesses.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Marketing and Advertising</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Costs associated with promoting the business to attract customers,
            including website development, advertising campaigns, signage, and
            promotional materials.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary"> Staffing and Training</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Hiring and training employees, including recruitment costs, salaries
            or wages for the initial period, and expenses related to employee
            onboarding and training programs.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Utilities and Infrastructure</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Deposits or setup fees for essential services such as electricity,
            water, gas, internet, and telephone lines.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Insurance</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Payments for insurance policies to protect the business from
            potential risks, such as property insurance, liability insurance,
            and workers' compensation insurance.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Technology and Software</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Purchases of software licenses, hardware, and technology
            infrastructure required for business operations, including
            point-of-sale systems, accounting software, and customer
            relationship management (CRM) software.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Professional Services</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            <strong>Professional Services:</strong> Fees for professional
            services such as accounting, bookkeeping, legal advice, and
            consultancy services to ensure smooth business operations and
            compliance with regulations.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Renovations and Interior Design</h5>
          <div className="border-dashed border-bottom my-3" />
          <li>
            Costs associated with renovating or designing the business premises
            to create an attractive and functional environment for customers and
            employees.
          </li>
          <div className="border-dashed border-bottom my-3" />
          <h5 className="text-primary">Contingency Funds</h5>
          <li>
            <strong>Contingency Funds:</strong> Setting aside funds for
            unexpected expenses or emergencies that may arise during the initial
            stages of business operations.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Opening costs are essential considerations for entrepreneurs and
          business owners when planning their startup budget and securing
          financing. Proper estimation and management of these costs are crucial
          for ensuring a smooth and successful launch of the business.
        </p>

        <div className="border-dashed border-bottom my-3" />
        <h5 className="mt-1 ps-3 mb-3">How do I add opening Costs?</h5>

        <div className="border-dashed border-bottom my-3" />

        <ol className="pt-1">
          <li>
            Click the plus button
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              en="true"
            ></IconButton>
          </li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li>
            Click{' '}
            <IconButton className="ml-3" variant="outline-primary" size="sm">
              <i className="fa fa-save fa-1x" aria-hidden="true"></i>
              Next
            </IconButton>{' '}
            to go to then next stage.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
      </Card.Body>
    </Card>
  );
});

export default OpeningCosts;
