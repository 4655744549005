import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CompaniesContext,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { parseCompany } from 'utils';
import api from 'utils/api';
import { toast } from 'react-toastify';

const CompaniesProvider = ({ children }) => {
  const [companies, setCompanies] = useState([]);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await api.get('/companies/getAll');
        // const parsedCompanies = parseCompanies(response.data);
        setCompanies(response.data);
        if (response.data.length > 0 && !companyDetails.id) {
          setCompanyDetails(response.data[0]);
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 404) return;
        toast.error('Error fetching companies');
      }
    };

    getCompanies();
  }, []);

  const value = { companies, setCompanies };
  return (
    <CompaniesContext.Provider value={value}>
      {children}
    </CompaniesContext.Provider>
  );
};

CompaniesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CompaniesProvider;
