import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectedCompanyContext } from 'context/Context';

const SelectedCompanyProvider = ({ children }) => {
  const [companyDetails, setCompanyDetails] = useState({});
  const value = { companyDetails, setCompanyDetails };

  return (
    <SelectedCompanyContext.Provider value={value}>
      {children}
    </SelectedCompanyContext.Provider>
  );
};

SelectedCompanyProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SelectedCompanyProvider;
