import React, { useState, forwardRef, useRef, useContext } from 'react';

import IconButton from 'components/common/IconButton';
import { Overlay, Popover, Card, Col, Row } from 'react-bootstrap';

import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import TinymceEditor from 'components/common/TinymceEditor';
import CustomDateInput from 'components/common/CustomDateInput';

const MarketTarget = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Card className="mb-3" id="marketTarget" ref={ref}>
      <Card.Header className="bg-light">
        <Row>
          <Col lg={10}>
            <h5 className="mb-0 text-primary">Market Target</h5>
          </Col>
          <Col lg={2}>
            <div ref={ref}>
              <div className="text-end">
                <IconButton
                  variant="outline-primary"
                  icon="fa-solid fa-info"
                  onClick={handleClick}
                  size="sm"
                ></IconButton>
              </div>

              <Overlay
                show={show}
                target={target}
                placement="left"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <Popover.Header as="h3">Help Center</Popover.Header>
                  <Popover.Body as="h6">
                    <div
                      className="border-none rounded mt-1"
                      style={{ maxHeight: '280px', overflowY: 'auto' }}
                    >
                      <h6 className="fw-bold mt-1">Market Target</h6>

                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        As we refine our marketing strategies and plan for
                        future growth, it's crucial to have a clear
                        understanding of our market target—the specific audience
                        segments we aim to reach and engage with our
                        products/services. By defining our market target with
                        precision, we can tailor our marketing efforts
                        effectively and maximize their impact. Here's how we can
                        identify and define our market target:
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Demographics</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Start by analyzing demographic factors such as age,
                        gender, income level, education, occupation, and marital
                        status. Identify which demographic groups are most
                        likely to have a need for our products/services and
                        possess the purchasing power to afford them.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Psychographics</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Dive deeper into the psychographic profiles of our
                        target audience to understand their lifestyles, values,
                        attitudes, interests, and behaviors. Consider factors
                        such as personality traits, hobbies, beliefs,
                        aspirations, and purchasing motivations to create
                        marketing messages that resonate with their emotional
                        needs and desires.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Geographic Location</h6>

                      <p>
                        Determine the geographical areas where our target
                        audience is located. This may include specific regions,
                        cities, neighborhoods, or even global markets, depending
                        on the nature of our business and target market.
                        Consider factors such as population density, urban vs.
                        rural areas, and cultural differences that may influence
                        consumer behavior.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                      <h6>Behavioral Patterns</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Analyze the purchasing behavior and consumption patterns
                        of our target audience to identify key insights and
                        opportunities. Consider factors such as buying
                        frequency, brand loyalty, product usage, shopping
                        preferences, and online/offline shopping habits to
                        tailor our marketing strategies accordingly.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Needs and Pain Points</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Identify the needs, challenges, and pain points that our
                        target audience faces and how our products/services can
                        address them effectively. Conduct market research,
                        surveys, and customer feedback sessions to gain valuable
                        insights into the specific problems our target market
                        seeks solutions for.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Competitive Landscape</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Assess the competitive landscape to understand how our
                        competitors are targeting similar audience segments and
                        identify opportunities for differentiation. Analyze
                        their strengths and weaknesses to uncover unmet needs or
                        underserved market segments that we can capitalize on.
                      </p>
                      <div className="border-dashed border-bottom my-3" />
                      <h6>Value Proposition</h6>
                      <div className="border-dashed border-bottom my-3" />
                      <p>
                        Clearly communicate the unique value proposition of our
                        products/services and how they address the specific
                        needs and preferences of our target audience. Highlight
                        the key benefits, features, and advantages that set us
                        apart from competitors and resonate with our target
                        market.
                      </p>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <p className="mt-1 ps-3 mb-3">
          Conduct thorough research on your industry, target market, and
          competitors. Identify market trends, customer demographics, and market
          size to assess the potential demand for your products/services.
        </p>

        <div className="create-product-description-textarea">
          <TinymceEditor height="13.438rem" />
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <IconButton
            variant="outline-success"
            icon="fa-solid fa-plus"
            className="mb-0 mx-1"
            size="sm"
            type="submit"
          >
            Save
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
});

export default MarketTarget;
