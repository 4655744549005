import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';

function PrepaidCostsList({ close }) {
  const [prepaidCosts, setPrepaidCosts] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const { companyDetails } = useContext(SelectedCompanyContext);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/prepaid-costs/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setPrepaidCosts(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setuo a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setTotalCost(
      prepaidCosts.reduce(
        (acc, prepaidCosts) =>
          acc + parseFloat(prepaidCosts.costAmount.replace(/[^0-9.-]+/g, '')),
        0
      )
    );
  }, [prepaidCosts]);

  return (
    <>
      <Table responsive className="">
        <thead>
          <tr>
            <th scope="col">Cost Type</th>
            <th scope="col">Description</th>
            <th scope="col">Amount</th>
            <th className="text-end" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {prepaidCosts.map(owner => (
            <Item
              key={owner.id}
              details={owner}
              prepaidCosts={prepaidCosts}
              setPrepaidCosts={setPrepaidCosts}
              close={close}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="text-center">
              <span className="fw-bold">
                Total Cost:{' '}
                {`${
                  currencies[companyDetails.currency]
                } ${totalCost.toLocaleString('en-US')}`}
              </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

PrepaidCostsList.propTypes = {
  close: PropTypes.func
};

export default PrepaidCostsList;
