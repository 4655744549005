import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Autocomplete } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomDateInput = forwardRef(
  (
    {
      value,
      onClick,
      isInvalid,
      isValid,
      formControlProps,
      errorMessage,
      label
    },
    ref
  ) => {
    return (
      <>
        {/* <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          {...formControlProps}
        /> */}
        {/* // add calendar icon */}
        <Form.FloatingLabel controlId="floatingInput" label={label}>
          <Form.Control
            ref={ref}
            isInvalid={isInvalid}
            isValid={isValid}
            value={value}
            onClick={onClick}
            {...formControlProps}
          />
          <FontAwesomeIcon
            icon={['fas', 'calendar-alt']}
            className="position-absolute top-50 end-0 translate-middle-y mx-3"
          />
        </Form.FloatingLabel>
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);

const WizardInput = ({
  label,
  name,
  errors,
  type = 'text',
  symbol = '$',
  options = [],
  symbols = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  getValues,
  datepickerProps
}) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (type === 'date') {
      const dateValue = (getValues && getValues(name)) || null;
      if (dateValue && dateValue !== date) {
        const date = new Date(dateValue);
        setDate(date);
      }
    }
  }, []);

  if (type === 'address') {
    const addressValue = (getValues && getValues('address')) || '';
    const [address, setAddress] = useState('');

    useEffect(() => {
      if (addressValue !== address) {
        setAddress(addressValue);
      }
    }, [addressValue]);
    const { ref: googleAutcompleteRef } = usePlacesWidget({
      apiKey: 'AIzaSyDMAA3a4GhTGeN37IXzwT7vzTOHEeB3swA',
      onPlaceSelected: (place, inputRef, autocomplete) => {
        setValue('address', place.formatted_address);
        setAddress(place.formatted_address);
      }
    });

    return (
      <>
        <Form.Group {...formGroupProps}>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            type={'text'}
            placeholder={placeholder}
            isInvalid={errors[name]}
            onChange={e => {
              setValue('address', e.target.value);
              setAddress(e.target.value);
            }}
            value={address}
            ref={googleAutcompleteRef}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
          />
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  }

  if (type === 'formGroup') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}
        <InputGroup className="mb-3">
          <InputGroup.Text>{symbol}</InputGroup.Text>
          <Form.Control
            placeholder={placeholder}
            {...formControlProps}
            isInvalid={errors[name]}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
            aria-label={placeholder}
          />
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    );
  }

  if (type === 'date') {
    return (
      <Form.Group {...formGroupProps}>
        <DatePicker
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
              label={label}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
        <Form.Check.Input
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
  if (type === 'select') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.FloatingLabel controlId="floatingSelect" label={label}>
          <Form.Select
            type={type}
            {...formControlProps}
            isInvalid={errors[name]}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
          >
            <option value="">{placeholder}</option>
            {options.map((option, index) => (
              <option value={option} key={option}>
                {symbols.length > 0 ? `${symbols[index]} ${option}` : option}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors[name]?.message}
          </Form.Control.Feedback>
        </Form.FloatingLabel>
      </Form.Group>
    );
  }
  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  return (
    <Form.Group {...formGroupProps}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        {...formControlProps}
        isInvalid={errors[name]}
        isValid={Object.keys(errors).length > 0 && !errors[name]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string,
  label: PropTypes.string
};

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  type: PropTypes.string,
  options: PropTypes.array,
  symbols: PropTypes.array,
  placeholder: PropTypes.string,
  symbol: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  datepickerProps: PropTypes.object
};

WizardInput.defaultProps = { required: false };

export default WizardInput;
