import Flex from 'components/common/Flex';
import React, { useContext, useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Form,
  FloatingLabel,
  ProgressBar,
  FormSelect,
  FormGroup,
  Collapse
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/6.svg';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import { BuzPitchFormModalContext } from 'context/Context';
import BuzPitchWizard from 'components/custom/wizards/buzPitchWizard/BuzPitchWizard';
import PageHeader from 'components/common/PageHeader';

import { Typewriter } from 'react-simple-typewriter';
import CompanyItem from '../CompanyItem';
import CompanyWizard from 'components/custom/wizards/companyWizard/CompanyWizard';
import Section from 'components/common/Section';
import {
  AuthUserContext,
  CompaniesContext,
  CompanyFormModalContenxt,
  CompanyWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import { toast } from 'react-toastify';

const BuzPitchSettings = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const { showFormModal, setShowFormModal } = useContext(
    BuzPitchFormModalContext
  );

  const [modalShow, setModalShow] = React.useState(false);

  const handleFormModelClose = () => {
    setShowFormModal(false);
  };

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const { companies } = useContext(CompaniesContext);
  const { setStep, setCompany } = useContext(CompanyWizardContext);
  const { user } = useContext(AuthUserContext);
  const { companyDetails, setCompanyDetails } = useContext(
    SelectedCompanyContext
  );

  const handleAddNewCompany = () => {
    if (user.status && user.status === 'trialing' && companies.length >= 1) {
      toast.error(
        'Your are only allowed to work on one company with this trial version, upgrade to AuziPro for multi-company support and more features.'
      );
      return;
    }
    setShowFormModal(true);
  };
  const [lgShow, setLgShow] = useState(false);

  return (
    <Container
      as={motion.div}
      className="g-3"
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          scale: 0.8,
          opacity: 0
        },
        visible: {
          scale: 1,
          opacity: 1
        }
      }}
    >
      <Row className="align-items-top justify-content-center">
        <Col lg={6} className="ps-lg-4 my-5 text-center">
          <img
            src={editing}
            className="img-fluid"
            alt=""
            style={{ maxWidth: '70%', height: 'auto' }}
          />

          <h2 className="test-primary"> Business Pitch</h2>
          <p>
            A business pitch is a concise presentation designed to persuade
            potential investors, partners, or customers about the viability,
            benefits, and potential success of a business idea, product, or
            service. It should be engaging, clear, and compelling, conveying
            both the vision and the practicality of the business concept.
          </p>

          <Row className="align-items-start justify-content-between">
            <Col lg={6}>
              <div className="text-start pt-3">
                <Link to="/process" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-left"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-end pt-3">
                <Link to="/help-center" className="text-decoration-none ">
                  <IconButton
                    variant="outline-primary"
                    size="lg"
                    icon="fa-solid fa-info"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
                <IconButton
                  onClick={() => setShowFormModal(true)}
                  variant="outline-primary"
                  size="lg"
                  icon="fa-solid fa-plus"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
                <Link to="/OwnerSettings" className="text-decoration-none">
                  <IconButton
                    variant="outline-success"
                    size="lg"
                    icon="fa-solid fa-arrow-right"
                    className="mb-1 mx-1"
                    iconAlign="right"
                    transform="shrink-3"
                  ></IconButton>
                </Link>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Add company wizard modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        //fullscreen={fullscreen}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <PageHeader
            title="Operating Expense Budget"
            description="Create your operating expenses budget"
            className="mb-0"
          />
          <div className="border-none rounded mt-4">
            <div className="p-x1">
              <Form.Group as={Col} lg={12} controlId="startupCostType">
                <FloatingLabel label="Startup Cost Type">
                  <Form.Select aria-label="Default select a cost type">
                    <option></option>
                    <option value="1">Let's start</option>
                    <option value="2">Thr Pitch</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showFormModal}
        //size="xl"
        fullscreen={fullscreen}
        aria-label="contained-modal-title-vcenter"
        centered
      >
        <Container>
          <BuzPitchWizard variant="pills" validation={false} />
        </Container>
      </Modal>
    </Container>
  );
};

export default BuzPitchSettings;
