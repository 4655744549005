import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Overlay,
  Popover,
  FloatingLabel
} from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/5.png';
//import NavbarStandard from './landing/NavbarStandard';
//import FooterStandard from './landing/FooterStandard';
//import Section from 'components/common/Section';
//import TooltipBadge from 'components/common/TooltipBadge';

import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import {
  FundingFormModalContext,
  FundingWizardContext,
  SelectedCompanyContext
} from 'context/Context';
import PhoneWizardInput from 'components/custom/common/PhoneWizardInput';
import CurrencyWizardInput from 'components/custom/common/CurrencyWizardInput';
import PercentageWizardInput from 'components/custom/common/PercentageWizardInput';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { addMoreNotification, currencies } from 'utils';
import PropTypes from 'prop-types';
import PageHeader from 'components/common/PageHeader';
import AddMoreAlert from 'components/custom/common/AddMoreAlert';

import Background from 'components/common/Background';
import gallery2 from 'assets/img/illustrations/corner-4.png';

const OwnerForm = () => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { step, setStep, edit, editDetails, setEdit, setEditDetails } =
    useContext(FundingWizardContext);
  const { setShowFormModal } = useContext(FundingFormModalContext);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [docId, setDocId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    clearErrors
  } = useForm({
    defaultValues: {
      ownerFirstName: '',
      ownerLastName: '',
      companyRole: 'ceo',
      ownerContribution: '',
      ownerPercentageEquity: '',
      ownerEmail: '',
      ownerPhone: '',
      ownerIntro: ''
    }
  });

  useEffect(() => {
    if (edit === 'owner' && editDetails.id) {
      setValue('ownerFirstName', editDetails.ownerFirstName);
      setValue('ownerLastName', editDetails.ownerLastName);
      setValue('companyRole', editDetails.companyRole);
      setValue(
        'ownerContribution',
        `${
          currencies[companyDetails.currency]
        }${editDetails.ownerContribution.slice(1)}`
      );
      setValue('ownerPercentageEquity', editDetails.ownerPercentageEquity);
      setValue('ownerEmail', editDetails.ownerEmail);
      setValue('ownerPhone', editDetails.ownerPhone);
      setValue('ownerIntro', editDetails.ownerIntro);
      setIsEditing(true);
      setDocId(editDetails.id);
      setEdit('');
      setEditDetails({});
    }
  }, [edit]);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSubmitData = data => {
    const payload =
      isEditing && docId
        ? { id: docId, data }
        : {
            companyId: companyDetails.id,
            ...data
          };

    api
      .post(isEditing ? '/owners/update' : '/owners/create', payload)
      .then(res => {
        reset();
        setEdit('');
        setEditDetails({});
        setShowFormModal(false);
        addMoreNotification(
          <AddMoreAlert
            message="Do you want to add more owners?"
            onClick={() => {
              setShowFormModal(true);
              setTimeout(() => {
                document.querySelector('input[name="ownerFirstName"]')?.focus();
              }, 500);
            }}
          />
        );
      })
      .catch(error => {
        console.log(error);
        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            toast.error(`Server error`, {
              theme: 'colored'
            });
            return;
          }

          if (error.response.status === 401 || error.response.status === 400) {
            toast.error(error.response.data.message || error.response.data, {
              theme: 'colored'
            });
            return;
          }
        }
        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const onError = () => {
    const validation = true;

    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Who are the owners of the company ?"
              description="Company owners are stakeholders with a financial interest in
              the business. They may include founders, investors, or the
              public in publicly traded firms. Their alignment with the
              company's goals is vital for its success."
              className="text-primary"
            />
            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>
            <Row className="mb-0 mt-0">
              <Col md="auto">
                <div ref={ref}>
                  <Overlay
                    show={show}
                    target={target}
                    placement="right"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover
                      id="popover-contained"
                      style={{ backgroundColor: '' }}
                    >
                      <Popover.Header as="h3">Help Center</Popover.Header>
                      <Popover.Body
                        style={{
                          backgroundColor: '',
                          maxHeight: '400px',
                          overflowY: 'auto',
                          padding: '15px'
                        }}
                      >
                        <Row
                          as={motion.div}
                          className="g-3"
                          initial={{ x: '-20%' }}
                          animate={{ x: '0%' }}
                          exit={{
                            x: window.innerWidth,
                            transition: { duration: 0.5 }
                          }}
                        >
                          <PageHeader
                            className="text-primary"
                            title="Company Ownership"
                            description=""
                          />
                          <div
                            className="border-none rounded mt-2"
                            style={{ maxHeight: '280px', overflowY: 'auto' }}
                          >
                            <Row>
                              <h6 className="text-primary pt-3">
                                Who are they?
                              </h6>
                              <p>
                                Company owners are stakeholders who have a
                                financial interest in the business. They can be
                                founders, investors, or members of the public in
                                publicly traded firms. Their alignment with the
                                company's goals is crucial for its success.
                              </p>
                              <p>
                                Provide details about the company owner(s) and
                                their initial financial contributions to
                                establish the company.
                              </p>

                              <h6 className="text-primary">
                                Percentage Share Equity
                              </h6>
                              <p>
                                Specify the percentage of ownership held by the
                                owner(s).
                              </p>

                              <h6 className="text-primary">Example:</h6>
                              <p>
                                Shireen and her sister, Chanelle, co-own a
                                company named SC PTY. They each invested
                                $100,000 to start the company, giving them an
                                equal 50% ownership stake.
                              </p>

                              <div className="container pt-0">
                                <h6 className="text-primary pt-3">
                                  How to Fill Out the Owner Form?
                                </h6>

                                <p>
                                  Below are step-by-step instructions to help
                                  you fill out the form accurately:
                                </p>

                                <h6 className="text-primary pt-3">
                                  1. Owner Details
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Owner First Name:</strong> Enter the
                                    first name of the company owner.
                                  </li>
                                  <li>
                                    <strong>Owner Last Name:</strong> Provide
                                    the last name of the company owner.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  2. Company Role
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Company Role:</strong> Select the
                                    role of the owner from the dropdown menu.
                                    Options include CEO, Director, Managing
                                    Director, and Financial Planner.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  3. Financial Contribution
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Owner Contribution:</strong> Specify
                                    the financial contribution made by the
                                    owner. Use the currency symbol displayed
                                    based on your company's currency.
                                  </li>
                                  <li>
                                    <strong>Percentage Equity:</strong> Enter
                                    the percentage of ownership held by the
                                    owner(s).
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  4. Contact Information
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Owner Email:</strong> Input the
                                    email address of the owner. Ensure it is
                                    valid as per the specified format.
                                  </li>
                                  <li>
                                    <strong>Owner Phone:</strong> Enter the
                                    phone number of the owner.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  5. Introduction
                                </h6>
                                <ul>
                                  <li>
                                    <strong>Owner Intro:</strong> Provide a
                                    brief introduction of the owner.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  Additional Information
                                </h6>
                                <ul>
                                  <li>
                                    If you encounter any errors while
                                    submitting, ensure all required fields are
                                    correctly filled out. Error messages will
                                    guide you on any missing or incorrect
                                    information.
                                  </li>
                                </ul>
                                <h6 className="text-primary">
                                  Saving Your Data
                                </h6>
                                <ul>
                                  <li>
                                    Click the "Save" button at the bottom right
                                    after filling out all required fields to
                                    save your entries.
                                  </li>
                                  <li>
                                    You will receive notifications and prompts
                                    based on your actions during the form
                                    submission process.
                                  </li>
                                </ul>
                              </div>

                              <div className="border-dashed border-bottom my-3" />
                            </Row>
                          </div>
                        </Row>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </Col>
              <Col md></Col>
            </Row>
            <Form onSubmit={handleSubmit(onSubmitData, onError)}>
              <Row className="mt-0 mb-2 g-3">
                <Form.Group as={Col} lg={6} controlId="ownerFirstName">
                  <FloatingLabel label="Owner First Name">
                    <Form.Control
                      type="text"
                      placeholder="Owner First Name"
                      name="ownerFirstName"
                      {...register('ownerFirstName', {
                        required: 'Owner First Name is required.'
                      })}
                      isInvalid={errors['ownerFirstName']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['ownerFirstName']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['ownerFirstName']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} lg={6} controlId="OwnerLastName">
                  <FloatingLabel label="Owner Last Name">
                    <Form.Control
                      type="text"
                      placeholder="Owner Last Name"
                      name="ownerLastName"
                      {...register('ownerLastName', {
                        required: 'Owner Last Name is required.'
                      })}
                      isInvalid={errors['ownerLastName']}
                      isValid={
                        Object.keys(errors).length > 0 &&
                        !errors['ownerLastName']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['ownerLastName']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-1 g-3">
                <Form.Group as={Col} lg={4} controlId="companyRole">
                  <FloatingLabel label="Company Role">
                    <Form.Select
                      aria-label="Default select company role"
                      {...register('companyRole', {
                        required: 'Company Role is required.'
                      })}
                      isInvalid={errors['companyRole']}
                      isValid={
                        Object.keys(errors).length > 0 && !errors['companyRole']
                      }
                    >
                      <option value="ceo">CEO</option>
                      <option value="director">Director</option>
                      <option value="managing-director">
                        Managing Director
                      </option>
                      <option value="financial-planner">
                        Financial Planner
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors['companyRole']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <CurrencyWizardInput
                  symbol={currencies[companyDetails.currency]}
                  name="ownerContribution"
                  label="Owner Contribution"
                  errors={errors}
                  formGroupProps={{ className: 'mb-1', lg: 4, as: Col }}
                  formControlProps={{
                    ...register('ownerContribution', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
                <PercentageWizardInput
                  name="ownerPercentageEquity"
                  label="Percentage Equity"
                  errors={errors}
                  formGroupProps={{ className: 'mb-1', as: Col, lg: 4 }}
                  formControlProps={{
                    ...register('ownerPercentageEquity', {
                      required: 'This field is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
              </Row>
              <Row className="mb-2 g-3">
                <Form.Group as={Col} lg={6} controlId="OwnerEmail">
                  <FloatingLabel label="Owner Email">
                    <Form.Control
                      type="email"
                      placeholder="Owner Email"
                      name="ownerEmail"
                      {...register('ownerEmail', {
                        required: 'Owner Email is required.',
                        pattern: {
                          value:
                            /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
                          message: 'Email must be valid'
                        }
                      })}
                      isInvalid={errors['ownerEmail']}
                      isValid={
                        Object.keys(errors).length > 0 && !errors['ownerEmail']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['ownerEmail']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <PhoneWizardInput
                  name="ownerPhone"
                  label="Owner Phone*"
                  placeholder="Owner Phone*"
                  errors={errors}
                  formGroupProps={{ as: Col, sm: 6 }}
                  formControlProps={{
                    ...register('ownerPhone', {
                      required: 'Owner Phone is required'
                    })
                  }}
                  setValue={setValue}
                  watch={watch}
                />
              </Row>
              <Row className="mb-1 g-3">
                <Form.Group className="mb-1" controlId="ownerIntro">
                  <FloatingLabel label="Owner Intro">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Owner Intro"
                      name="ownerIntro"
                      {...register('ownerIntro', {
                        required: 'Owner Intro is required.'
                      })}
                      isInvalid={errors['ownerIntro']}
                      isValid={
                        Object.keys(errors).length > 0 && !errors['ownerIntro']
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['ownerIntro']?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <div className="border-dashed border-bottom my-3" />
              <Row>
                <Col lg={6}></Col>
                <Col lg={6}>
                  <div className="text-end">
                    <IconButton
                      variant="outline-success"
                      size="sm"
                      type="submit"
                    >
                      Save
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default OwnerForm;
