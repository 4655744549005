import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import Success from '../forms/Success';
import AppContext, {
  CompanyWizardContext,
  CompanyFormModalContenxt
} from 'context/Context';

import IconButton from 'components/common/IconButton';
import WizardModal from './WizardModal';
import Flex from 'components/common/Flex';
import CompanyTaxForm from '../forms/CompanyTaxForm';
import TradingTermsForm from '../forms/TradingTermsForm';
import OpeningBalancesForm from '../forms/OpeningBalancesForm';
import CompanyForm from '../forms/CompanyForm';
import { currencies } from 'utils';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const { company, setCompany, step, setStep, edit, setEdit } =
    useContext(CompanyWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    getValues,
    reset,
    clearErrors
  } = useForm();

  useEffect(() => {
    if (edit) {
      const keys = Object.keys(company);
      keys.forEach(key => {
        setValue(key, company[key]);
      });
      setEdit(false);
    }
  }, [edit]);

  useEffect(() => {
    if (company.currency) {
      if (company['debtorsBalance']) {
        setValue(
          'debtorsBalance',
          `${currencies[company.currency]}${company['debtorsBalance'].slice(1)}`
        );
      }

      if (company['creditorsBalance']) {
        setValue(
          'creditorsBalance',
          `${currencies[company.currency]}${company['creditorsBalance'].slice(
            1
          )}`
        );
      }

      if (company['bankBalance']) {
        setValue(
          'bankBalance',
          `${currencies[company.currency]}${company['bankBalance'].slice(1)}`
        );
      }
    }
  }, [company.currency]);

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'book',
      label: 'Company Profile'
    },

    {
      icon: 'dollar-sign',
      label: 'Opening Balances'
    },

    {
      icon: 'percentage',
      label: 'GST/Sales Tax'
    },

    {
      icon: 'book',
      label: 'Trading Terms'
    },

    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const onSubmitData = data => {
    setCompany({ ...company, ...data });
    setStep(step + 1);
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 5) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  const { showFormModal, setShowFormModal } = useContext(
    CompanyFormModalContenxt
  );

  const handleFormModelClose = () => {
    setShowFormModal(false);
    setCompany({});
    setStep(1);
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />

      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav
            className="justify-content-center gap-2 flex-wrap"
            variant={variant}
          >
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    variant="outline-primary"
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    variant="outline-primary"
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <CompanyForm
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
            />
          )}

          {step === 2 && (
            <OpeningBalancesForm
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
            />
          )}

          {step === 3 && (
            <CompanyTaxForm
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
            />
          )}

          {step === 4 && (
            <TradingTermsForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}

          {step === 5 && <Success reset={reset} />}
        </Card.Body>

        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 5,
            ' d-flex': step < 5
          })}
        >
          <IconButton
            color="success"
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-arrow-left"
            iconAlign="right"
            className={classNames('px-auto fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          ></IconButton>
          <IconButton
            color="warning"
            variant="outline-success"
            size="lg"
            icon="fa fa-times"
            iconAlign="right"
            onClick={() => handleFormModelClose(false)}
            className="mb-0 mx-1 px-auto"
          ></IconButton>
          <IconButton
            color="success"
            variant="outline-success"
            size="lg"
            icon="fa-solid fa-arrow-right"
            iconAlign="right"
            onClick={() => {
              setStep(step + 1);
            }}
          ></IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 5 ? step > index : step > 5,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
