import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

const AddMoreAlert = ({ closeToast, toastProps, onClick, message }) => (
  <div>
    <p>{message}</p>
    <Button
      variant="outline-light"
      size="sm"
      onClick={() => {
        toast.dismiss(toastProps.toastId);
        onClick();
      }}
    >
      Yes
    </Button>
    <Button
      variant="outline-light"
      size="sm"
      className="ms-2"
      onClick={() => {
        closeToast();
        toast.dismiss(toastProps.toastId);
      }}
    >
      No
    </Button>
  </div>
);

AddMoreAlert.propTypes = {
  message: PropTypes.string,
  closeToast: PropTypes.func,
  onClick: PropTypes.func,
  toastProps: PropTypes.object
};

export default AddMoreAlert;
