import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, FloatingLabel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import api from 'utils/api';
import { toast } from 'react-toastify';
import { AuthUserContext } from 'context/Context';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  const { logout, isAuthenticated } = useContext(AuthUserContext);

  useEffect(() => {
    api
      .get('/users/logout')
      .then(resp => {
        if (resp.status === 200) {
          if (isAuthenticated) {
            logout();
            toast.success('You have been logged out.');
          }
        }
      })
      .catch(err => {
        if (isAuthenticated) logout();
        toast.error('There was an error logging you out.');
      });
  }, []);

  return (
    <Container>
      <Row
        as={motion.div}
        className="g-3"
        initial={{ x: '-20%' }}
        animate={{ x: '0%' }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <img
          className="d-block mx-auto mb-4"
          src={logoutImg}
          alt="shield"
          width={100}
        />
        <TitleTag>See you again!</TitleTag>
        <p>
          Thanks for using AuziPlan. You are{' '}
          <br className="d-none d-sm-block" />
          now successfully signed out.
        </p>
        <IconButton
          as={Link}
          color="primary"
          size="sm"
          className="mt-3"
          to={`/login`}
        >
          <FontAwesomeIcon
            icon="chevron-left"
            transform="shrink-4 down-1"
            className="me-1"
          />
          Return to Login
        </IconButton>
      </Row>
    </Container>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'split',
  titleTag: 'h4'
};

export default LogoutContent;
