import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SelectedCompanyProvider from './SelectedCompanyProvider';
import CompaniesProvider from './CompaniesProvider';

const StageProvider = () => {
  return (
    <SelectedCompanyProvider>
      <CompaniesProvider>
        <Outlet />
      </CompaniesProvider>
    </SelectedCompanyProvider>
  );
};

export default StageProvider;
