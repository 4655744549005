import React from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'utils/api';
import PageHeader from 'components/common/PageHeader';

function AddUser() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [isSaved, setIsSaved] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    console.log(formData);

    if (formData.password !== formData.confirmPassword) {
      return toast.error(`Passwords don't match!`, {
        theme: 'colored'
      });
    }

    const data = { ...formData };
    delete data.confirmPassword;

    api
      .post('/users/register', data)
      .then(response => {
        console.log(response);
        setIsSaved(true);
        toast.success(`Successfully registered ${formData.username}`, {
          theme: 'colored'
        });
      })
      .catch(error => {
        console.log(error);
        setIsSaved(false);
        setFormData({
          username: '',
          email: '',
          password: '',
          confirmPassword: ''
        });

        if (error.code !== 'ERR_NETWORK') {
          if (error.response.status === 500) {
            return toast.error(`Server error`, {
              theme: 'colored'
            });
          }

          if (
            error.response.status === 401 ||
            error.response.status === 400 ||
            error.response.status === 409
          ) {
            return toast.error(error.response.data.message, {
              theme: 'colored'
            });
          }
        }

        toast.error(`Something went wrong!`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    const value = e.target.value;
    const valueToBeSet =
      value === 'true' ? true : value === 'false' ? false : value;
    setFormData({
      ...formData,
      [e.target.name]: valueToBeSet
    });
  };

  return (
    <>
      <PageHeader
        title="Add New User"
        description={`You can add new user here.`}
        className="mb-3"
      ></PageHeader>
      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="exampleFirstName">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleFieldChange}
                  value={formData.email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exampleUserName">
                <Form.Label>Username</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend"
                    name="username"
                    value={formData.username}
                    onChange={handleFieldChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please choose a username.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="exampleFirstName">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  value={formData.password}
                  onChange={handleFieldChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="exampleFirstName">
                <Form.Label>Confirm Password Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleFieldChange}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button
              disabled={
                isSaved ||
                !formData.password ||
                !formData.confirmPassword ||
                !formData.username ||
                !formData.email
              }
              className="mt-2"
              type="submit"
            >
              Save
            </Button>
            <Button className="ms-2 mt-2" variant="link">
              <Link to="/dashboard/users">Go Back</Link>
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default AddUser;
