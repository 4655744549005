import React, { Fragment, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../components/WizardInput';
import {
  Col,
  Button,
  Overlay,
  Popover,
  ListGroup,
  Form,
  Card,
  ListGroupItem,
  Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TooltipBadge from 'components/common/TooltipBadge';
import { CompanyWizardContext } from 'context/Context';
import { currencies } from 'utils';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';
import Background from 'components/common/Background';
import gallery2 from 'assets/img/illustrations/corner-4.png';

const TradingTermsForm = ({ register, errors, watch }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = event => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Row
      as={motion.div}
      className="g-3"
      initial={{ x: '-20%' }}
      animate={{ x: '0%' }}
      exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
    >
      <Card>
        <Card.Body className="overflow-hidden p-lg-2">
          <Row className="align-items-center justify-content-between">
            <PageHeader
              title="Company Tax Liabilities"
              description=""
              className="text-primary"
            />

            <div className="mt-3">
              <IconButton
                variant="outline-primary"
                icon="fa-solid fa-info"
                onClick={handleClick}
                size="sm"
              ></IconButton>
            </div>

            <div className="border-none rounded mt-0">
              <Row className="mb-3">
                <div ref={ref}>
                  <Overlay
                    show={show}
                    target={target}
                    placement="right"
                    container={ref.current}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained">
                      <Popover.Header as="h3">Help Center</Popover.Header>
                      <Popover.Body as="h6">
                        <PageHeader
                          title="Company Trading Terms"
                          description=""
                          className="text-primary"
                        />

                        <div className="border-dashed border-bottom my-3" />
                        <div
                          className="border-none rounded mt-1"
                          style={{ maxHeight: '280px', overflowY: 'auto' }}
                        >
                          <ul>
                            <h6 className="fw-bold mt-1">
                              Creditors Payment Term
                            </h6>
                            <div className="border-dashed border-bottom my-3" />
                            <li>
                              Specify the number of days within which you plan
                              to settle your creditors' balance. The standard
                              contract typically involves a 30-day period.
                            </li>
                            <div className="border-dashed border-bottom my-3" />
                            <h6 className="fw-bold mt-1">
                              Debtors Expected Payment Window
                            </h6>
                            <div className="border-dashed border-bottom my-3" />
                            <li>
                              Indicate the anticipated number of days within
                              which you expect your customers' balances to be
                              paid. The standard contract usually involves a
                              30-day period.
                            </li>
                            <div className="border-dashed border-bottom my-3" />
                            <h6 className="fw-bold mt-4">
                              C.O.D (Cash on Delivery)
                            </h6>
                          </ul>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>

                <h6 className="fw-bold  mt-4">Customers</h6>
                <div className="border-dashed border-bottom my-3" />
                <Form.Group className="mb-3 d-flex gap-3 flex-wrap">
                  <WizardInput
                    type="radio"
                    name="customerPayPeriod"
                    label="C.O.D"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('customerPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '0'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="customerPayPeriod"
                    label="30-days"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('customerPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '30'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="customerPayPeriod"
                    label="60-days"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('customerPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '60'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="customerPayPeriod"
                    label="90-days"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('customerPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '90'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="customerPayPeriod"
                    label="Year-End"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('customerPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: 'year-end'
                    }}
                  />
                </Form.Group>

                <h6 className="fw-bold  mt-4">Suppliers?</h6>
                <div className="border-dashed border-bottom my-3" />
                <Form.Group className="mb-3 d-flex gap-3 flex-wrap">
                  <WizardInput
                    type="radio"
                    name="supplierPayPeriod"
                    label="C.O.D"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('supplierPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '0'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="supplierPayPeriod"
                    label="30-days"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('supplierPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '30'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="supplierPayPeriod"
                    label="60-days"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('supplierPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '60'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="supplierPayPeriod"
                    label="90-days"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('supplierPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: '90'
                    }}
                  />
                  <WizardInput
                    type="radio"
                    name="supplierPayPeriod"
                    label="Year-End"
                    errors={errors}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                      ...register('supplierPayPeriod', {
                        required: 'This field is required'
                      }),
                      value: 'year-end'
                    }}
                  />
                </Form.Group>
                <div className="border-dashed border-bottom my-3" />
              </Row>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

TradingTermsForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default TradingTermsForm;
