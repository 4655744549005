import AddMoreAlert from 'components/custom/common/AddMoreAlert';
import { toast } from 'react-toastify';

export const currencies = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  JPY: '¥',
  INR: '₹'
};

export const formatAmount = (amount, currency) => {
  return `${currencies[currency]} ${amount.toLocaleString('en-US', {
    minimumFractionDigits: 2
  })}`;
};

export const parseCompany = company => {
  return {
    id: company.id,
    logo: company.avatar.base64 || company.avatar,
    degree: company.industry,
    duration: 'Plan Start - 2023',
    location: company.address,
    institution: company.name,
    verified: true
  };
};

export const addMoreNotification = component => {
  toast.info(component, {
    pauseOnHover: true,
    autoClose: 5000,
    theme: 'colored'
  });
};
