import IconButton from 'components/common/IconButton';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from 'utils/api';

const NewsLetter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    setIsLoading(true);
    api
      .post('/users/newsletter', {
        email
      })
      .then(() => {
        setIsLoading(false);
        setEmail('');
        toast.success('Subscribed to newsletter', {
          theme: 'colored'
        });
      })
      .catch(e => {
        setIsLoading(false);
        toast.error('Error occured while subscribing');
      });
  };
  return (
    <Form className="my-5">
      <h5 className="text-white fw-ligh">SUBSCRIBE TO OUR NEWSLETTER</h5>
      <div className="input-group mt-1">
        <input
          type="email"
          className="form-control p-2 outline-none"
          placeholder="Enter your email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <IconButton
          variant="outline-success"
          className="rounded-pil ms-1"
          //icon="fa-solid fa-info"
          size="lg"
          disabled={isLoading}
          onClick={handleSubscribe}
        >
          {isLoading ? 'Loading...' : 'Subscribe'}
        </IconButton>
      </div>
    </Form>
  );
};

export default NewsLetter;
