import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CompanyFormModalContenxt } from 'context/Context';

const CompanyFormModalProvider = ({ children }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const value = { showFormModal, setShowFormModal };

  return (
    <CompanyFormModalContenxt.Provider value={value}>
      {children}
    </CompanyFormModalContenxt.Provider>
  );
};

CompanyFormModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CompanyFormModalProvider;
