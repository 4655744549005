import { SelectedCompanyContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencies } from 'utils';
import api from 'utils/api';
import Item from './Item';
import PropTypes from 'prop-types';

function InvestorsList({ close }) {
  const [investors, setInvestors] = useState([]);
  const { companyDetails } = useContext(SelectedCompanyContext);
  const [totalContributions, setTotalContributions] = useState(0);

  useEffect(() => {
    if (companyDetails.id) {
      api
        .get(`/investors/getAll?companyId=${companyDetails.id}`)
        .then(response => {
          setInvestors(response.data);
        })
        .catch(error => {
          toast.error(error.message);
        });
      return;
    }
    toast.error('No company selected, you must setup a new company to start.');
  }, [companyDetails]);

  useEffect(() => {
    setTotalContributions(
      investors.reduce(
        (acc, investor) =>
          acc +
          parseFloat(investor.investorContribution.replace(/[^0-9.-]+/g, '')),
        0
      )
    );
  }, [investors]);

  return (
    <>
      <Table responsive className="">
        <thead>
          <tr>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Type</th>
            <th scope="col">Contribution</th>
            <th scope="col">Percentage Equity</th>
            <th className="text-end" scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {investors.map(owner => (
            <Item
              key={owner.id}
              details={owner}
              investors={investors}
              setInvestors={setInvestors}
              close={close}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="text-left">
              <span className="fw-bold">
                Total Contributions:{' '}
                {`${
                  currencies[companyDetails.currency]
                } ${totalContributions.toLocaleString('en-US')}`}
              </span>
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

InvestorsList.propTypes = {
  close: PropTypes.func
};

export default InvestorsList;
