import React, { forwardRef } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
const CompanySetup = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="companySetup" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Company Setup</h5>
      </Card.Header>
      <Card.Body>
        <h5 className="text-primary mt-1 ps-3 mb-3">What is "Company Setup"</h5>
        <div className="border-dashed border-bottom my-3" />

        <p className="mt-1 ps-3 mb-3">
          Setting up a company is Australia involves making a decision about the
          type of company you want to set up through to registering the company
          with ASIC and maintaining a company register.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Although this is the opening stage of your business plan, the business
          name, tagline, logo all add together to help the investor gain a good
          first impression and understanding of your business and they should
          lead on and link into the remaining sections of the business plan.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Your Business Name</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          The business name is the first thing a investor will see, and
          reinforces and communicates the key components of your business, it
          gives the investor an impression of your business, the more it does
          this, the easier it will be for the business plan that follows to
          explain what the business stands for and what it does.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Your Tagline Name</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          The tagline is a short, easily remembered phrase so that people will
          remember your business. Think Auziplan, “Planing is the key to your
          succeess”, BMW “The ultimate driving machine” or McDonalds “I’m lovin
          it”. Again the tagline encapsulates everything about the business, it
          communicates to the investor what kind of business you are, what
          values the business has, what the product is, how the product provides
          a solution to a customers problem, and what differentiates the
          business from the competition. This all hints and helps to reinforce
          whats to come in the rest of the business plan.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">Your Logo</h5>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          Finally the logo is a visual representation of what the business
          stands for. The logo helps investors develop a first and lasting
          impression of the business, to understand what makes your product
          different from the competition, your competitive advantage, and who
          your target audience is.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <p className="mt-1 ps-3 mb-3">
          <div className="border-dashed border-bottom my-3" />
          First, seek financial or legal advice to determine if setting up a
          company is the best means to conduct your business. Before you set up
          a company, you should know the name you want to give to the company,
          how many and who will be the director(s), shareholders and
          officeholders, how many shares each shareholder will hold etc.
        </p>
        <div className="border-dashed border-bottom my-3" />
        <h5 className="text-primary mt-1 ps-3 mb-3">How do I add a Company?</h5>

        <div className="border-dashed border-bottom my-3" />
        <ol className="pt-1">
          <li>
            Click the plus button
            <IconButton
              variant="outline-primary"
              size="sm"
              icon="fa-solid fa-plus"
              className="mb-1 mx-1"
              iconAlign="right"
              transform="shrink-3"
              en="true"
            ></IconButton>
          </li>
          <li>
            Upload your company logo that will be printed on the header on your
            financial reports.
          </li>
          <li>Enter the date.</li>
          <li>Enter your company name.</li>
          <li>Enter your ABN number if you have one.(Optional).</li>
          <li>Enter your company structure.</li>
          <li>Enter your currency.</li>
          <li>Enter your business location.</li>
          <li>Select your industry (Products by default).</li>
          <li>Enter your addess.</li>
          <li>Enter your email.</li>
          <li>Select your telephone</li>
          <li>Writre a tag line</li>
          <li>
            Click{' '}
            <IconButton className="ml-3" variant="outline-primary" size="sm">
              <i className="fa fa-save fa-1x" aria-hidden="true"></i>
              Next
            </IconButton>{' '}
            to go to then next stage.
          </li>
        </ol>
        <div className="border-dashed border-bottom my-3" />
      </Card.Body>
    </Card>
  );
});

export default CompanySetup;
