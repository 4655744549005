import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import IconButton from 'components/common/IconButton';
import { motion } from 'framer-motion';
import PageHeader from 'components/common/PageHeader';

function ConfirmationModal({
  show,
  title,
  message,
  handleClose,
  handleConfirm
}) {
  return (
    <Modal
      style={{ backgroundColor: '' }}
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <PageHeader>{title}</PageHeader>
      {/*
      <Modal.Header>       
        <Modal.Title>{title}</Modal.Title>
        <FalconCloseButton onClick={handleClose} />  
      </Modal.Header>
     */}
      <Modal.Body style={{ backgroundColor: '' }}>{message}</Modal.Body>
      <Modal.Footer style={{ backgroundColor: '' }}>
        <Button variant="outline-danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outline-success" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handleShow: PropTypes.func,
  message: PropTypes.string,
  handleConfirm: PropTypes.func
};

export default ConfirmationModal;
