import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { createNumberMask } from 'text-mask-addons';

function PercentageWizardInput({
  name,
  label,
  placeholder,
  symbol = '%',
  formControlProps,
  formGroupProps,
  defaultValue = '',
  disabled = false,
  errors,
  setValue,
  watch
}) {
  const currencyFormValue = watch && watch(name);
  const [percentage, setPercentage] = useState(defaultValue);

  useEffect(() => {
    if (watch && watch(name) === undefined) {
      setValue(name, defaultValue);
    }
  }, [watch]);

  useEffect(() => {
    if (currencyFormValue !== percentage) {
      setPercentage(currencyFormValue);
    }
  }, [currencyFormValue]);

  const defaultMaskOptions = {
    prefix: '',
    suffix: ` ${symbol}`,
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 4, // how many digits allowed after the decimal
    integerLimit: 4, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false
  };
  const maskOptions = {
    ...defaultMaskOptions,
    ...formControlProps.maskOptions
  };
  const currencyMask = createNumberMask(maskOptions);
  return (
    <Form.Group {...formGroupProps}>
      <FloatingLabel label={label}>
        <Form.Control
          as={MaskedInput}
          mask={currencyMask}
          inputMode="numeric"
          placeholder={placeholder}
          onChange={e => {
            setValue(name, e.target.value, {
              shouldValidate: true,
              shouldDirty: true
            });
            setPercentage(e.target.value);
          }}
          value={percentage}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          aria-label={placeholder}
          disabled={disabled}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  );
}

PercentageWizardInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  symbol: PropTypes.string,
  disabled: PropTypes.bool,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  defaultValue: PropTypes.string,
  watch: PropTypes.func
};

export default PercentageWizardInput;
