import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FundingFormModalContext } from 'context/Context';

const FundingFormModalProvider = ({ children }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const value = { showFormModal, setShowFormModal };

  return (
    <FundingFormModalContext.Provider value={value}>
      {children}
    </FundingFormModalContext.Provider>
  );
};

FundingFormModalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FundingFormModalProvider;
