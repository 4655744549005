import React from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  ProgressBar,
  Container
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import NavbarStandard from './landing/NavbarStandard';
import Section from 'components/common/Section';
import { motion } from 'framer-motion';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';

//import { Typewriter } from 'react-simple-typewriter';
//import Lottie from 'lottie-react';
//import celebration from '../pages/lottie/celebration.json';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import FooterStandard from './landing/FooterStandard';
//import Flex from 'components/common/Flex';
//import logo from 'assets/img/logos/auziLogo-120x120.png';

const Starter = () => {
  return (
    <>
      <NavbarStandard />

      <Container
        as={motion.div}
        className="g-3 pt-6"
        initial={{ x: '-20%' }}
        animate={{ x: '0%' }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <Row className="align-items-top justify-content-center">
          <Col lg={6} className="ps-lg-4 my-5 text-center">
            <img
              src={editing}
              className="img-fluid"
              alt=""
              style={{ maxWidth: '70%', height: 'auto' }}
            />
            <h2 className="test-primary">Welcome</h2>
            <p>
              So you've got a fantastic business concept, and now it's time
              toturn it into a reality. First things first, let's establishing
              your company and business assumptions. This involves delving into
              key aspects such identification, revenue models, competitive
              analysis, and operational strategies.
            </p>

            <h5 className="text-primary mt-4">
              "Planning is the key your your success!"
            </h5>
            <h6 className="fs-11 text-muted pt-2">Nuri Bydel-CEO</h6>

            <div className="text- pt-1">
              <Link to="/process" className="text-decoration-none">
                <IconButton
                  variant="outline-success"
                  size="lg"
                  icon="fa-solid fa-arrow-right"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                ></IconButton>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Starter;

{
  /*<ProgressBar now={5} label="5%" />
  <h1 className=" h1 mt-0 text-success">Welcome</h1>
  <div className="border-dashed border-bottom my-3" />

  <PageHeader
    title=""
    description="So you've got a fantastic business concept, and now it's time to turn it into a reality. First things first, let's craft a compelling business narrative, commonly known as 'The Pitch'.
      This narrative will be the cornerstone of your business presentation, highlighting the core essence of your idea and demonstrating its potential and unique value proposition."
    className="mb-0 pt-0"
  />
  <p>
    Once we've setup your company, we'll move on to the writing a
    compelling business narrative, commonly known as 'The Pitch'.
    This narrative will be the cornerstone of your business
    presentation, highlighting the core essence of your idea and
    demonstrating its potential and unique value proposition.
  </p>
  <p>
    Together, we'll work to refine your pitch and fine-tune your
    business assumptions, setting the stage for success. With a
    clear roadmap in place, you'll be well-equipped to attract
    funding or investors and take your business idea to the next
    level. Let's get started on this exciting journey!
  </p>
  <p>
    Should you encounter any difficulties, our Help Centre is
    readily available within the application. Take a moment to
    peruse the guidelines if you find yourself stuck. Wishing you
    the best of luck with your new venture!
  </p>

  <p>
    So you've got a fantastic business concept, and now it's time to
    turn it into a reality. First things first, let's establishing
    your company and business assumptions. This involves delving
    into key aspects such identification, revenue models,
    competitive analysis, and operational strategies. By solidifying
    these assumptions, we lay a strong foundation for your business
    strategy, ensuring alignment with your vision and objectives.
  </p>

  By solidifying
    these assumptions, we lay a strong foundation for your business
    strategy, ensuring alignment with your vision and objectives

*/
}
